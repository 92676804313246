import { useQuery } from "@tanstack/react-query";

import {
  BikeBenefitContractRead,
  ResponseError,
  ShopBenefitApi,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

const shopBenefitApi = new ShopBenefitApi(apiConfiguration);

export const getContractQuery = (contractId: string) => ({
  queryKey: ["contracts", contractId],
  queryFn: () =>
    shopBenefitApi.shopBenefitGetBikeBenefitContract({
      bikeBenefitContractId: contractId,
    }),
});

export const useGetBenefitContract = (
  id?: string,
  options?: UseQueryOptions<BikeBenefitContractRead>,
) =>
  useQuery<BikeBenefitContractRead, ResponseError>({
    ...options,
    ...getContractQuery(id as string),
    enabled: !!id,
  });
