import { TFunction } from "i18next";
import styled from "styled-components";

import { useEnumEntries } from "@vapaus/shared-api";
import {
  BuildIcon,
  CircularIcon,
  Flex,
  Typography,
  useBreakpoints,
} from "@vapaus/ui-v2";

import { useDateFormat } from "../../../../../../../../packages/i18n/src/hooks";
import { Event } from "../components/useServiceHistoryTable";

type EventCellProps = {
  event: Event;
  t: TFunction<"translation">;
};

export function EventCell({ event, t }: EventCellProps) {
  const { isXs } = useBreakpoints();
  const { getEnumLabel } = useEnumEntries("WorkTypes");

  const formatDate = useDateFormat();

  return (
    <Flex>
      {!isXs ? <CircularIcon icon={BuildIcon} /> : null}
      <StyledFlex direction="column" gap="xss">
        <Typography>{getEnumLabel(event.eventType)}</Typography>
        <Typography variant="bodySm" weight="bold">
          {formatDate(event.workDate)}
        </Typography>
      </StyledFlex>
    </Flex>
  );
}

const StyledFlex = styled(Flex)`
  overflow: hidden;
`;
