import { find, map, merge, orderBy } from "lodash-es";
import invariant from "tiny-invariant";

import {
  AccessoryCreateUpdate,
  BikeBenefitOrderAdditionalServiceWrite,
  BikeBenefitOrderCreateUpdate,
  BikeBenefitOrderDeliveryTransport,
  BikeBenefitOrderExtraRead,
  BikeCreateUpdate,
  Currency,
  Plan,
} from "@vapaus/api-codegen";

import { BenefitOrderFormType } from "../orderFormSchema/orderFormSchema";

export function convertFormToSubmittableOrderRecord(
  orderForm: BenefitOrderFormType,
  currency: Currency,
  benefitPlan: Plan,
  hasFixedTaxableValue: boolean,
  editingOrder?: BikeBenefitOrderExtraRead,
): BikeBenefitOrderCreateUpdate {
  const shopId = orderForm.shopId;
  invariant(orderForm.userId, "orderForm.userId should have a value");

  let submittableOrderData: BikeBenefitOrderCreateUpdate = {
    userId: orderForm.userId,
    benefitDefinitionId: orderForm.benefitDefinitionId,
    benefitDefinitionTermsId: orderForm.benefitDefinitionTermsId,

    monthlyMaintenanceBudget: orderForm.monthlyMaintenanceBudget,
    leasingPeriodMonths: orderForm.leasingPeriodMonths,
    fixedMonthlyTaxableValue: orderForm.fixedMonthlyTaxableValue,
    fixedDownPaymentAmount: orderForm.fixedDownPaymentAmount,

    isDownPaymentInsured: orderForm.isDownPaymentInsured,
    notes: orderForm.notes,
    orderNumber: orderForm.orderNumber,
    bikePrice: orderForm.bike.purchasePrice,

    bike: {
      ...orderForm.bike,
      shopId,
      purchaseCurrency: currency,
      condition: "new",
      status: "benefit_bike",
    } as BikeCreateUpdate,

    accessories: (orderForm.accessories || []).map((accessory) => ({
      ...accessory,
      shopId,
      status: "benefit_bike",
      purchaseCurrency: currency,
    })) as AccessoryCreateUpdate[],

    additionalServices: (orderForm.additionalServices || []).map((service) => ({
      ...service,
      shopId,
    })) as BikeBenefitOrderAdditionalServiceWrite[],
  };

  if (editingOrder) {
    const accessories = submittableOrderData.accessories.map((item) =>
      merge({}, find(editingOrder.accessories, { id: item.id }), item),
    );
    const additionalServices = submittableOrderData.additionalServices.map(
      (item) =>
        merge({}, find(editingOrder.additionalServices, { id: item.id }), item),
    );

    // If editing, we need to copy all the default values that are hidden in the form
    submittableOrderData = merge(
      {},
      {
        ...editingOrder,
        deliveryTransport: submittableOrderData.deliveryTransport,
      },
      submittableOrderData,
    );
    submittableOrderData.accessories = accessories;
    submittableOrderData.additionalServices = additionalServices;
  }

  if (
    orderForm.deliveryTransport &&
    Object.values(orderForm.deliveryTransport).some((value) => !!String(value))
  ) {
    submittableOrderData.deliveryTransport =
      orderForm.deliveryTransport as BikeBenefitOrderDeliveryTransport;
    submittableOrderData.deliveryTransport.expectedDeliveryDate =
      submittableOrderData.deliveryTransport.expectedDeliveryDate
        ? new Date(submittableOrderData.deliveryTransport.expectedDeliveryDate)
        : undefined;
    if (!submittableOrderData.deliveryTransport.trackingUrl) {
      submittableOrderData.deliveryTransport.trackingUrl = "";
    }
  }

  if (
    ([Plan.Custom, Plan.Legacy, Plan.SwedenPilot] as Plan[]).includes(
      benefitPlan,
    ) &&
    !hasFixedTaxableValue
  ) {
    delete submittableOrderData.fixedMonthlyTaxableValue;
  }
  return submittableOrderData;
}
