import { useRouteLoaderData } from "react-router-dom";

import { Flex } from "@vapaus/ui-v2";

import { OrderViewRouteLoaderResult } from "../loaders";
import { Accessories } from "./components/Accessories";
import { AdditionalServices } from "./components/AdditionalServices";
import { BikeInformation } from "./components/BikeInformation";
import { BillingDetails } from "./components/BillingDetails";
import { Delivery } from "./components/Delivery";
import { LeasingContract } from "./components/LeasingContract";
import { MaintenanceBudget } from "./components/MaintenanceBudget";
import { OrderCustomerDetails } from "./components/OrderCustomerDetails";
import { OrderSummary } from "./components/OrderSummary";

export function OrderDetailsTab() {
  const data = useRouteLoaderData("orderView") as OrderViewRouteLoaderResult;
  return (
    <Flex direction="column" gap="lg">
      <Flex direction="row" smDirection="column" gap="lg" align="stretch">
        <OrderCustomerDetails
          order={data.order}
          benefitDefinition={data.benefitDefinition}
        />
        <OrderSummary
          order={data.order}
          benefitDefinition={data.benefitDefinition}
        />
      </Flex>
      <BikeInformation {...data} />
      <Accessories {...data} />
      <AdditionalServices {...data} />
      <Delivery {...data} />
      <MaintenanceBudget {...data} />
      <LeasingContract {...data} />
      <BillingDetails {...data} />
    </Flex>
  );
}
