import { useQuery } from "@tanstack/react-query";

import {
  AccessoryTypeRead,
  ResponseError,
  ShopAccessoryApi,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const shopAccessoryApi = new ShopAccessoryApi(apiConfiguration);

export const useGetAccessoryTypes = (
  options?: UseQueryOptions<Array<AccessoryTypeRead>>,
) => {
  return useQuery<Array<AccessoryTypeRead>, ResponseError>(
    ["accessoryTypes"],
    () => shopAccessoryApi.shopAccessoryGetAvailableAccessoryTypes(),
    options,
  );
};
