import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ShopRead } from "@vapaus/api-codegen";
import { useCurrentUser, useLogout } from "@vapaus/auth-v2";

export type ShopContextValue = {
  changeSelectedShop: (shopId: string) => void;
  managedShops: Array<ShopRead>;
  selectedShop?: ShopRead;
};

const defaultContextValue = {
  changeSelectedShop: (shopId: string) => {},
  managedShops: [],
};

export const ShopContext = createContext<ShopContextValue>(defaultContextValue);

export function ShopContextProvider({ children }: { children: ReactNode }) {
  const { shopId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const logout = useLogout();
  const { data: user } = useCurrentUser({
    staleTime: 0,
  });
  const managedShops = useMemo(() => user?.managedShops ?? [], [user]);
  const selectedShop = managedShops.find((shop) => shop.id === shopId);

  useEffect(() => {
    const firstShop = managedShops[0];
    if (!firstShop) logout();
    else if (!selectedShop) navigate(`/${firstShop.id}`);
  }, [selectedShop, managedShops, navigate]);

  const changeSelectedShop = useCallback(
    (shopId: string) => {
      const slashCount = (location.pathname.match(/\//g) || []).length - 1;
      const root = "../".repeat(slashCount);
      navigate(`${root}${shopId}/orders`);
    },
    [navigate, location],
  );

  const value = useMemo(
    () => ({
      changeSelectedShop,
      managedShops,
      selectedShop,
    }),
    [changeSelectedShop, managedShops, selectedShop],
  );

  if (!selectedShop) return null;

  return <ShopContext.Provider value={value}>{children}</ShopContext.Provider>;
}

export const useShopContext = () => useContext(ShopContext);
