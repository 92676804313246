import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { BikeBenefitContractForMaintenanceRead } from "@vapaus/api-codegen";
import { Flex, Notification, SearchBar, Typography } from "@vapaus/ui-v2";

import { useSearchBikeContractsForMaintenance } from "../../../../api/bikeContractsForMaintenance";
import { SearchResult } from "./components/SearchResult";

type SearchViewProps = {
  selectBikeContract: (contract: BikeBenefitContractForMaintenanceRead) => void;
};

export function SearchView({ selectBikeContract }: SearchViewProps) {
  const { t } = useTranslation();

  const [searchPhrase, setSearchPhrase] = useState<string>("");
  const onSearch = (value: string) => setSearchPhrase(value.trim());

  const { data: fetchedData, isFetched } = useSearchBikeContractsForMaintenance(
    {
      q: searchPhrase,
    },
    {
      enabled: !!searchPhrase,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    },
  );

  return (
    <StyledLayout justify="center">
      <StyledFlexContainer direction="column" gap="lg">
        <StyledHeaderContainer direction="column" align="center" gap="sm">
          <Typography variant="h1" align="center">
            {t("shopV2:maintenance.wizard.bikeSearch.title")}
          </Typography>
          <Typography align="center">
            {t("shopV2:maintenance.wizard.bikeSearch.description")}
          </Typography>
        </StyledHeaderContainer>
        <StyledSearchBar
          placeholder={t(
            "shopV2:maintenance.wizard.bikeSearch.searchPlaceholder",
          )}
          onSearch={onSearch}
          searchOnClick
          allowEmptySearch={false}
        />
        {(fetchedData?.length || 0) > 1 ? (
          <StyledSearchResultsContainer>
            <Notification
              message={t(
                "shopV2:maintenance.wizard.bikeSearch.notification.multipleResults",
              )}
              type="warning"
            />
          </StyledSearchResultsContainer>
        ) : null}
        {(fetchedData?.length || 0) === 0 && isFetched ? (
          <StyledSearchResultsContainer>
            <Notification
              message={t(
                "shopV2:maintenance.wizard.bikeSearch.notification.noResults",
              )}
              type="error"
            />
          </StyledSearchResultsContainer>
        ) : null}
        <StyledSearchResultsContainer direction="column">
          {fetchedData?.map((contract) => (
            <SearchResult
              key={contract.id}
              contract={contract}
              onClick={() => selectBikeContract(contract)}
            />
          ))}
        </StyledSearchResultsContainer>
      </StyledFlexContainer>
    </StyledLayout>
  );
}

const StyledLayout = styled(Flex)`
  padding-top: 5rem;
  padding-bottom: 5rem;
`;

const StyledFlexContainer = styled(Flex)`
  width: 50rem;

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      width: 44rem;
    `)}
`;

const StyledHeaderContainer = styled(Flex)`
  padding: 0 10rem;

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      padding: 0 8rem;
    `)}

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      padding: 0 1.5rem;
    `)}
`;

const StyledSearchBar = styled(SearchBar)`
  margin: 0 11rem;

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      margin: 0 8rem;
    `)}

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      margin: 0 1.5rem;
    `)}
`;

const StyledSearchResultsContainer = styled(Flex)`
  padding: 0 7.5rem;

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      padding: 0 4.5rem;
    `)}

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      padding: 0 1.5rem;
    `)}
`;
