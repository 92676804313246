import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { TableEmptyState } from "@vapaus/common-v2";
import { BunnyWithBike3DIcon, Card, Flex, SearchBar } from "@vapaus/ui-v2";

import { OrdersTable } from "./OrdersTable/OrdersTable";
import { OrderStateSelect } from "./OrdersTable/components/OrderStateSelect";
import { useOrdersTable } from "./OrdersTable/useOrdersTable";

export function OrdersCard() {
  const { t } = useTranslation();

  const {
    searchQuery,
    setSearchQuery,
    selectedStateFilter,
    paginationOptions,
    sortingOptions,
    handleSelectedStateFilterChange,
    fetchedData,
    isLoading,
  } = useOrdersTable();

  const shouldNotShowOrdersTable =
    !fetchedData?.total && !searchQuery && !selectedStateFilter;

  return (
    <Card>
      {isLoading ? null : shouldNotShowOrdersTable ? (
        <TableEmptyState
          icon={<BunnyWithBike3DIcon size="lg" />}
          title={t("shopV2:orders.emptyState.title")}
          subtitle={t("shopV2:orders.emptyState.subtitle")}
        />
      ) : (
        <>
          <StyledFilteringSection marginBottom="lg" xsDirection="column">
            <StyledSearchBar
              placeholder={t("commonV2:search")}
              onSearch={setSearchQuery}
              defaultValue={searchQuery}
            />
            <OrderStateSelect
              value={selectedStateFilter}
              onChange={handleSelectedStateFilterChange}
            />
          </StyledFilteringSection>
          <OrdersTable
            data={fetchedData}
            paginationOptions={paginationOptions}
            sortingOptions={sortingOptions}
          />
        </>
      )}
    </Card>
  );
}

const StyledSearchBar = styled(SearchBar)`
  flex: 1;
`;

const StyledFilteringSection = styled(Flex)`
  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      > div {
        width: 100%;
        flex: auto;
      }
    `)}
`;
