import React from "react";
import ReactDOM from "react-dom/client";

import { init } from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { CssBaseline, Fonts, ToastProvider, createTheme } from "@vapaus/ui-v2";

import "./i18n";
import { createRoutes } from "./routes";

if (
  import.meta.env.VITE_ENV &&
  ["prod", "staging", "feature"].includes(import.meta.env.VITE_ENV)
) {
  init({
    dsn: "https://f3f78ddcb04e883a85be57e47fd7d570@o1371810.ingest.sentry.io/4506072355438592",
    integrations: [],
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60,
    },
  },
});

const theme = createTheme();

const router = createBrowserRouter(createRoutes(queryClient));

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Fonts />
        <CssBaseline />
        <ToastProvider>
          <RouterProvider router={router} />
        </ToastProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
