import { ReactNode } from "react";

import { useTranslation } from "react-i18next";

import { Card, Flex } from "@vapaus/ui-v2";

import {
  EditOrderButton,
  FormSectionType,
} from "../../../../components/OrderCreation";
import { BaseOrderViewProps } from "../../type";
import { useGetOrderPermission } from "../../useGetOrderPermission";

type OrderSectionWithEditProps = {
  title: string;
  children: ReactNode;
  editingSection?: FormSectionType;
} & BaseOrderViewProps;

export function OrderSectionWithEdit({
  title,
  children,
  order,
  benefitDefinition,
  editingSection,
}: OrderSectionWithEditProps) {
  const { t } = useTranslation();
  const { canEdit, isEditDisabled, editDisabledMessage } =
    useGetOrderPermission(order, benefitDefinition);
  return (
    <Card title={title}>
      <Flex direction="column" gap="xs" marginBottom={canEdit ? "md" : "none"}>
        {children}
      </Flex>
      {canEdit && (
        <EditOrderButton
          variant="outlined"
          label={t("commonV2:edit")}
          order={order}
          benefitDefinition={benefitDefinition}
          editingSection={editingSection}
          disabled={isEditDisabled}
          disabledTooltipMessage={editDisabledMessage}
        />
      )}
    </Card>
  );
}
