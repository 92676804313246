import { get } from "lodash-es";
import { RegisterOptions, useFormContext } from "react-hook-form";

import {
  TextArea as UITextArea,
  TextAreaProps as UITextAreaProps,
} from "@vapaus/ui-v2";

export type TextAreaProps = Omit<UITextAreaProps, "error" | "pattern"> &
  Pick<
    RegisterOptions,
    "validate" | "pattern" | "valueAsNumber" | "valueAsDate"
  > & {
    name: string;
  };

// TODO: Add test for this later or resolve with possible conflicts from similar component by Jan
export function TextArea({
  name,
  required,
  validate,
  value,
  onChange,
  onBlur,
  pattern,
  ...rest
}: TextAreaProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  // Annotation needed because typescript doesn't seems to get the type right somehow ¯\_(ツ)_/¯
  const errorMessage = get(errors, name)?.message as string | undefined;

  return (
    <UITextArea
      {...register(name, {
        required,
        validate,
        value,
        onChange,
        onBlur,
        // Typescript is not happy with the type somehow
        /* eslint-disable @typescript-eslint/no-explicit-any */
        pattern: pattern as any,
        /* eslint-enable @typescript-eslint/no-explicit-any */
      })}
      error={errorMessage}
      required={required}
      {...rest}
    />
  );
}
