export function getDateDifferenceInDays(firstDate: Date, secondDate: Date) {
  const dayInMillis = 1000 * 60 * 60 * 24;
  const firstDateInUTC = Date.UTC(
    firstDate.getFullYear(),
    firstDate.getMonth(),
    firstDate.getDate(),
  );
  const secondDateInUTC = Date.UTC(
    secondDate.getFullYear(),
    secondDate.getMonth(),
    secondDate.getDate(),
  );

  return Math.floor((secondDateInUTC - firstDateInUTC) / dayInMillis);
}
