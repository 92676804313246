import { apiConfiguration } from "@vapaus/auth-v2";

const getFileWithName = async (res: Response): Promise<File> => {
  const contentDispositionHeader = res.headers.get("Content-Disposition") || "";
  const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
    contentDispositionHeader,
  );
  const filename = matches?.[1]?.replace?.(/['"]/g, "") as string;
  const blob = await res.blob();
  const file = new File([blob], filename, { type: "application/pdf" });
  return file;
};

const downloadTermRequest = async (endpoint: string): Promise<Blob> => {
  const res = await fetch(`${apiConfiguration.basePath}${endpoint}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/pdf",
      Authorization: `Bearer ${apiConfiguration.tokenString}`,
    },
  });
  return await getFileWithName(res);
};

export const downloadUserTermRequest = (id: string): Promise<Blob> =>
  downloadTermRequest(`/api/user-terms/${id}/download`);
export const downloadShopTermRequest = (id: string): Promise<Blob> =>
  downloadTermRequest(`/shop-api/shop-terms/${id}/download`);
export const downloadProviderTermRequest = (id: string): Promise<Blob> =>
  downloadTermRequest(`/provider-api/provider-terms/${id}/download`);
