import { Flex, Typography } from "@vapaus/ui-v2";

import { Bike } from "../../../../../../types/Bike";

type BikeCellProps = {
  bike: Bike;
};

export function BikeCell({ bike }: BikeCellProps) {
  return (
    <Flex direction="column" align="flex-end" gap="0">
      <Typography>{bike.bikeBrand.name}</Typography>
      <Typography>{bike.model}</Typography>
    </Flex>
  );
}
