import { useTranslation } from "react-i18next";

import {
  BenefitDefinitionReadWithOrganisationSummary,
  BikeBenefitContractState,
  BikeBenefitOrderExtraRead,
  BikeBenefitOrderState,
  BikeBenefitOrderingMethod,
} from "@vapaus/api-codegen";
import { useEnumEntries } from "@vapaus/shared-api";
import { getDaysSinceDate } from "@vapaus/utils";

export type OrderPermissionData = {
  canEdit: boolean;
  canMarkAsDelivered: boolean;
  canCancelOrder: boolean;
  isEditDisabled: boolean;
  editDisabledMessage?: string;
};

export function useGetOrderPermission(
  order: BikeBenefitOrderExtraRead,
  benefitDefinition: BenefitDefinitionReadWithOrganisationSummary,
  contractState?: BikeBenefitContractState,
): OrderPermissionData {
  const { t } = useTranslation();
  const { getEnumLabel: getOrderingMethodEnumLabel } = useEnumEntries(
    "BikeBenefitOrderingMethod",
  );
  const canManageRevisionCorrection =
    !(
      order.revisesBikeBenefitContractId || order.correctsBikeBenefitContractId
    ) || import.meta.env.VITE_VAPAUS_SHOP_ID === order.shopId;

  const daysSinceDelivered = getDaysSinceDate(
    order.deliveryDate?.toISOString() ?? "",
  );

  const isAcceptedOrDraft: boolean = (
    [
      BikeBenefitOrderState.Draft,
      BikeBenefitOrderState.WaitingForPayment,
      BikeBenefitOrderState.ReadyForDelivery,
    ] as BikeBenefitOrderState[]
  ).includes(order.state);

  const canEdit: boolean = Boolean(
    isAcceptedOrDraft &&
      canManageRevisionCorrection &&
      benefitDefinition.active,
  );
  const editDisabledMessage =
    order.orderingMethod &&
    order.orderingMethod !== BikeBenefitOrderingMethod.PartnerStore
      ? t("shopV2:orderViewPage.orderPermissions.editDisabledOrderingMethod", {
          orderingMethod: getOrderingMethodEnumLabel(order.orderingMethod),
        })
      : undefined;
  const isEditDisabled: boolean = !!editDisabledMessage;

  const canMarkAsDelivered: boolean = Boolean(
    order.state === BikeBenefitOrderState.ReadyForDelivery,
  );

  const canCancelOrder: boolean =
    Boolean(
      order.state === BikeBenefitOrderState.Finalised &&
        contractState === BikeBenefitContractState.Active &&
        daysSinceDelivered !== undefined &&
        daysSinceDelivered <= 30 &&
        canManageRevisionCorrection,
    ) ||
    Boolean(
      isAcceptedOrDraft &&
        canManageRevisionCorrection &&
        benefitDefinition.active,
    );

  return {
    canEdit,
    canMarkAsDelivered,
    canCancelOrder,
    isEditDisabled,
    editDisabledMessage,
  };
}
