import styled from "styled-components";

import {
  CircularIcon,
  Flex,
  PersonIcon,
  Typography,
  useBreakpoints,
} from "@vapaus/ui-v2";

import { Customer } from "../../../../../../types/Customer";

type CustomerCellProps = {
  customer: Customer;
};

export function CustomerCell({ customer }: CustomerCellProps) {
  const { isMobile } = useBreakpoints();

  return (
    <Flex>
      {!isMobile ? <CircularIcon icon={PersonIcon} /> : null}
      <StyledFlex direction="column" gap="xss">
        <Typography>{customer.name}</Typography>
        <Typography variant="bodySm" weight="bold">
          {customer.email}
        </Typography>
        {customer.vapausCode ? (
          <Typography variant="bodySm">{customer.vapausCode}</Typography>
        ) : null}
      </StyledFlex>
    </Flex>
  );
}

const StyledFlex = styled(Flex)`
  overflow: hidden;
`;
