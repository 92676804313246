import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { BuildIcon, OrderApproveIcon, TabRow } from "@vapaus/ui-v2";

import { Path } from "../../../constants/paths";

export function OrderTabs() {
  const { orderId } = useParams<{ orderId: string }>();

  invariant(orderId, "OrderId must have a value");

  const { t } = useTranslation();

  const orderDetails = t("shopV2:orderViewPage.heroSection.orderDetails");
  const maintenance = t("shopV2:orderViewPage.heroSection.maintenance");

  const tabItems = [
    {
      icon: OrderApproveIcon,
      label: orderDetails,
      to: `../${orderId}`,
    },
    {
      icon: BuildIcon,
      label: maintenance,
      to: Path.maintenance,
    },
  ];

  return <TabRow items={tabItems} />;
}
