import { useQuery } from "@tanstack/react-query";

import {
  BikeBenefitOrderExtraRead,
  BikeBenefitOrderState,
  OrderDirection,
  PaginatedBikeBenefitOrderSummaryOut,
  ResponseError,
  ShopBenefitApi,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const shopBenefitApi = new ShopBenefitApi(apiConfiguration);

export const useSearchBikeBenefitOrder = (
  params: {
    shopId: string;
    skip: number;
    limit: number;
    q?: string;
    sortField?: string;
    sortDirection?: OrderDirection;
    state?: BikeBenefitOrderState;
  },
  options?: UseQueryOptions<PaginatedBikeBenefitOrderSummaryOut>,
) => {
  return useQuery<PaginatedBikeBenefitOrderSummaryOut, ResponseError>(
    ["orders", params],
    () =>
      shopBenefitApi.shopBenefitSearchBikeBenefitOrders({
        shopId: params.shopId,
        q: params.q,
        skip: params.skip,
        limit: params.limit,
        sortField: params.sortField,
        sortDirection: params.sortDirection,
        ...(params.state ? { states: new Set([params.state]) } : null),
      }),
    options,
  );
};

export const getOrderQuery = (orderId: string) => ({
  queryKey: ["orders", orderId],
  queryFn: () =>
    shopBenefitApi.shopBenefitGetBikeBenefitOrder({
      orderId,
    }),
});

export const useGetOrderQuery = (orderId: string) =>
  useQuery<BikeBenefitOrderExtraRead, ResponseError>(getOrderQuery(orderId));
