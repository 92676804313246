import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  AccessoryTypeRead,
  SupportedOrganisationCountries,
} from "@vapaus/api-codegen";
import { useEnumEntries } from "@vapaus/shared-api";
import {
  CheckFillIcon,
  CloseFillIcon,
  CollapsibleLineItem,
  Flex,
  Typography,
} from "@vapaus/ui-v2";

import { useFilterAccessoryTypesByCountry } from "../../hooks";

type AccessoryGuideItemProps = {
  label: string;
  isAllowed?: boolean;
};

function AccessoryGuideItem({ label, isAllowed }: AccessoryGuideItemProps) {
  return (
    <Flex fullWidth justify="space-between">
      <Typography weight="bold">{label}</Typography>
      {isAllowed ? (
        <CheckFillIcon color="success" />
      ) : (
        <CloseFillIcon color="error" />
      )}
    </Flex>
  );
}

type AccessoryGuideButton = {
  availableAccessoryTypes: AccessoryTypeRead[];
};

type AccessoryGuideButtonProps = {
  country: SupportedOrganisationCountries;
};

export function AccessoryGuideButton({ country }: AccessoryGuideButtonProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { allowedAccessoryTypes, notAllowedAccessoryTypes } =
    useFilterAccessoryTypesByCountry(country);

  const { getEnumLabel: getAccessoryTypeEnumLabel } =
    useEnumEntries("AccessoryType");

  return (
    <CollapsibleLineItem
      title={
        isOpen
          ? t("shopV2:orderForm.accessories.accessoryGuideButton.hide")
          : t("shopV2:orderForm.accessories.accessoryGuideButton.show")
      }
      headerPadding="none"
      headerJustify="flex-start"
      topBorder={false}
      onToggle={setIsOpen}
    >
      <StyledRoot
        gap="lg"
        smGap="md"
        marginTop="md"
        padding="md"
        justify="space-between"
        align="flex-start"
        smDirection="column"
        data-testid="accessory-guide-list"
      >
        <Flex direction="column" gap="md" fullWidth>
          {allowedAccessoryTypes.map((accessory) => (
            <AccessoryGuideItem
              key={accessory.accessoryType}
              label={getAccessoryTypeEnumLabel(accessory.accessoryType) ?? ""}
              isAllowed
            />
          ))}
        </Flex>
        <Flex direction="column" gap="md" fullWidth>
          {notAllowedAccessoryTypes.map((accessory) => (
            <AccessoryGuideItem
              key={accessory.accessoryType}
              label={getAccessoryTypeEnumLabel(accessory.accessoryType) ?? ""}
            />
          ))}
        </Flex>
      </StyledRoot>
    </CollapsibleLineItem>
  );
}

const StyledRoot = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.background.main};
  border-radius: 10px;
`;
