/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface AutocompleteResults
 */
export interface AutocompleteResults {
  /**
   *
   * @type {Array<string>}
   * @memberof AutocompleteResults
   */
  results: Array<string>;
}

/**
 * Check if a given object implements the AutocompleteResults interface.
 */
export function instanceOfAutocompleteResults(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "results" in value;

  return isInstance;
}

export function AutocompleteResultsFromJSON(json: any): AutocompleteResults {
  return AutocompleteResultsFromJSONTyped(json, false);
}

export function AutocompleteResultsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AutocompleteResults {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    results: json["results"],
  };
}

export function AutocompleteResultsToJSON(
  value?: AutocompleteResults | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    results: value.results,
  };
}
