import { useTranslation } from "react-i18next";

import { PageRoot } from "@vapaus/common-v2";
import { Flex, Typography } from "@vapaus/ui-v2";

import { AddOrderButton } from "../../components/OrderCreation";
import { useShopContext } from "../../contexts/ShopContext";
import { OrdersCard } from "./components/OrdersCard";

export function OrdersPage() {
  const { selectedShop } = useShopContext();
  const { t } = useTranslation();

  return (
    <PageRoot size="wide">
      <Flex direction="column" gap="xl">
        <Flex justify="space-between" align="flex-end">
          <div>
            <Typography variant="h6" marginBottom="xxs">
              {selectedShop?.name}
            </Typography>
            <Typography variant="h1">{t("shopV2:orders.title")}</Typography>
          </div>
          <AddOrderButton />
        </Flex>
        <OrdersCard />
      </Flex>
    </PageRoot>
  );
}
