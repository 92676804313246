/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BikeCondition } from "./BikeCondition";
import {
  BikeConditionFromJSON,
  BikeConditionFromJSONTyped,
  BikeConditionToJSON,
} from "./BikeCondition";
import type { BikeDriveType } from "./BikeDriveType";
import {
  BikeDriveTypeFromJSON,
  BikeDriveTypeFromJSONTyped,
  BikeDriveTypeToJSON,
} from "./BikeDriveType";
import type { BikeFrameType } from "./BikeFrameType";
import {
  BikeFrameTypeFromJSON,
  BikeFrameTypeFromJSONTyped,
  BikeFrameTypeToJSON,
} from "./BikeFrameType";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
} from "./Currency";

/**
 *
 * @export
 * @interface BikeCreateUpdate
 */
export interface BikeCreateUpdate {
  /**
   *
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  serialNumber: string;
  /**
   *
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  manufacturer?: string;
  /**
   *
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  bikeBrandId: string;
  /**
   *
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  model: string;
  /**
   *
   * @type {BikeDriveType}
   * @memberof BikeCreateUpdate
   */
  driveType: BikeDriveType;
  /**
   *
   * @type {BikeFrameType}
   * @memberof BikeCreateUpdate
   */
  frameType: BikeFrameType;
  /**
   *
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  size: string;
  /**
   *
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  colour: string;
  /**
   *
   * @type {number}
   * @memberof BikeCreateUpdate
   */
  purchasePrice: number;
  /**
   *
   * @type {Currency}
   * @memberof BikeCreateUpdate
   */
  purchaseCurrency: Currency;
  /**
   *
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  shopId: string;
  /**
   *
   * @type {BikeCondition}
   * @memberof BikeCreateUpdate
   */
  condition: BikeCondition;
  /**
   *
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  pictureId?: string;
  /**
   *
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  url?: string;
  /**
   * Leave empty in case of creating a Bike
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  id?: string;
}

/**
 * Check if a given object implements the BikeCreateUpdate interface.
 */
export function instanceOfBikeCreateUpdate(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "serialNumber" in value;
  isInstance = isInstance && "bikeBrandId" in value;
  isInstance = isInstance && "model" in value;
  isInstance = isInstance && "driveType" in value;
  isInstance = isInstance && "frameType" in value;
  isInstance = isInstance && "size" in value;
  isInstance = isInstance && "colour" in value;
  isInstance = isInstance && "purchasePrice" in value;
  isInstance = isInstance && "purchaseCurrency" in value;
  isInstance = isInstance && "shopId" in value;
  isInstance = isInstance && "condition" in value;

  return isInstance;
}

export function BikeCreateUpdateFromJSON(json: any): BikeCreateUpdate {
  return BikeCreateUpdateFromJSONTyped(json, false);
}

export function BikeCreateUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeCreateUpdate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    serialNumber: json["serial_number"],
    manufacturer: !exists(json, "manufacturer")
      ? undefined
      : json["manufacturer"],
    bikeBrandId: json["bike_brand_id"],
    model: json["model"],
    driveType: BikeDriveTypeFromJSON(json["drive_type"]),
    frameType: BikeFrameTypeFromJSON(json["frame_type"]),
    size: json["size"],
    colour: json["colour"],
    purchasePrice: json["purchase_price"],
    purchaseCurrency: CurrencyFromJSON(json["purchase_currency"]),
    shopId: json["shop_id"],
    condition: BikeConditionFromJSON(json["condition"]),
    pictureId: !exists(json, "picture_id") ? undefined : json["picture_id"],
    url: !exists(json, "url") ? undefined : json["url"],
    id: !exists(json, "id") ? undefined : json["id"],
  };
}

export function BikeCreateUpdateToJSON(value?: BikeCreateUpdate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    serial_number: value.serialNumber,
    manufacturer: value.manufacturer,
    bike_brand_id: value.bikeBrandId,
    model: value.model,
    drive_type: BikeDriveTypeToJSON(value.driveType),
    frame_type: BikeFrameTypeToJSON(value.frameType),
    size: value.size,
    colour: value.colour,
    purchase_price: value.purchasePrice,
    purchase_currency: CurrencyToJSON(value.purchaseCurrency),
    shop_id: value.shopId,
    condition: BikeConditionToJSON(value.condition),
    picture_id: value.pictureId,
    url: value.url,
    id: value.id,
  };
}
