import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormMethods } from "@vapaus/form";
import { useEnumEntries } from "@vapaus/shared-api";
import { Select } from "@vapaus/ui-v2";

import { MaintenanceEventFormSchema } from "./useMaintenanceEventFormSchema";

type FormSelectProps = {
  formMethods: FormMethods<MaintenanceEventFormSchema>;
};

export function MaintenanceEventFormSelect({ formMethods }: FormSelectProps) {
  const { t } = useTranslation();
  const typeSelectError = formMethods.formState.errors.type?.message;
  const { entries: workTypeOptions } = useEnumEntries("WorkTypes");

  return (
    <Controller
      name="type"
      control={formMethods.control}
      render={({ field }) => (
        <Select
          label={t("shopV2:maintenance.eventForm.type.label")}
          placeholder={t("shopV2:maintenance.eventForm.type.placeholder")}
          options={workTypeOptions}
          value={field.value}
          onChange={(_, value) => field.onChange(value)}
          onBlur={field.onBlur}
          name={field.name}
          error={typeSelectError}
        />
      )}
    />
  );
}
