import { get } from "lodash-es";
import { RegisterOptions, useFormContext } from "react-hook-form";

import {
  NumberInput as UINumberInput,
  NumberInputProps as UINumberInputProps,
  valueToSafeString,
} from "@vapaus/ui-v2";

export type NumberInputProps = Omit<UINumberInputProps, "error" | "pattern"> &
  Pick<RegisterOptions, "validate"> & {
    name: string;
  };

export function NumberInput({
  name,
  required,
  min,
  max,
  maxLength,
  minLength,
  validate,
  value,
  onChange,
  onBlur,
  decimals = 2,
  ...rest
}: NumberInputProps) {
  const {
    register,
    formState: { errors, defaultValues },
    watch,
  } = useFormContext();
  // Annotation needed because typescript doesn't seem to get the type right somehow ¯\_(ツ)_/¯
  const errorMessage = get(errors, name)?.message as string | undefined;
  const parseFn = decimals > 0 ? parseFloat : parseInt;
  const formValue = value ?? watch(name);
  return (
    <UINumberInput
      {...register(name, {
        required,
        min,
        max,
        maxLength,
        minLength,
        validate,
        value,
        onChange,
        onBlur,
        setValueAs: (value) => {
          return value === "" ? undefined : parseFn(valueToSafeString(value));
        },
      })}
      decimals={decimals}
      defaultValue={get(defaultValues, name)}
      value={formValue}
      error={errorMessage}
      {...rest}
    />
  );
}
