/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BodyShopBenefitFinaliseBikeBenefitOrder
 */
export interface BodyShopBenefitFinaliseBikeBenefitOrder {
  /**
   * Bike Benefit Order finalisation date. In case of a regular Order it's the delivery date which will be used also as the start date of Bike Benefit Contract. In case of correcting existing Bike Benefit Contract this value won't be used.
   * @type {Date}
   * @memberof BodyShopBenefitFinaliseBikeBenefitOrder
   */
  date: Date;
  /**
   *
   * @type {string}
   * @memberof BodyShopBenefitFinaliseBikeBenefitOrder
   */
  bikeSerialNumber: string;
}

/**
 * Check if a given object implements the BodyShopBenefitFinaliseBikeBenefitOrder interface.
 */
export function instanceOfBodyShopBenefitFinaliseBikeBenefitOrder(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "date" in value;
  isInstance = isInstance && "bikeSerialNumber" in value;

  return isInstance;
}

export function BodyShopBenefitFinaliseBikeBenefitOrderFromJSON(
  json: any,
): BodyShopBenefitFinaliseBikeBenefitOrder {
  return BodyShopBenefitFinaliseBikeBenefitOrderFromJSONTyped(json, false);
}

export function BodyShopBenefitFinaliseBikeBenefitOrderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BodyShopBenefitFinaliseBikeBenefitOrder {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: new Date(json["date"]),
    bikeSerialNumber: json["bike_serial_number"],
  };
}

export function BodyShopBenefitFinaliseBikeBenefitOrderToJSON(
  value?: BodyShopBenefitFinaliseBikeBenefitOrder | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date.toISOString().substring(0, 10),
    bike_serial_number: value.bikeSerialNumber,
  };
}
