/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { AccessoryCreateUpdate } from "./AccessoryCreateUpdate";
import {
  AccessoryCreateUpdateFromJSON,
  AccessoryCreateUpdateFromJSONTyped,
  AccessoryCreateUpdateToJSON,
} from "./AccessoryCreateUpdate";
import type { BikeBenefitOrderAdditionalServiceWrite } from "./BikeBenefitOrderAdditionalServiceWrite";
import {
  BikeBenefitOrderAdditionalServiceWriteFromJSON,
  BikeBenefitOrderAdditionalServiceWriteFromJSONTyped,
  BikeBenefitOrderAdditionalServiceWriteToJSON,
} from "./BikeBenefitOrderAdditionalServiceWrite";
import type { BikeBenefitOrderDeliveryTransport } from "./BikeBenefitOrderDeliveryTransport";
import {
  BikeBenefitOrderDeliveryTransportFromJSON,
  BikeBenefitOrderDeliveryTransportFromJSONTyped,
  BikeBenefitOrderDeliveryTransportToJSON,
} from "./BikeBenefitOrderDeliveryTransport";
import type { BikeCreateUpdate } from "./BikeCreateUpdate";
import {
  BikeCreateUpdateFromJSON,
  BikeCreateUpdateFromJSONTyped,
  BikeCreateUpdateToJSON,
} from "./BikeCreateUpdate";

/**
 *
 * @export
 * @interface BikeBenefitOrderCreateUpdate
 */
export interface BikeBenefitOrderCreateUpdate {
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  benefitDefinitionId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  benefitDefinitionTermsId: string;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  leasingPeriodMonths: number;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  orderNumber?: string;
  /**
   *
   * @type {BikeCreateUpdate}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  bike?: BikeCreateUpdate;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  bikeId?: string;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  bikePrice: number;
  /**
   *
   * @type {Array<AccessoryCreateUpdate>}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  accessories: Array<AccessoryCreateUpdate>;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  fixedDownPaymentAmount?: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  fixedMonthlyTaxableValue?: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  monthlyMaintenanceBudget: number;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  hasExtendedWarranty?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  isDownPaymentInsured?: boolean;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  notes?: string;
  /**
   *
   * @type {BikeBenefitOrderDeliveryTransport}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  deliveryTransport?: BikeBenefitOrderDeliveryTransport;
  /**
   *
   * @type {Array<BikeBenefitOrderAdditionalServiceWrite>}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  additionalServices: Array<BikeBenefitOrderAdditionalServiceWrite>;
}

/**
 * Check if a given object implements the BikeBenefitOrderCreateUpdate interface.
 */
export function instanceOfBikeBenefitOrderCreateUpdate(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "userId" in value;
  isInstance = isInstance && "benefitDefinitionId" in value;
  isInstance = isInstance && "benefitDefinitionTermsId" in value;
  isInstance = isInstance && "leasingPeriodMonths" in value;
  isInstance = isInstance && "bikePrice" in value;
  isInstance = isInstance && "accessories" in value;
  isInstance = isInstance && "monthlyMaintenanceBudget" in value;
  isInstance = isInstance && "additionalServices" in value;

  return isInstance;
}

export function BikeBenefitOrderCreateUpdateFromJSON(
  json: any,
): BikeBenefitOrderCreateUpdate {
  return BikeBenefitOrderCreateUpdateFromJSONTyped(json, false);
}

export function BikeBenefitOrderCreateUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitOrderCreateUpdate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: json["user_id"],
    benefitDefinitionId: json["benefit_definition_id"],
    benefitDefinitionTermsId: json["benefit_definition_terms_id"],
    leasingPeriodMonths: json["leasing_period_months"],
    orderNumber: !exists(json, "order_number")
      ? undefined
      : json["order_number"],
    bike: !exists(json, "bike")
      ? undefined
      : BikeCreateUpdateFromJSON(json["bike"]),
    bikeId: !exists(json, "bike_id") ? undefined : json["bike_id"],
    bikePrice: json["bike_price"],
    accessories: (json["accessories"] as Array<any>).map(
      AccessoryCreateUpdateFromJSON,
    ),
    fixedDownPaymentAmount: !exists(json, "fixed_down_payment_amount")
      ? undefined
      : json["fixed_down_payment_amount"],
    fixedMonthlyTaxableValue: !exists(json, "fixed_monthly_taxable_value")
      ? undefined
      : json["fixed_monthly_taxable_value"],
    monthlyMaintenanceBudget: json["monthly_maintenance_budget"],
    hasExtendedWarranty: !exists(json, "has_extended_warranty")
      ? undefined
      : json["has_extended_warranty"],
    isDownPaymentInsured: !exists(json, "is_down_payment_insured")
      ? undefined
      : json["is_down_payment_insured"],
    notes: !exists(json, "notes") ? undefined : json["notes"],
    deliveryTransport: !exists(json, "delivery_transport")
      ? undefined
      : BikeBenefitOrderDeliveryTransportFromJSON(json["delivery_transport"]),
    additionalServices: (json["additional_services"] as Array<any>).map(
      BikeBenefitOrderAdditionalServiceWriteFromJSON,
    ),
  };
}

export function BikeBenefitOrderCreateUpdateToJSON(
  value?: BikeBenefitOrderCreateUpdate | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    user_id: value.userId,
    benefit_definition_id: value.benefitDefinitionId,
    benefit_definition_terms_id: value.benefitDefinitionTermsId,
    leasing_period_months: value.leasingPeriodMonths,
    order_number: value.orderNumber,
    bike: BikeCreateUpdateToJSON(value.bike),
    bike_id: value.bikeId,
    bike_price: value.bikePrice,
    accessories: (value.accessories as Array<any>).map(
      AccessoryCreateUpdateToJSON,
    ),
    fixed_down_payment_amount: value.fixedDownPaymentAmount,
    fixed_monthly_taxable_value: value.fixedMonthlyTaxableValue,
    monthly_maintenance_budget: value.monthlyMaintenanceBudget,
    has_extended_warranty: value.hasExtendedWarranty,
    is_down_payment_insured: value.isDownPaymentInsured,
    notes: value.notes,
    delivery_transport: BikeBenefitOrderDeliveryTransportToJSON(
      value.deliveryTransport,
    ),
    additional_services: (value.additionalServices as Array<any>).map(
      BikeBenefitOrderAdditionalServiceWriteToJSON,
    ),
  };
}
