import { Trans, useTranslation } from "react-i18next";

import {
  downloadShopTermRequest,
  useCurrentUser,
  useGetOrganisation,
} from "@vapaus/shared-api";
import { Card, HorizontalLine, LineItem } from "@vapaus/ui-v2";
import { useDownload } from "@vapaus/utils";

import { useShopContext } from "../../../contexts/ShopContext";

export function ShopAdminSection() {
  const { selectedShop: shop } = useShopContext();
  const { data: organisation } = useGetOrganisation(shop?.organisationId);
  const { t } = useTranslation();
  const { data } = useCurrentUser();
  const { download } = useDownload(() =>
    downloadShopTermRequest(data?.acceptedShopTermsId as string),
  );

  return (
    <Card
      title={t("shopV2:settings.shopAdmin.title")}
      subtitle={
        <Trans
          t={t}
          i18nKey="shopV2:settings.shopAdmin.contactUsMessage"
          components={{ 1: <strong /> }}
        />
      }
    >
      <LineItem
        name={t("shopV2:settings.shopAdmin.businessName")}
        content={organisation?.name}
      />
      <HorizontalLine />
      <LineItem
        name={t("shopV2:settings.shopAdmin.businessId")}
        content={organisation?.businessId}
      />
      <HorizontalLine />
      <LineItem
        name={t("shopV2:settings.shopAdmin.address")}
        content={
          <>
            <div>{shop?.streetAddress}</div>
            <div>
              {shop?.postalCode} {shop?.city}
            </div>
            <div>{shop?.country}</div>
          </>
        }
      />
      <HorizontalLine />
      <LineItem
        name={t("shopV2:settings.shopAdmin.termsOfService")}
        action={{
          label: t("commonV2:download"),
          onClick: () => download(undefined, "", `application/pdf`),
        }}
      />
    </Card>
  );
}
