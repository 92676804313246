import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import * as zod from "zod";

export type MaintenanceEventFormSchema = zod.infer<
  ReturnType<typeof useMaintenanceEventFormSchema>
>;

export function useMaintenanceEventFormSchema() {
  const { t } = useTranslation();
  return useMemo(
    () =>
      zod.object({
        // This is needed because of a bug in zod: https://github.com/colinhacks/zod/issues/1526
        date: zod.date({
          errorMap: (issue, { defaultError }) => ({
            message:
              issue.code === "invalid_date"
                ? t("commonV2:fieldCantBeEmpty")
                : defaultError,
          }),
        }),
        type: zod.string({ required_error: t("commonV2:fieldCantBeEmpty") }),
        partsPrice: zod
          .number({
            required_error: t("commonV2:fieldCantBeEmpty"),
            invalid_type_error: t("commonV2:validation.fieldMustBeANumber"),
          })
          .nonnegative({
            message: t(
              "shopV2:maintenance.eventForm.validation.priceCannotBeLowerThanZero",
            ),
          }),
        labourPrice: zod
          .number({
            required_error: t("commonV2:fieldCantBeEmpty"),
            invalid_type_error: t("commonV2:validation.fieldMustBeANumber"),
          })
          .nonnegative({
            message: t(
              "shopV2:maintenance.eventForm.validation.priceCannotBeLowerThanZero",
            ),
          }),
        serviceDescription: zod
          .string()
          .nonempty(t("commonV2:fieldCantBeEmpty")),
      }),
    [t],
  );
}
