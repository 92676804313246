import { useEffect } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useRevalidator } from "react-router-dom";

export function useRefetchAndRevalidateOnFocus(queryKeys: string[]) {
  const reValidator = useRevalidator();
  const queryClient = useQueryClient();
  useEffect(() => {
    const handleFocus = async () => {
      await Promise.all(
        queryKeys.map((queryKey) =>
          queryClient.invalidateQueries({
            queryKey: [queryKey],
          }),
        ),
      );
      reValidator.revalidate();
    };
    window.addEventListener("focus", handleFocus);
    return () => window.removeEventListener("focus", handleFocus);
  }, [reValidator, queryClient, queryKeys]);
}
