import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { MaintenanceEventRead, WorkTypes } from "@vapaus/api-codegen";
import { useFormMutation } from "@vapaus/form";
import { useToaster } from "@vapaus/ui-v2";

import { maintenanceEventsApi } from "../../../../../api/maintenanceEvents";
import {
  MaintenanceEventFormSchema,
  useMaintenanceEventFormSchema,
} from "../../MaintenanceEventForm/useMaintenanceEventFormSchema";

export function useEditMaintenanceEventForm(
  onClose: () => void,
  maintenanceEventId?: string,
  defaultValues?: MaintenanceEventFormSchema,
) {
  const { t } = useTranslation();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const schema = useMaintenanceEventFormSchema();

  const { formMethods, mutation } = useFormMutation<
    MaintenanceEventFormSchema,
    MaintenanceEventRead
  >(
    {
      mutationFn: (data) =>
        maintenanceEventsApi.maintenanceEventsUpdateMaintenanceEvent({
          maintenanceEventId: maintenanceEventId ?? "",
          shopMaintenanceEventUpdate: {
            partsPrice: data.partsPrice,
            workDate: new Date(data.date),
            workDescription: data.serviceDescription,
            workPrice: data.labourPrice,
            workType: data.type as WorkTypes,
          },
        }),
      onSuccess: () => {
        queryClient.invalidateQueries(["maintenanceEvents"]);
        toaster.add(
          "success",
          t("shopV2:maintenance.eventModal.editEventModal.successToast"),
        );
        onClose();
        formMethods.reset();
      },
    },
    {
      resolver: zodResolver(schema),
      defaultValues: {
        ...defaultValues,
        date:
          defaultValues &&
          (DateTime.fromJSDate(
            defaultValues?.date,
          ).toISODate() as unknown as Date),
      },
    },
  );

  return { formMethods, mutation };
}
