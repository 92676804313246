import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  CircularIcon,
  CloseIcon,
  Flex,
  PersonIcon,
  Typography,
  useBreakpoints,
} from "@vapaus/ui-v2";

import { Customer } from "../../../../../../types/Customer";

type CustomerCellProps = {
  customer?: Customer;
};

export function CustomerCell({ customer }: CustomerCellProps) {
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation();

  const CustomerIcon = customer ? PersonIcon : CloseIcon;

  return (
    <Flex>
      {!isMobile ? <CircularIcon icon={CustomerIcon} /> : null}
      <StyledFlex direction="column" gap="xss">
        <Typography>
          {customer?.name ?? t("shopV2:maintenance.table.noCustomer")}
        </Typography>
        {customer ? (
          <Typography variant="bodySm" weight="bold">
            {customer.email}
          </Typography>
        ) : null}
      </StyledFlex>
    </Flex>
  );
}

const StyledFlex = styled(Flex)`
  overflow: hidden;
`;
