import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  BenefitDefinitionSummaryRead,
  BikeBenefitOrderRead,
  UserSummaryRead,
} from "@vapaus/api-codegen";
import { FullPageModal, useToaster } from "@vapaus/ui-v2";

import { OrderForm } from "../OrderForm";
import { CancelOrderModal } from "./CancelOrderModal";
import { ChangeCustomerModal } from "./ChangeCustomerModal";
import { CustomerBenefitSearch } from "./CustomerBenefitSearch";
import { ModalViewType } from "./types";

type OrderCreationModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function OrderCreationModal({
  isOpen,
  onClose,
}: OrderCreationModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toaster = useToaster();

  const [modalView, setModalView] = useState<ModalViewType>("search");
  const [searchEmail, setSearchEmail] = useState<string>();
  const [user, setUser] = useState<UserSummaryRead>();
  const [selectedBenefit, setSelectedBenefit] =
    useState<BenefitDefinitionSummaryRead>();
  const [isCancelOpen, setIsCancelOpen] = useState<boolean>(false);
  const [isChangeCustomerOpen, setIsChangeCustomerOpen] =
    useState<boolean>(false);

  const handleClose = () => {
    setSearchEmail("");
    onClose();
  };

  const handleGoBack = () => {
    if (modalView === "form") setIsChangeCustomerOpen(true);
    else handleClose();
  };

  const handleOnCancelOrder = () => setIsCancelOpen(true);

  const handleOnClose = () => {
    if (modalView === "search") handleClose();
    else handleOnCancelOrder();
  };

  const handleOnConfirmChangeCustomer = () => {
    setIsChangeCustomerOpen(false);
    setModalView("search");
    setSelectedBenefit(undefined);
  };

  const handleOnConfirmCancelOrder = () => {
    setSearchEmail("");
    setModalView("search");
    setSelectedBenefit(undefined);
    setIsCancelOpen(false);
    onClose();
  };

  const handleOrderCreateSuccess = (order: BikeBenefitOrderRead) => {
    toaster.add("success", t("shopV2:orderCreation.successMessage"));
    navigate(order.id);
  };

  return (
    <>
      <FullPageModal
        isOpen={isOpen}
        onClose={handleOnClose}
        onBack={modalView === "form" ? handleGoBack : undefined}
        title={t("shopV2:orderCreation.title")}
        hasBorder={modalView === "form"}
      >
        {modalView === "search" && (
          <CustomerBenefitSearch
            searchEmail={searchEmail}
            selectedBenefit={selectedBenefit}
            setSearchEmail={setSearchEmail}
            setUser={setUser}
            setSelectedBenefit={setSelectedBenefit}
            setModalView={setModalView}
          />
        )}
        {modalView === "form" && (
          <OrderForm
            user={user!}
            benefitSummary={selectedBenefit!}
            onCancelOrder={handleOnClose}
            onSubmitSuccess={handleOrderCreateSuccess}
          />
        )}
      </FullPageModal>
      <CancelOrderModal
        isOpen={isCancelOpen}
        onClose={() => setIsCancelOpen(false)}
        onConfirmCancelOrder={handleOnConfirmCancelOrder}
      />
      <ChangeCustomerModal
        isOpen={isChangeCustomerOpen}
        onClose={() => setIsChangeCustomerOpen(false)}
        onConfirmChangeCustomer={handleOnConfirmChangeCustomer}
      />
    </>
  );
}
