import { useEffect } from "react";

import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Currency } from "@vapaus/api-codegen";
import { Card, Flex, InfoIcon, RowItem, Typography } from "@vapaus/ui-v2";

import { useCurrencyFormat } from "../../../../../../../../packages/i18n/src/hooks";
import { BenefitOrderFormType } from "../../utils";
import { BaseOrderFormSectionProps } from "../types";
import { BudgetBox } from "./BudgetBox";
import { useMaintenanceOptions } from "./useMaintenanceOptions";

export function MaintenanceBudgetForm({
  currency = Currency.Eur,
}: BaseOrderFormSectionProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(currency);
  const {
    watch,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<BenefitOrderFormType>();

  const { maintenanceOptions } = useMaintenanceOptions(currency);
  const [
    monthlyMaintenanceBudget,
    leasingPeriodMonths,
    fixedDownPaymentAmount,
  ] = watch([
    "monthlyMaintenanceBudget",
    "leasingPeriodMonths",
    "fixedDownPaymentAmount",
  ]);
  const hasDownPayment = !!fixedDownPaymentAmount;

  const totalBudget =
    (monthlyMaintenanceBudget ?? 0) * (leasingPeriodMonths ?? 0);

  useEffect(() => {
    if (hasDownPayment && monthlyMaintenanceBudget !== 0)
      setValue("monthlyMaintenanceBudget", 0);
  }, [hasDownPayment, monthlyMaintenanceBudget, setValue]);

  return (
    <Card
      title={t("shopV2:orderForm.maintenanceBudget.title")}
      subtitle={t("shopV2:orderForm.maintenanceBudget.subtitle")}
    >
      <Flex gap="md" direction="column">
        <Controller
          control={control}
          name="monthlyMaintenanceBudget"
          render={({ field: { onChange, value } }) => (
            <Flex gap="md" xsDirection="column">
              {maintenanceOptions.map((option) => (
                <BudgetBox
                  key={option.description}
                  label={option.label}
                  description={option.description}
                  disabled={hasDownPayment && option.value > 0}
                  selected={option.value === value}
                  onClick={() => onChange(option.value)}
                />
              ))}
            </Flex>
          )}
        />
        {hasDownPayment && (
          <StyledInfoBox padding="sm">
            <InfoIcon />
            <Typography weight="bold">
              {t("shopV2:orderForm.maintenanceBudget.downPaymentError")}
            </Typography>
          </StyledInfoBox>
        )}
        <RowItem
          label={t("shopV2:orderForm.maintenanceBudget.totalBudget")}
          value={
            !errors.root?.message
              ? formatCurrency(isNaN(totalBudget) ? 0 : totalBudget)
              : "-"
          }
          valueIsBold
        />
      </Flex>
    </Card>
  );
}

const StyledInfoBox = styled(Flex)`
  background: ${({ theme }) => theme.palette.secondary2.lighter};
  border-radius: ${({ theme }) => theme.spacing.xs};
  padding-left: ${({ theme }) => theme.spacing.md};
  padding-right: ${({ theme }) => theme.spacing.md};
`;
