import { useState } from "react";

import { useTranslation } from "react-i18next";

import { parseApiError, useRouterDataRefetch } from "@vapaus/shared-api";
import { Button, Modal, TextButton, useToaster } from "@vapaus/ui-v2";

import { useCancelBikeBenefitOrder } from "../../../api/order";

type CancelOrderButtonProps = {
  orderId: string;
  variant?: "button" | "text";
};

export function CancelOrderButton({
  orderId,
  variant = "button",
}: CancelOrderButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const toaster = useToaster();
  const cancelOrder = useCancelBikeBenefitOrder();
  const handleRefetch = useRouterDataRefetch();

  const handleCloseModal = () => setIsOpen(false);

  const handleCancelOrder = () => {
    cancelOrder.mutate(orderId, {
      onSuccess: async () => {
        toaster.add(
          "success",
          t(
            "shopV2:orderViewPage.heroSection.cancelOrderButton.successMessage",
          ),
        );
        await handleRefetch();
        handleCloseModal();
      },
      onError: async (error) => {
        const message = await parseApiError(error);
        toaster.add("error", message);
      },
    });
  };

  return (
    <>
      {variant === "button" ? (
        <Button
          variant="outlined"
          size="small"
          onClick={() => setIsOpen(true)}
          xsFullWidth
        >
          {t("shopV2:orderViewPage.heroSection.cancelOrderButton.title")}
        </Button>
      ) : (
        <TextButton onClick={() => setIsOpen(true)}>
          {t("shopV2:orderViewPage.heroSection.cancelOrderButton.title")}
        </TextButton>
      )}

      <Modal
        isOpen={isOpen}
        title={t("shopV2:orderViewPage.heroSection.cancelOrderButton.title")}
        description={t(
          "shopV2:orderViewPage.heroSection.cancelOrderButton.description",
        )}
        confirmText={t(
          "shopV2:orderViewPage.heroSection.cancelOrderButton.title",
        )}
        cancelText={t("commonV2:cancel")}
        onCancel={handleCloseModal}
        onClose={handleCloseModal}
        onConfirm={handleCancelOrder}
        isSubmitting={cancelOrder.isLoading}
        confirmButtonVariant="primaryDanger"
      />
    </>
  );
}
