import { ReactNode } from "react";

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import {
  BuildIcon,
  MobileNavigationButton,
  PedalBikeIcon,
  SupportIcon,
} from "@vapaus/ui-v2";

import { Path } from "../../../constants/paths";

export function MobileNavigationButtons(): ReactNode {
  const { t } = useTranslation();

  return (
    <>
      <NavLink to={Path.orders}>
        {({ isActive }) => (
          <MobileNavigationButton
            icon={PedalBikeIcon}
            label={t("shopV2:navigation.buttons.orders")}
            isActivated={isActive}
          />
        )}
      </NavLink>
      <NavLink to={Path.maintenance}>
        {({ isActive }) => (
          <MobileNavigationButton
            icon={BuildIcon}
            label={t("shopV2:navigation.buttons.maintenance")}
            isActivated={isActive}
          />
        )}
      </NavLink>
      <NavLink to={Path.support}>
        {({ isActive }) => (
          <MobileNavigationButton
            icon={SupportIcon}
            label={t("shopV2:navigation.buttons.support")}
            isActivated={isActive}
          />
        )}
      </NavLink>
    </>
  );
}
