import { useTranslation } from "react-i18next";

import { TableEmptyState } from "@vapaus/common-v2";
import { Lens3DIcon, Table } from "@vapaus/ui-v2";

import {
  ServiceHistoryTableDataType,
  useServiceHistoryTable,
} from "./useServiceHistoryTable";

type ServiceHistoryTableProps = {
  frameNumber: string;
};

export function ServiceHistoryTable({ frameNumber }: ServiceHistoryTableProps) {
  const { t } = useTranslation();
  const { rows, columns, sortingOptions, paginationOptions } =
    useServiceHistoryTable(frameNumber);

  return (
    <Table<ServiceHistoryTableDataType>
      data={rows}
      columns={columns}
      paginationOptions={paginationOptions}
      sortingOptions={sortingOptions}
      emptyStateComponent={
        <TableEmptyState
          icon={<Lens3DIcon size="lg" />}
          title={t("shopV2:maintenance.wizard.bikeView.table.emptyState.title")}
          subtitle={t(
            "shopV2:maintenance.wizard.bikeView.table.emptyState.subtitle",
          )}
        />
      }
    />
  );
}
