import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { TableEmptyState } from "@vapaus/common-v2";
import { Card, SearchBar, Settings3DIcon } from "@vapaus/ui-v2";

import { MaintenanceEventsTable } from "./MaintenanceEventsTable/MaintenanceEventsTable";
import { useMaintenanceEventsTable } from "./MaintenanceEventsTable/useMaintenanceEventsTable";

export function MaintenanceEventsCard() {
  const { t } = useTranslation();

  const {
    searchPhrase,
    setSearchPhrase,
    paginationOptions,
    sortingOptions,
    fetchedData,
    isLoading,
  } = useMaintenanceEventsTable();

  const shouldNotShowMaintenanceEventsTable =
    !fetchedData?.total && !searchPhrase;

  return (
    <Card>
      {isLoading ? null : shouldNotShowMaintenanceEventsTable ? (
        <TableEmptyState
          icon={<Settings3DIcon size="lg" />}
          title={t("shopV2:maintenance.emptyState.title")}
          subtitle={t("shopV2:maintenance.emptyState.subtitle")}
        />
      ) : (
        <>
          <StyledSearchBar
            placeholder={t("commonV2:search")}
            onSearch={setSearchPhrase}
          />
          <MaintenanceEventsTable
            data={fetchedData}
            paginationOptions={paginationOptions}
            sortingOptions={sortingOptions}
          />
        </>
      )}
    </Card>
  );
}

const StyledSearchBar = styled(SearchBar)`
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;
