/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BikeDataCheckIn
 */
export interface BikeDataCheckIn {
  /**
   * Leave empty in case of creating a Bike
   * @type {string}
   * @memberof BikeDataCheckIn
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof BikeDataCheckIn
   */
  serialNumber: string;
}

/**
 * Check if a given object implements the BikeDataCheckIn interface.
 */
export function instanceOfBikeDataCheckIn(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "serialNumber" in value;

  return isInstance;
}

export function BikeDataCheckInFromJSON(json: any): BikeDataCheckIn {
  return BikeDataCheckInFromJSONTyped(json, false);
}

export function BikeDataCheckInFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeDataCheckIn {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    serialNumber: json["serial_number"],
  };
}

export function BikeDataCheckInToJSON(value?: BikeDataCheckIn | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    serial_number: value.serialNumber,
  };
}
