import { useQueryClient } from "@tanstack/react-query";
import { useRevalidator } from "react-router-dom";

export function useRouterDataRefetch() {
  const reValidator = useRevalidator();
  const queryClient = useQueryClient();

  const handleRefetch = async (queryKey?: string[]) => {
    await queryClient.invalidateQueries(queryKey);
    reValidator.revalidate();
  };

  return handleRefetch;
}
