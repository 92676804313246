import { useTranslation } from "react-i18next";

import { HorizontalLine, RowItem } from "@vapaus/ui-v2";

import {
  useCurrencyFormat,
  useDateFormat,
} from "../../../../../../../packages/i18n/src/hooks/";
import { BaseOrderViewProps } from "../../type";
import { OrderSectionWithEdit } from "./OrderSectionWithEdit";

export function Delivery({ order, benefitDefinition }: BaseOrderViewProps) {
  const { t } = useTranslation();
  const { deliveryTransport, currency } = order;
  const formatCurrency = useCurrencyFormat(currency);
  const formatData = useDateFormat();

  if (!deliveryTransport) return null;
  return (
    <OrderSectionWithEdit
      title={t("shopV2:orderViewPage.delivery.title")}
      order={order}
      benefitDefinition={benefitDefinition}
      editingSection="Delivery"
    >
      <RowItem
        label={t("shopV2:orderViewPage.delivery.deliveryType")}
        value={t("shopV2:orderViewPage.customerDetails.delivery")}
      />
      <RowItem
        label={t("shopV2:orderViewPage.delivery.estimatedDelivery")}
        value={formatData(deliveryTransport?.expectedDeliveryDate)}
      />
      <RowItem
        label={t("shopV2:orderViewPage.delivery.trackingUrl")}
        value={deliveryTransport?.trackingUrl || "-"}
      />
      <HorizontalLine />
      <RowItem
        label={t("shopV2:orderViewPage.delivery.price")}
        value={formatCurrency(deliveryTransport?.priceGross)}
        valueIsBold
      />
    </OrderSectionWithEdit>
  );
}
