import { QueryClient } from "@tanstack/react-query";
import { RouteObject } from "react-router-dom";

import { ErrorPage } from "@vapaus/common-v2";

import { Path } from "../../constants/paths";
import { MaintenanceTab } from "./MaintenanceTab";
import { OrderDetailsTab } from "./OrderDetailsTab";
import { OrderViewPage } from "./OrderViewPage";
import { orderViewRouteLoader } from "./loaders";

export const createOrderViewRoute = (
  queryClient: QueryClient,
): RouteObject => ({
  id: "orderView",
  path: ":orderId",
  element: <OrderViewPage />,
  loader: orderViewRouteLoader(queryClient),
  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <OrderDetailsTab />,
    },
    {
      path: Path.maintenance,
      element: <MaintenanceTab />,
    },
  ],
});
