import { useState } from "react";

import { animated, useSpring } from "@react-spring/web";
import { useTranslation } from "react-i18next";

import { BikeBenefitContractForMaintenanceRead } from "@vapaus/api-codegen";
import { FullPageModal } from "@vapaus/ui-v2";

import { BikeView } from "./BikeView";
import { SearchView } from "./SearchView";

type MaintenanceEventCreationWizardProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function MaintenanceEventCreationWizard({
  isOpen,
  onClose,
}: MaintenanceEventCreationWizardProps) {
  const { t } = useTranslation();
  const [selectedBikeContract, setSelectedBikeContract] =
    useState<BikeBenefitContractForMaintenanceRead | null>(null);

  const handleClose = () => {
    setSelectedBikeContract(null);
    onClose();
  };

  const selectBikeContract = (
    contract: BikeBenefitContractForMaintenanceRead,
  ) => setSelectedBikeContract(contract);

  const bikeViewAnimation = useSpring({
    transform: selectedBikeContract ? "translateX(0%)" : "translateX(100%)",
    config: { tension: 210, friction: 20, duration: 300 },
  });

  return (
    <FullPageModal
      title={t("shopV2:maintenance.wizard.header")}
      isOpen={isOpen}
      onClose={handleClose}
      onBack={
        selectedBikeContract ? () => setSelectedBikeContract(null) : undefined
      }
      topBarBgColor="secondary1.main"
    >
      {!selectedBikeContract ? (
        <SearchView selectBikeContract={selectBikeContract} />
      ) : (
        <animated.div style={bikeViewAnimation}>
          <BikeView contract={selectedBikeContract} />
        </animated.div>
      )}
    </FullPageModal>
  );
}
