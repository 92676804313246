import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Currency } from "@vapaus/api-codegen";

import { useCurrencyFormat } from "../../../../../../../../packages/i18n/src/hooks";
import { BenefitOrderFormType } from "../../utils";

export function useMaintenanceOptions(currency: Currency = Currency.Eur) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(currency);
  const currencySymbol = formatCurrency(0).replace(/[0-9\s.,]/g, "");
  const { watch } = useFormContext<BenefitOrderFormType>();
  const monthlyMaintenanceBudget = watch("monthlyMaintenanceBudget");

  const CURRENCY_MAINTENANCES = {
    [Currency.Eur]: [0, 12, 16, 20],
    [Currency.Sek]: [0, 50, 100, 200],
  };

  const maintenanceOptionDescription = [
    t("shopV2:orderForm.maintenanceBudget.option.noBudget"),
    t("shopV2:orderForm.maintenanceBudget.option.lowBudget"),
    t("shopV2:orderForm.maintenanceBudget.option.mediumBudget"),
    t("shopV2:orderForm.maintenanceBudget.option.highBudget"),
  ];

  const maintenanceOptions = CURRENCY_MAINTENANCES[currency].map(
    (value, i) => ({
      value,
      label: t("shopV2:orderForm.maintenanceBudget.budgetUnit", {
        value,
        currency: currencySymbol,
      }),
      description: maintenanceOptionDescription[i],
    }),
  );

  const selectedOption = maintenanceOptions.find(
    (option) => option.value === monthlyMaintenanceBudget,
  );

  return { maintenanceOptions, selectedOption };
}
