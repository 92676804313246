import { useMemo } from "react";

import { ColumnDef, Row } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  BikeBenefitOrderState,
  PaginatedBikeBenefitOrderSummaryOut,
} from "@vapaus/api-codegen";
import { TableEmptyState } from "@vapaus/common-v2";
import {
  Lens3DIcon,
  PaginationProps,
  SortingProps,
  Table,
  useBreakpoints,
} from "@vapaus/ui-v2";

import { DateCell } from "../../../../components/tableCells/DateCell";
import { PriceCell } from "../../../../components/tableCells/PriceCell";
import { Bike } from "../../../../types/Bike";
import { Customer } from "../../../../types/Customer";
import { Price } from "../../../../types/Price";
import { BikeCell } from "./components/cells/BikeCell";
import { CustomerCell } from "./components/cells/CustomerCell";
import { OrderStatusCell } from "./components/cells/OrderStatusCell";

type OrdersTableDataType = {
  orderId: string;
  customer: Customer;
  bike: Bike;
  price: Price;
  frameNumber: string;
  lastUpdated: Date;
  orderStatus: BikeBenefitOrderState;
};

type OrdersTableProps = {
  data?: PaginatedBikeBenefitOrderSummaryOut;
  paginationOptions: PaginationProps;
  sortingOptions: SortingProps;
};

export function OrdersTable({
  data,
  paginationOptions,
  sortingOptions,
}: OrdersTableProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isXl, isLg, isXs } = useBreakpoints();

  const handleViewOrder = (row: OrdersTableDataType) => navigate(row.orderId);

  const getTableRows = (): OrdersTableDataType[] => {
    if (!data) {
      return [];
    }

    return data.items.map((item) => ({
      orderId: item.id,
      customer: {
        name: item.user?.fullName || "",
        email: item.user?.email || "",
        vapausCode: item.vapausCode,
      },
      bike: {
        model: item.bike.model,
        bikeBrand: item.bike.bikeBrand,
      },
      price: {
        total: item.totalPackagePriceWithoutMaintenance,
        currency: item.benefitDefinition.currency,
      },
      frameNumber: item.bike.serialNumber,
      lastUpdated: item.updatedAt as Date,
      orderStatus: item.state,
    }));
  };

  const columns = useMemo<ColumnDef<OrdersTableDataType>[]>(
    () => [
      {
        id: "customer",
        header: t("shopV2:orders.table.customer"),
        cell: ({ row }) => <CustomerCell customer={row.original.customer} />,
        accessorFn: (row) => row.customer,
        enableSorting: true,
        size: 25,
      },
      ...(!isXs
        ? [
            {
              id: "bike",
              header: t("shopV2:orders.table.bike"),
              cell: ({ row }: { row: Row<OrdersTableDataType> }) => (
                <BikeCell bike={row.original.bike} />
              ),
              accessorFn: (row: Row<OrdersTableDataType>) => row.original.bike,
              enableSorting: true,
              size: 15,
            },
          ]
        : []),
      ...(isLg || isXl
        ? [
            {
              id: "price",
              header: t("shopV2:orders.table.price"),
              cell: ({ row }: { row: Row<OrdersTableDataType> }) => (
                <PriceCell price={row.original.price} />
              ),
              accessorKey: "price",
              enableSorting: false,
            },
          ]
        : []),
      ...(isLg || isXl
        ? [
            {
              id: "frameNumber",
              header: t("shopV2:orders.table.frameNumber"),
              cell: ({ row }: { row: Row<OrdersTableDataType> }) =>
                row.original.frameNumber,
              accessorKey: "frameNumber",
              enableSorting: true,
              size: 15,
            },
          ]
        : []),
      ...(!isXs
        ? [
            {
              id: "lastUpdated",
              header: t("shopV2:orders.table.lastUpdated"),
              cell: ({ row }: { row: Row<OrdersTableDataType> }) => (
                <DateCell date={row.original.lastUpdated} />
              ),
              accessorKey: "lastUpdated",
              enableSorting: true,
              size: 15,
            },
          ]
        : []),
      {
        id: "orderStatus",
        header: t("shopV2:orders.table.orderStatus"),
        cell: ({ row }) => (
          <OrderStatusCell status={row.original.orderStatus} t={t} />
        ),
        accessorKey: "orderStatus",
        enableSorting: true,
        size: 18,
      },
    ],
    [t, isXs, isLg, isXl],
  );

  return (
    <Table<OrdersTableDataType>
      data={getTableRows()}
      columns={columns}
      paginationOptions={paginationOptions}
      sortingOptions={sortingOptions}
      emptyStateComponent={
        <TableEmptyState
          icon={<Lens3DIcon size="lg" />}
          title={t("shopV2:orders.table.emptyState.title")}
          subtitle={t("shopV2:orders.table.emptyState.subtitle")}
        />
      }
      onRowClick={handleViewOrder}
    />
  );
}
