import { useState } from "react";

import {
  BenefitDefinitionReadWithOrganisationSummary,
  BikeBenefitOrderExtraRead,
} from "@vapaus/api-codegen";
import { Button, ButtonVariants, Tooltip } from "@vapaus/ui-v2";

import { OrderEditingModal } from "../OrderEditingModal";
import { FormSectionType } from "../OrderForm";

type EditOrderButtonProps = {
  variant?: ButtonVariants;
  label: string;
  order: BikeBenefitOrderExtraRead;
  benefitDefinition: BenefitDefinitionReadWithOrganisationSummary;
  editingSection?: FormSectionType;
  disabled?: boolean;
  disabledTooltipMessage?: string;
};

export function EditOrderButton({
  variant,
  label,
  order,
  benefitDefinition,
  editingSection,
  disabled,
  disabledTooltipMessage,
}: EditOrderButtonProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);

  return (
    <>
      <Tooltip
        trigger={
          <Button
            onClick={handleOpenModal}
            variant={variant}
            size="small"
            xsFullWidth
            disabled={disabled}
          >
            {label}
          </Button>
        }
        disabled={!disabled || !disabledTooltipMessage}
      >
        {disabledTooltipMessage}
      </Tooltip>

      <OrderEditingModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        benefitDefinition={benefitDefinition}
        order={order}
        editingSection={editingSection}
      />
    </>
  );
}
