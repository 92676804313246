/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  BikeBrand,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  OrderDirection,
  PaginatedBikeBrandOut,
  PaginatedShopBikeBrandOut,
  ShopBikeBrand,
  ShopBikeBrandWrite,
} from "../models/index";
import {
  BikeBrandFromJSON,
  BikeBrandToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  OrderDirectionFromJSON,
  OrderDirectionToJSON,
  PaginatedBikeBrandOutFromJSON,
  PaginatedBikeBrandOutToJSON,
  PaginatedShopBikeBrandOutFromJSON,
  PaginatedShopBikeBrandOutToJSON,
  ShopBikeBrandFromJSON,
  ShopBikeBrandToJSON,
  ShopBikeBrandWriteFromJSON,
  ShopBikeBrandWriteToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface ShopBikeBrandsAddShopBikeBrandRequest {
  shopBikeBrandWrite: ShopBikeBrandWrite;
}

export interface ShopBikeBrandsGetBikeBrandRequest {
  bikeBrandId: string;
}

export interface ShopBikeBrandsRemoveShopBikeBrandRequest {
  shopBikeBrandId: string;
}

export interface ShopBikeBrandsSearchBikeBrandsRequest {
  active?: boolean;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

export interface ShopBikeBrandsSearchShopBikeBrandsRequest {
  shopId?: string;
  brandId?: string;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

/**
 *
 */
export class ShopBikeBrandsApi extends runtime.BaseAPI {
  /**
   * Add Shop Bike Brand
   */
  async shopBikeBrandsAddShopBikeBrandRaw(
    requestParameters: ShopBikeBrandsAddShopBikeBrandRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ShopBikeBrand>> {
    if (
      requestParameters.shopBikeBrandWrite === null ||
      requestParameters.shopBikeBrandWrite === undefined
    ) {
      throw new runtime.RequiredError(
        "shopBikeBrandWrite",
        "Required parameter requestParameters.shopBikeBrandWrite was null or undefined when calling shopBikeBrandsAddShopBikeBrand.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/shop-bike-brands`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ShopBikeBrandWriteToJSON(requestParameters.shopBikeBrandWrite),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShopBikeBrandFromJSON(jsonValue),
    );
  }

  /**
   * Add Shop Bike Brand
   */
  async shopBikeBrandsAddShopBikeBrand(
    requestParameters: ShopBikeBrandsAddShopBikeBrandRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ShopBikeBrand> {
    const response = await this.shopBikeBrandsAddShopBikeBrandRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Bike Brand
   */
  async shopBikeBrandsGetBikeBrandRaw(
    requestParameters: ShopBikeBrandsGetBikeBrandRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BikeBrand>> {
    if (
      requestParameters.bikeBrandId === null ||
      requestParameters.bikeBrandId === undefined
    ) {
      throw new runtime.RequiredError(
        "bikeBrandId",
        "Required parameter requestParameters.bikeBrandId was null or undefined when calling shopBikeBrandsGetBikeBrand.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/bike-brands/{bike_brand_id}`.replace(
          `{${"bike_brand_id"}}`,
          encodeURIComponent(String(requestParameters.bikeBrandId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BikeBrandFromJSON(jsonValue),
    );
  }

  /**
   * Get Bike Brand
   */
  async shopBikeBrandsGetBikeBrand(
    requestParameters: ShopBikeBrandsGetBikeBrandRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BikeBrand> {
    const response = await this.shopBikeBrandsGetBikeBrandRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Remove Shop Bike Brand
   */
  async shopBikeBrandsRemoveShopBikeBrandRaw(
    requestParameters: ShopBikeBrandsRemoveShopBikeBrandRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.shopBikeBrandId === null ||
      requestParameters.shopBikeBrandId === undefined
    ) {
      throw new runtime.RequiredError(
        "shopBikeBrandId",
        "Required parameter requestParameters.shopBikeBrandId was null or undefined when calling shopBikeBrandsRemoveShopBikeBrand.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/shop-bike-brands/{shop_bike_brand_id}`.replace(
          `{${"shop_bike_brand_id"}}`,
          encodeURIComponent(String(requestParameters.shopBikeBrandId)),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Remove Shop Bike Brand
   */
  async shopBikeBrandsRemoveShopBikeBrand(
    requestParameters: ShopBikeBrandsRemoveShopBikeBrandRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.shopBikeBrandsRemoveShopBikeBrandRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   * Search Bike Brands
   */
  async shopBikeBrandsSearchBikeBrandsRaw(
    requestParameters: ShopBikeBrandsSearchBikeBrandsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedBikeBrandOut>> {
    const queryParameters: any = {};

    if (requestParameters.active !== undefined) {
      queryParameters["active"] = requestParameters.active;
    }

    if (requestParameters.q !== undefined) {
      queryParameters["q"] = requestParameters.q;
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sort_field"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sort_direction"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.xFilterIds !== undefined &&
      requestParameters.xFilterIds !== null
    ) {
      headerParameters["X-Filter-Ids"] = String(requestParameters.xFilterIds);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/bike-brands`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedBikeBrandOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Bike Brands
   */
  async shopBikeBrandsSearchBikeBrands(
    requestParameters: ShopBikeBrandsSearchBikeBrandsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedBikeBrandOut> {
    const response = await this.shopBikeBrandsSearchBikeBrandsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Search Shop Bike Brands
   */
  async shopBikeBrandsSearchShopBikeBrandsRaw(
    requestParameters: ShopBikeBrandsSearchShopBikeBrandsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedShopBikeBrandOut>> {
    const queryParameters: any = {};

    if (requestParameters.shopId !== undefined) {
      queryParameters["shop_id"] = requestParameters.shopId;
    }

    if (requestParameters.brandId !== undefined) {
      queryParameters["brand_id"] = requestParameters.brandId;
    }

    if (requestParameters.q !== undefined) {
      queryParameters["q"] = requestParameters.q;
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sort_field"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sort_direction"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.xFilterIds !== undefined &&
      requestParameters.xFilterIds !== null
    ) {
      headerParameters["X-Filter-Ids"] = String(requestParameters.xFilterIds);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/shop-bike-brands`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedShopBikeBrandOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Shop Bike Brands
   */
  async shopBikeBrandsSearchShopBikeBrands(
    requestParameters: ShopBikeBrandsSearchShopBikeBrandsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedShopBikeBrandOut> {
    const response = await this.shopBikeBrandsSearchShopBikeBrandsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
