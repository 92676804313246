import { ReactNode } from "react";

import { Trans, useTranslation } from "react-i18next";

import {
  BenefitDefinitionReadWithOrganisationSummary,
  BikeBenefitContractState,
  BikeBenefitOrderCancellationReason,
  BikeBenefitOrderExtraRead,
  BikeBenefitOrderState,
} from "@vapaus/api-codegen";
import { PaletteVariants, TagVariant } from "@vapaus/ui-v2";

import { useDateFormat } from "../../../../../../packages/i18n/src/hooks/";
import {
  type OrderPermissionData,
  useGetOrderPermission,
} from "../useGetOrderPermission";

type OrderHeroData = {
  bgColor: PaletteVariants;
  statusType: TagVariant;
  statusDescription: ReactNode;
  statusLabel: string;
  disabledTitle: boolean;
} & OrderPermissionData;

export function useGetOrderHeroData(
  order: BikeBenefitOrderExtraRead,
  benefitDefinition: BenefitDefinitionReadWithOrganisationSummary,
  contractState?: BikeBenefitContractState,
  contractDeliveryDate?: Date,
): OrderHeroData {
  const { t } = useTranslation();

  let bgColor: PaletteVariants;
  let statusType: TagVariant;
  let statusLabel: string;
  let statusDescription: ReactNode;
  let disabledTitle: boolean;

  const { ...otherPermissions } = useGetOrderPermission(
    order,
    benefitDefinition,
    contractState,
  );

  const formateDate = useDateFormat();
  const updatedAt = formateDate(order.updatedAt);
  const deliveryDate = formateDate(contractDeliveryDate);

  switch (order.state) {
    case BikeBenefitOrderState.Draft: // -> In-Review
      bgColor = "secondary3.lighter";
      statusType = "pending";
      statusLabel = t("shopV2:orderViewPage.heroSection.stateInReview.label");
      statusDescription = (
        <Trans
          t={t}
          i18nKey="shopV2:orderViewPage.heroSection.stateInReview.description"
          // We're using updatedAt here for lack of better alternative, but a more specific date should be used in the future
          values={{ date: updatedAt }}
          components={{ 1: <strong /> }}
        />
      );
      disabledTitle = false;
      break;

    case BikeBenefitOrderState.ReadyForDelivery: // -> Accepted
      bgColor = "primary2.lighter";
      statusType = "waiting";
      statusLabel = t(
        "shopV2:orderViewPage.heroSection.stateReadyForDelivery.label",
      );
      statusDescription = t(
        "shopV2:orderViewPage.heroSection.stateReadyForDelivery.description",
      );
      disabledTitle = false;
      break;

    case BikeBenefitOrderState.WaitingForPayment: // -> Waiting for payment
      bgColor = "secondary2.lighter";
      statusType = "error";
      statusLabel = t(
        "shopV2:orderViewPage.heroSection.stateWaitingForPayment.label",
      );
      statusDescription = t(
        "shopV2:orderViewPage.heroSection.stateWaitingForPayment.description",
      );
      disabledTitle = false;
      break;

    case BikeBenefitOrderState.Finalised: // -> Delivered
      bgColor = "secondary1.lighter";
      statusType = "accepted";
      statusLabel = t("shopV2:orderViewPage.heroSection.stateDelivered.label");
      statusDescription = (
        <Trans
          t={t}
          i18nKey="shopV2:orderViewPage.heroSection.stateDelivered.description"
          values={{ date: deliveryDate }}
          components={{ 1: <strong /> }}
        />
      );
      disabledTitle = false;
      break;

    case BikeBenefitOrderState.Cancelled: // -> Cancelled
      bgColor = "background.white";
      statusType = "cancelled";
      statusLabel = t("shopV2:orderViewPage.heroSection.stateCancelled.label");
      statusDescription =
        order.cancellationReason ===
        BikeBenefitOrderCancellationReason.Expired ? (
          t(
            "shopV2:orderViewPage.heroSection.stateCancelled.description.expired",
          )
        ) : (
          <Trans
            t={t}
            i18nKey="shopV2:orderViewPage.heroSection.stateCancelled.description.default"
            // We're using updatedAt here for lack of better alternative, but a more specific date should be used in the future
            values={{
              date: updatedAt,
            }}
            components={{ 1: <strong /> }}
          />
        );
      disabledTitle = true;
      break;
  }

  return {
    bgColor,
    statusType,
    statusLabel,
    statusDescription,
    disabledTitle,
    ...otherPermissions,
  };
}
