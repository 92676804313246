import { useTranslation } from "react-i18next";

import { Modal } from "@vapaus/ui-v2";

type CancelOrderModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirmChangeCustomer: () => void;
};

export function ChangeCustomerModal({
  isOpen,
  onClose,
  onConfirmChangeCustomer,
}: CancelOrderModalProps) {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={onConfirmChangeCustomer}
      title={t("shopV2:orderCreation.changeCustomerModal.title")}
      description={t("shopV2:orderCreation.changeCustomerModal.description")}
      confirmText={t("shopV2:orderCreation.changeCustomerModal.confirm")}
      cancelText={t("commonV2:cancel")}
    />
  );
}
