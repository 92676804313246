import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { PageRoot } from "@vapaus/common-v2";
import {
  AddIcon,
  Button,
  CircularButton,
  Flex,
  Typography,
  useBreakpoints,
} from "@vapaus/ui-v2";

import { useShopContext } from "../../contexts/ShopContext";
import { MaintenanceEventCreationWizard } from "./components/MaintenanceEventCreationWizard/MaintenanceEventCreationWizard";
import { MaintenanceEventsCard } from "./components/MaintenanceEventsCard";

export function MaintenancePage() {
  const { selectedShop } = useShopContext();
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation();
  const [
    isMaintenanceEventCreationWizardOpen,
    setIsMaintenanceEventCreationWizardOpen,
  ] = useState<boolean>(false);

  const handleCloseWizard = () =>
    setIsMaintenanceEventCreationWizardOpen(false);

  return (
    <PageRoot size="wide">
      <StyledBackground />
      <Flex direction="column" gap="xl">
        <Flex justify="space-between" align="flex-end">
          <div>
            <Typography variant="h6" marginBottom="xxs">
              {selectedShop?.name}
            </Typography>
            <Typography variant="h1">
              {t("shopV2:maintenance.title")}
            </Typography>
          </div>
          {isMobile ? (
            <CircularButton
              icon={<AddIcon />}
              variant="secondary"
              onClick={() => setIsMaintenanceEventCreationWizardOpen(true)}
            />
          ) : (
            <Button
              onClick={() => setIsMaintenanceEventCreationWizardOpen(true)}
            >
              {t("shopV2:maintenance.newEvent")}
            </Button>
          )}
        </Flex>
        <MaintenanceEventsCard />
      </Flex>
      <MaintenanceEventCreationWizard
        isOpen={isMaintenanceEventCreationWizardOpen}
        onClose={handleCloseWizard}
      />
    </PageRoot>
  );
}

const StyledBackground = styled.div`
  position: absolute;
  z-index: -1;
  outline: ${({ theme }) => theme.border.secondary.main};
  height: 31rem;
  width: 100vw;
  left: 0;
  top: 0;
  background-color: ${({ theme }) => theme.palette.secondary1.main};
`;
