import { useQuery } from "@tanstack/react-query";

import {
  BenefitDefinitionReadWithOrganisationSummary,
  ResponseError,
  ShopBenefitDefinitionsApi,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const shopBenefitDefinitionsApi = new ShopBenefitDefinitionsApi(
  apiConfiguration,
);

export const getBenefitDefinitionQuery = (benefitDefinitionId: string) => ({
  queryKey: ["benefitDefinitions", benefitDefinitionId],
  queryFn: () =>
    shopBenefitDefinitionsApi.shopBenefitDefinitionsGetBenefitDefinition({
      benefitId: benefitDefinitionId,
    }),
});

export const useGetBenefitDefinition = (
  id?: string,
  options?: UseQueryOptions<BenefitDefinitionReadWithOrganisationSummary>,
) =>
  useQuery<BenefitDefinitionReadWithOrganisationSummary, ResponseError>({
    ...options,
    ...getBenefitDefinitionQuery(id as string),
    enabled: !!id,
  });
