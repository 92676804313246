import { get } from "lodash-es";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";

import {
  AutocompleteSelectAsync as UIAutocompleteSelectAsync,
  AutocompleteSelectAsyncProps as UIAutocompleteSelectAsyncProps,
} from "@vapaus/common-v2";

export type AutocompleteSelectProps<TValue = unknown> = Omit<
  UIAutocompleteSelectAsyncProps<TValue>,
  "error" | "pattern" | "value"
> &
  Pick<RegisterOptions, "validate" | "pattern"> & {
    name: string;
    required?: boolean;
  };

export function AutocompleteSelectAsync<TValue>({
  name,
  required,
  validate,
  onChange,
  pattern,
  ...rest
}: AutocompleteSelectProps<TValue>) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const errorMessage = get(errors, name)?.message as string | undefined;

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required, validate, pattern }}
      render={({ field }) => (
        <UIAutocompleteSelectAsync
          value={field.value}
          error={errorMessage}
          onChange={(_, option) => field.onChange(option?.value)}
          getOptionLabel={(option) => option.label ?? ""}
          {...rest}
        />
      )}
    />
  );
}
