import { useQuery } from "@tanstack/react-query";

import {
  BenefitDefinitionSummaryRead,
  ResponseError,
  ShopUsersApi,
  UserSummaryRead,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

const shopUserApi = new ShopUsersApi(apiConfiguration);

export const useGetUserByMail = (
  email?: string,
  options?: UseQueryOptions<UserSummaryRead>,
) =>
  useQuery<UserSummaryRead, ResponseError>(
    ["user", email],
    () =>
      shopUserApi.shopUsersShopGetUserByEmail({
        email: email as string,
      }),
    { retry: 0, enabled: !!email, ...options },
  );

export const useGetUserAvailableBenefits = (
  shopId: string,
  email?: string,
  options?: UseQueryOptions<Array<BenefitDefinitionSummaryRead>>,
) =>
  useQuery<Array<BenefitDefinitionSummaryRead>, ResponseError>(
    ["userBenefitDefinitions", shopId, email],
    () =>
      shopUserApi.shopUsersGetBenefitDefinitionsActivations({
        email: email as string,
        shopId,
      }),
    { retry: 0, enabled: !!email, ...options },
  );
