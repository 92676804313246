import { useTranslation } from "react-i18next";

import { Modal } from "@vapaus/ui-v2";

import { MaintenanceEventForm } from "../MaintenanceEventForm/MaintenanceEventForm";
import { useAddMaintenanceEventForm } from "./useAddMaintenanceEventForm";

type MaintenanceEventFormModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly contractId: string;
  readonly vatRate: number;
  readonly currency: string;
  readonly handleBudgetChange: (budgetDifference: number) => void;
};

export function AddMaintenanceEventModal({
  isOpen,
  onClose,
  contractId,
  vatRate,
  currency,
  handleBudgetChange,
}: MaintenanceEventFormModalProps) {
  const { t } = useTranslation();

  const { formMethods, mutation } = useAddMaintenanceEventForm(
    contractId,
    onClose,
    handleBudgetChange,
  );

  const handleCancel = () => {
    onClose();
    formMethods.reset();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t("shopV2:maintenance.wizard.bikeView.newEventModal.title")}
      description={t(
        "shopV2:maintenance.wizard.bikeView.newEventModal.description",
      )}
      cancelText={t("commonV2:cancel")}
      confirmText={t("commonV2:submit")}
      onCancel={handleCancel}
      onClose={onClose}
      onConfirm={formMethods._handleSubmit}
      isSubmitting={mutation.isLoading}
    >
      <MaintenanceEventForm
        currency={currency}
        vatRate={vatRate}
        formMethods={formMethods}
      />
    </Modal>
  );
}
