import { useEffect, useMemo, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useRevalidator } from "react-router-dom";
import invariant from "tiny-invariant";

import {
  BenefitDefinitionSummaryRead,
  BikeBenefitOrderExtraRead,
  BikeBenefitOrderRead,
  ProductTypes,
  UserSummaryRead,
} from "@vapaus/api-codegen";
import { useFormMutation } from "@vapaus/form";
import { useDebounce } from "@vapaus/utils";

import { useGetBenefitDefinition } from "../../../../../api/benefitDefinition";
import { useCheckBikeData } from "../../../../../api/bike";
import { shopBenefitApi } from "../../../../../api/bikeOrders";
import { useGetProductsVatRates } from "../../../../../api/productVatRates";
import { useShopContext } from "../../../../../contexts/ShopContext";
import { convertFormToSubmittableOrderRecord } from "../../utils/convertFormToSubmittableOrderRecord/convertFormToSubmittableOrderRecord";
import { getOrderFormDefaultValues } from "../../utils/getOrderFormDefaultValues/getOrderFormDefaultValues";
import {
  BenefitOrderFormType,
  benefitOrderFormSchema,
} from "../../utils/orderFormSchema/orderFormSchema";
import { useOrderFormCalculatedParams } from "../useOrderFormCalculatedParams/useOrderFormCalculatedParams";

// TODO: Let's refactor this to separate it into useOrderCreationForm & useOrderEditingForm later
export function useOrderForm(
  user: UserSummaryRead,
  benefitSummary: BenefitDefinitionSummaryRead,
  onSubmitSuccess: (order: BikeBenefitOrderRead) => void,
  editingOrder?: BikeBenefitOrderExtraRead,
) {
  const { t } = useTranslation();
  const { selectedShop } = useShopContext();
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();
  const [isBikeDataCheckResultModalOpen, setIsBikeDataCheckResultModalOpen] =
    useState(false);
  const [confirmBikeDataCheckResultModal, setConfirmBikeDataCheckResultModal] =
    useState(() => () => {});
  const [closeBikeDataCheckResultModal, setCloseBikeDataCheckResultModal] =
    useState(() => () => {});

  invariant(selectedShop?.id, "selectedShop.id must have a value");

  const formDefaultValues = getOrderFormDefaultValues(
    benefitSummary.currency,
    user.id,
    selectedShop.id,
    benefitSummary.id,
    editingOrder,
  );

  const { data: benefitDefinition } = useGetBenefitDefinition(
    benefitSummary.id,
  );
  const hasFixedTaxableValue =
    !!benefitDefinition?.fixedMonthlyTaxableValues?.length;

  const { data: productsVatRates } = useGetProductsVatRates(
    benefitSummary.organisation.country,
  );

  const getProductVatRate = (product: ProductTypes) =>
    productsVatRates?.[product] ??
    productsVatRates?.[ProductTypes.DefaultProduct];

  const orderFormSchema = useMemo(
    () => benefitOrderFormSchema(t, benefitDefinition),
    [t, benefitDefinition],
  );

  const save = (data: BenefitOrderFormType) => {
    invariant(benefitDefinition, "benefitDefinition must have a value");
    const bikeBenefitOrderCreateUpdate = convertFormToSubmittableOrderRecord(
      data,
      benefitDefinition.currency,
      benefitDefinition.plan,
      hasFixedTaxableValue,
      editingOrder,
    );
    return editingOrder
      ? shopBenefitApi.shopBenefitUpdateBikeBenefitOrder({
          bikeBenefitOrderId: editingOrder.id,
          bikeBenefitOrderCreateUpdate,
        })
      : shopBenefitApi.shopBenefitCreateBikeBenefitOrder({
          bikeBenefitOrderCreateUpdate,
        });
  };

  const { formMethods, mutation } = useFormMutation<
    BenefitOrderFormType,
    BikeBenefitOrderRead
  >(
    {
      mutationFn: (data) => {
        if (
          bikeDataCheckResult &&
          bikeDataCheckResult.serialNumber.warnings.length > 0
        ) {
          return new Promise((resolve, reject) => {
            setConfirmBikeDataCheckResultModal(() => () => {
              save(data).then(resolve).catch(reject);
              setIsBikeDataCheckResultModalOpen(false);
            });
            setCloseBikeDataCheckResultModal(() => () => {
              reject(null);
              setIsBikeDataCheckResultModalOpen(false);
            });
            setIsBikeDataCheckResultModalOpen(true);
          });
        } else {
          return save(data);
        }
      },
      onSuccess(order) {
        if (editingOrder) {
          queryClient.invalidateQueries({
            queryKey: ["orders"],
          });
          revalidator.revalidate();
        }
        onSubmitSuccess(order);
      },
    },
    {
      resolver: zodResolver(orderFormSchema),
      defaultValues: formDefaultValues,
    },
  );

  const currentBenefitDefinitionTermId =
    benefitDefinition?.currentBenefitDefinitionTerm?.id;
  useEffect(() => {
    const benefitDefinitionTermsId = formMethods.watch(
      "benefitDefinitionTermsId",
    );
    if (
      currentBenefitDefinitionTermId &&
      currentBenefitDefinitionTermId !== benefitDefinitionTermsId
    )
      formMethods.setValue(
        "benefitDefinitionTermsId",
        currentBenefitDefinitionTermId,
      );
  }, [currentBenefitDefinitionTermId, formMethods, formMethods.setValue]);

  const formSummary = useOrderFormCalculatedParams(
    formMethods,
    hasFixedTaxableValue,
    benefitDefinition,
  );

  const bikeData = formMethods.watch("bike");
  const bikeDataCheckParams = useDebounce(
    useMemo(
      () => ({ id: bikeData.id, serialNumber: bikeData.serialNumber }),
      [bikeData.id, bikeData.serialNumber],
    ),
  );
  const bikeDataCheckEnabled =
    !!bikeDataCheckParams.serialNumber &&
    bikeDataCheckParams.serialNumber.length > 0;

  const {
    data: bikeDataCheckResult,
    isSuccess: isBikeDataCheckSuccess,
    isLoading: isBikeDataCheckLoading,
  } = useCheckBikeData(bikeDataCheckParams, {
    enabled: bikeDataCheckEnabled,
  });

  const isSubmitEnabled =
    !formMethods.formState.errors.root?.message &&
    (!bikeDataCheckEnabled ||
      (isBikeDataCheckSuccess && !isBikeDataCheckLoading));

  return {
    formMethods,
    mutation,
    formSummary,
    benefitDefinition,
    getProductVatRate,
    bikeDataCheckResult,
    isBikeDataCheckResultModalOpen,
    closeBikeDataCheckResultModal,
    confirmBikeDataCheckResultModal,
    isSubmitEnabled,
  };
}
