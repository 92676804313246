import { useQuery } from "@tanstack/react-query";

import {
  BikeBrand,
  ResponseError,
  ShopBikeBrandsApi,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const shopBikeBrandsApi = new ShopBikeBrandsApi(apiConfiguration);

export const useGetBikeBrand = (
  id?: string,
  options?: UseQueryOptions<BikeBrand>,
) =>
  useQuery<BikeBrand, ResponseError>({
    queryKey: ["bikeBrands", id],
    queryFn: () =>
      shopBikeBrandsApi.shopBikeBrandsGetBikeBrand({
        bikeBrandId: id!,
      }),
    ...options,
    enabled: !!id,
  });
