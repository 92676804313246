import { useTranslation } from "react-i18next";

import { Modal } from "@vapaus/ui-v2";

type CancelOrderModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirmCancelOrder: () => void;
};

export function CancelOrderModal({
  isOpen,
  onClose,
  onConfirmCancelOrder,
}: CancelOrderModalProps) {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={onConfirmCancelOrder}
      title={t("shopV2:orderCreation.cancelOrderModal.title")}
      description={t("shopV2:orderCreation.cancelOrderModal.description")}
      confirmText={t("shopV2:orderCreation.cancelOrderModal.confirm")}
      cancelText={t("commonV2:cancel")}
      confirmButtonVariant="primaryDanger"
    />
  );
}
