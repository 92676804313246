import { useTranslation } from "react-i18next";
import { useRouteLoaderData } from "react-router-dom";

import { BikeBenefitContractForMaintenanceRead } from "@vapaus/api-codegen";
import { TableEmptyState } from "@vapaus/common-v2";
import { Card, Settings3DIcon } from "@vapaus/ui-v2";

import { BikeView } from "../MaintenancePage/components/MaintenanceEventCreationWizard/BikeView";
import { OrderViewRouteLoaderResult } from "./loaders";

export function MaintenanceTab() {
  const { t } = useTranslation();
  const { contract } = useRouteLoaderData(
    "orderView",
  ) as OrderViewRouteLoaderResult;

  return contract?.monthlyMaintenanceBudget ? (
    <BikeView
      contract={contract as BikeBenefitContractForMaintenanceRead}
      includeHeader={false}
    />
  ) : (
    <Card>
      <TableEmptyState
        icon={<Settings3DIcon size="lg" />}
        title={t("shopV2:orderViewPage.maintenance.emptyState.title")}
        subtitle={t("shopV2:orderViewPage.maintenance.emptyState.subtitle")}
      />
    </Card>
  );
}
