import { useTranslation } from "react-i18next";

import {
  Flex,
  HorizontalLine,
  Paper,
  RowItem,
  Typography,
} from "@vapaus/ui-v2";

import { useCurrencyFormat } from "../../../../../../../packages/i18n/src/hooks/";
import { BaseOrderViewProps } from "../../type";
import { useOrderSummary } from "../hooks/useOrderSummary";

export function OrderCustomerDetails({
  order,
  benefitDefinition,
}: BaseOrderViewProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(order.currency);
  const { monthlyTaxableValue } = useOrderSummary(order, benefitDefinition);

  return (
    <Paper shadow="md">
      <Flex direction="column" gap="md">
        <Flex direction="column" gap="xxs">
          <Typography variant="h2" align="center" marginBottom="none">
            {order.user?.fullName}
          </Typography>
          <Typography weight="bold" align="center" marginBottom="none">
            {benefitDefinition?.organisation.name} · {benefitDefinition?.name}
          </Typography>
          <Typography align="center">{order.user?.email}</Typography>
        </Flex>
        <Flex direction="column">
          <RowItem
            label={t("shopV2:orderViewPage.customerDetails.vapausCode")}
            value={order.vapausCode}
          />
          <RowItem
            label={t("shopV2:orderViewPage.customerDetails.frameNumber")}
            value={order.bike.serialNumber || "-"}
          />
          <RowItem
            label={t("shopV2:orderViewPage.customerDetails.orderNumber")}
            value={order.orderNumber || "-"}
          />
          <HorizontalLine marginBottom="xs" marginTop="xs" />
          <RowItem
            label={t("shopV2:orderViewPage.customerDetails.maintenanceBudget")}
            value={t(
              "shopV2:orderViewPage.customerDetails.maintenanceBudgetValue",
              { value: formatCurrency(order.monthlyMaintenanceBudget) },
            )}
          />
          <RowItem
            label={t("shopV2:orderViewPage.customerDetails.leasingPeriod")}
            value={t(
              "shopV2:orderViewPage.customerDetails.leasingPeriodValue",
              { value: order.leasingPeriodMonths },
            )}
          />
          <RowItem
            label={t("shopV2:orderViewPage.customerDetails.monthlyPayment")}
            value={formatCurrency(monthlyTaxableValue)}
          />
        </Flex>
        {order.notes && (
          <Paper background="neutral.lighter" padding="sm">
            <Typography>{order.notes}</Typography>
          </Paper>
        )}
      </Flex>
    </Paper>
  );
}
