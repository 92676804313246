import { TFunction } from "i18next";

export enum DeliveryMethod {
  delivery = "Delivery",
  pickUp = "Pickup",
}

export function getDeliveryLabel(
  t: TFunction,
  deliveryMethod: DeliveryMethod,
): string {
  const deliveryLabel = {
    [DeliveryMethod.delivery]: t("shopV2:orderForm.deliveryType.delivery"),
    [DeliveryMethod.pickUp]: t("shopV2:orderForm.deliveryType.pickUp"),
  };

  return deliveryLabel[deliveryMethod];
}
