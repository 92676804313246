import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useEnumEntries } from "@vapaus/shared-api";
import { Button, Card, Flex } from "@vapaus/ui-v2";

import { BaseOrderFormSectionProps } from "../types";
import { SingleAdditionalServiceForm } from "./SingleAdditionalServiceForm";

export function AdditionalServicesForm({
  currency,
  vatRate,
}: BaseOrderFormSectionProps) {
  const { t } = useTranslation();

  const { entries: additionalServiceTypes } = useEnumEntries(
    "AdditionalServiceType",
  );
  const { fields, append, remove } = useFieldArray({
    name: "additionalServices",
  });

  const handleAddAccessory = () => {
    append({
      _key: Date.now(),
      serviceType: null,
      priceGross: null,
    });
  };

  return (
    <Card
      title={t("shopV2:orderForm.additionalServices.title")}
      subtitle={t("shopV2:orderForm.additionalServices.subtitle")}
    >
      <Flex direction="column" gap="lg">
        {fields.map((service, index) => (
          <SingleAdditionalServiceForm
            key={service.id}
            additionalServiceTypes={additionalServiceTypes}
            index={index}
            onRemove={remove}
            currency={currency}
            vatRate={vatRate}
            fields={fields}
            id={service.id}
          />
        ))}
        <Flex>
          <Button type="button" variant="outlined" onClick={handleAddAccessory}>
            {t("shopV2:orderForm.additionalServices.add")}
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
}
