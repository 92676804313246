/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BikeBenefitOrderSummaryRead } from "./BikeBenefitOrderSummaryRead";
import {
  BikeBenefitOrderSummaryReadFromJSON,
  BikeBenefitOrderSummaryReadFromJSONTyped,
  BikeBenefitOrderSummaryReadToJSON,
} from "./BikeBenefitOrderSummaryRead";

/**
 *
 * @export
 * @interface PaginatedBikeBenefitOrderSummaryOut
 */
export interface PaginatedBikeBenefitOrderSummaryOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedBikeBenefitOrderSummaryOut
   */
  total: number;
  /**
   *
   * @type {Array<BikeBenefitOrderSummaryRead>}
   * @memberof PaginatedBikeBenefitOrderSummaryOut
   */
  items: Array<BikeBenefitOrderSummaryRead>;
}

/**
 * Check if a given object implements the PaginatedBikeBenefitOrderSummaryOut interface.
 */
export function instanceOfPaginatedBikeBenefitOrderSummaryOut(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "total" in value;
  isInstance = isInstance && "items" in value;

  return isInstance;
}

export function PaginatedBikeBenefitOrderSummaryOutFromJSON(
  json: any,
): PaginatedBikeBenefitOrderSummaryOut {
  return PaginatedBikeBenefitOrderSummaryOutFromJSONTyped(json, false);
}

export function PaginatedBikeBenefitOrderSummaryOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedBikeBenefitOrderSummaryOut {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(
      BikeBenefitOrderSummaryReadFromJSON,
    ),
  };
}

export function PaginatedBikeBenefitOrderSummaryOutToJSON(
  value?: PaginatedBikeBenefitOrderSummaryOut | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    items: (value.items as Array<any>).map(BikeBenefitOrderSummaryReadToJSON),
  };
}
