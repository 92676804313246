import * as React from "react";

import { SelectProps } from "@mui/base/Select/Select.types";
import { useTranslation } from "react-i18next";

import { DotFilledIcon, OrderApproveIcon, Select } from "@vapaus/ui-v2";

type OrderStateSelectProps = Pick<
  SelectProps<string, false>,
  "value" | "onChange" | "className"
>;

export function OrderStateSelect({
  value,
  onChange,
  className,
}: OrderStateSelectProps) {
  const { t } = useTranslation();

  const orderStatusSelectOptions = [
    {
      icon: <OrderApproveIcon />,
      value: "",
      label: t("shopV2:orders.status.allOrders"),
    },
    {
      icon: <DotFilledIcon color="secondary3.main" />,
      value: "draft",
      label: t("shopV2:orders.status.inReview"),
    },
    {
      icon: <DotFilledIcon color="secondary2.main" />,
      value: "waiting_for_payment",
      label: t("shopV2:orders.status.waitingForPayment"),
    },
    {
      icon: <DotFilledIcon color="primary2.main" />,
      value: "ready_for_delivery",
      label: t("shopV2:orders.status.readyForDelivery"),
    },
    {
      icon: <DotFilledIcon color="secondary1.main" />,
      value: "finalised",
      label: t("shopV2:orders.status.delivered"),
    },
    {
      icon: <DotFilledIcon color="error" />,
      value: "cancelled",
      label: t("shopV2:orders.status.cancelled"),
    },
  ];

  return (
    <Select<string>
      options={orderStatusSelectOptions}
      value={value}
      onChange={onChange}
      className={className}
    />
  );
}
