import { useTranslation } from "react-i18next";

import {
  Flex,
  HorizontalLine,
  Paper,
  RowItem,
  Typography,
} from "@vapaus/ui-v2";

import {
  useCurrencyFormat,
  useDateFormat,
  usePercentFormat,
} from "../../../../../../../packages/i18n/src/hooks/";
import { BaseOrderViewProps } from "../../type";
import { useOrderSummary } from "../hooks/useOrderSummary";

export function OrderSummary({ order, benefitDefinition }: BaseOrderViewProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(order.currency);
  const formatPercent = usePercentFormat();
  const formatDate = useDateFormat();
  const { totalBikePackage, partnerInvoice, partnerDiscount } = useOrderSummary(
    order,
    benefitDefinition,
  );

  return (
    <Paper shadow="md">
      <Flex direction="column" gap="md">
        <Flex direction="column" gap="xxs">
          <Typography variant="h2" align="center">
            {t("shopV2:orderViewPage.orderSummary.title")}
          </Typography>
          <Typography align="center" weight="bold">
            {t("shopV2:orderViewPage.orderSummary.subtitle")}
          </Typography>
          <Typography align="center">
            {t("shopV2:orderViewPage.orderSummary.hint", {
              value: formatPercent(order.flatVatRate),
            })}
          </Typography>
        </Flex>
        <Flex direction="column">
          <RowItem
            label={t("shopV2:orderViewPage.orderSummary.orderCreated")}
            value={formatDate(order.createdAt)}
          />
          <RowItem
            label={t("shopV2:orderViewPage.orderSummary.orderDelivered")}
            value={formatDate(order.deliveryDate)}
          />
          <RowItem
            label={t("shopV2:orderViewPage.orderSummary.deliveryMethod")}
            value={
              order.deliveryTransport
                ? t("shopV2:orderViewPage.orderSummary.delivery")
                : t("shopV2:orderViewPage.orderSummary.pickUp")
            }
          />
          <HorizontalLine marginBottom="xs" marginTop="xs" />
          <RowItem
            label={t("shopV2:orderViewPage.orderSummary.bikePackage")}
            value={formatCurrency(totalBikePackage)}
          />
          <RowItem
            label={t("shopV2:orderViewPage.orderSummary.partnerDiscount")}
            value={formatCurrency(
              partnerDiscount !== null ? partnerDiscount * -1 : null,
            )}
          />
          <RowItem
            label={t("shopV2:orderViewPage.orderSummary.invoiceTotal")}
            value={formatCurrency(partnerInvoice)}
            valueIsBold
          />
        </Flex>
      </Flex>
    </Paper>
  );
}
