import { useCallback } from "react";

import { BikeBenefitOrderState } from "@vapaus/api-codegen";
import { useTableParams } from "@vapaus/ui-v2";

import { useSearchBikeBenefitOrder } from "../../../../api/bikeOrders";
import { useShopContext } from "../../../../contexts/ShopContext";

export function useOrdersTable() {
  const { selectedShop } = useShopContext();
  const {
    q: searchQuery,
    selectedStateFilter,
    sorting,
    pagination,
    setSorting,
    setPagination,
    setSearchQuery,
    setExtraParams,
  } = useTableParams<{
    selectedStateFilter: BikeBenefitOrderState | "";
  }>({ sortId: "lastUpdated", sortDesc: true, selectedStateFilter: "" });

  const sortFieldMapping: Record<string, string> = {
    customer: "user.last_name",
    bike: "bike.bike_brand.name",
    frameNumber: "bike.serial_number",
    lastUpdated: "updated_at",
    orderStatus: "state",
  };

  const { data: fetchedData, isLoading } = useSearchBikeBenefitOrder(
    {
      shopId: selectedShop?.id || "",
      limit: pagination.pageSize,
      skip: pagination.pageIndex * pagination.pageSize,
      q: searchQuery,
      ...(sortFieldMapping[sorting[0]?.id]
        ? {
            sortField: sortFieldMapping[sorting[0]?.id],
            sortDirection: sorting[0]?.desc ? "desc" : "asc",
          }
        : null),
      state: selectedStateFilter as BikeBenefitOrderState,
    },
    {
      enabled: selectedShop != null,
      keepPreviousData: true,
    },
  );

  const handleSelectedStateFilterChange = useCallback(
    function handleSelectedStateFilterChange(_: unknown, value: string | null) {
      setExtraParams({
        selectedStateFilter: value as BikeBenefitOrderState,
      });
    },
    [setExtraParams],
  );

  return {
    searchQuery,
    setSearchQuery,
    selectedStateFilter,
    paginationOptions: {
      totalItems: fetchedData?.total ?? 0,
      pagination,
      setPagination,
    },
    sortingOptions: {
      sorting,
      setSorting,
    },
    handleSelectedStateFilterChange,
    fetchedData,
    isLoading,
  };
}
