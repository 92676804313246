/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface ShopBikeBrand
 */
export interface ShopBikeBrand {
  /**
   *
   * @type {string}
   * @memberof ShopBikeBrand
   */
  shopId: string;
  /**
   *
   * @type {string}
   * @memberof ShopBikeBrand
   */
  bikeBrandId: string;
  /**
   *
   * @type {string}
   * @memberof ShopBikeBrand
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof ShopBikeBrand
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof ShopBikeBrand
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the ShopBikeBrand interface.
 */
export function instanceOfShopBikeBrand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "shopId" in value;
  isInstance = isInstance && "bikeBrandId" in value;

  return isInstance;
}

export function ShopBikeBrandFromJSON(json: any): ShopBikeBrand {
  return ShopBikeBrandFromJSONTyped(json, false);
}

export function ShopBikeBrandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShopBikeBrand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    shopId: json["shop_id"],
    bikeBrandId: json["bike_brand_id"],
    id: !exists(json, "id") ? undefined : json["id"],
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    updatedAt: !exists(json, "updated_at")
      ? undefined
      : new Date(json["updated_at"]),
  };
}

export function ShopBikeBrandToJSON(value?: ShopBikeBrand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    shop_id: value.shopId,
    bike_brand_id: value.bikeBrandId,
    id: value.id,
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
  };
}
