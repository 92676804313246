/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ProductTypes {
  DEFAULT_PRODUCT = "Default product",
  BIKE = "Bike",
  ACCESSORY = "Accessory",
  ADDITIONAL_SERVICES = "Additional services",
  DELIVERY = "Delivery",
  PARTNER_MAINTENANCE_WORK = "Partner maintenance work",
  PARTNER_MAINTENANCE_PARTS = "Partner maintenance parts",
  MONTHLY_OFFICE_FEE = "Monthly office fee",
  MONTHLY_TAXABLE_VALUE = "Monthly taxable value",
  CANCELLATION_COVERAGE = "Cancellation coverage",
  DOWN_PAYMENT = "Down payment",
  DOWN_PAYMENT_INSURANCE = "Down payment insurance",
  EXTENDED_WARRANTY = "Extended warranty",
  INSURANCE_DEDUCTIBLE = "Insurance deductible",
  LATE_FEES = "Late fees",
  MAINTENANCE = "Maintenance",
  OVERSPENT_MAINTENANCE_BUDGET = "Overspent maintenance budget",
  REDEMPTION_FEE = "Redemption fee",
  REDEMPTION_FEES_ACCESSORIES_ONLY_ = "Redemption fees (accessories only)",
  REFUND = "Refund",
  RETURN_FEE = "Return fee",
}
