import { useTranslation } from "react-i18next";

import {
  BenefitDefinitionReadWithOrganisationSummary,
  BikeBenefitOrderExtraRead,
} from "@vapaus/api-codegen";
import { FullPageModal } from "@vapaus/ui-v2";

import { FormSectionType, OrderForm } from "../OrderForm";

type OrderEditingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  order: BikeBenefitOrderExtraRead;
  benefitDefinition: BenefitDefinitionReadWithOrganisationSummary;
  editingSection?: FormSectionType;
};

export function OrderEditingModal({
  isOpen,
  onClose,
  order,
  benefitDefinition,
  editingSection,
}: OrderEditingModalProps) {
  const { t } = useTranslation();

  const handleOrderCreateSuccess = () => {
    onClose();
  };

  return (
    <FullPageModal
      isOpen={isOpen}
      onClose={onClose}
      title={t("shopV2:orderCreation.editOrder")}
      hasBorder
    >
      {order && (
        <OrderForm
          user={order.user!}
          benefitSummary={benefitDefinition!}
          onCancelOrder={onClose}
          editingOrder={order}
          onSubmitSuccess={handleOrderCreateSuccess}
          editingSection={editingSection}
        />
      )}
    </FullPageModal>
  );
}
