import { useTranslation } from "react-i18next";

import { Accessory } from "@vapaus/api-codegen";
import { useEnumEntries } from "@vapaus/shared-api";
import { Flex, RowItem, Typography } from "@vapaus/ui-v2";

import {
  useCurrencyFormat,
  usePercentFormat,
} from "../../../../../../../packages/i18n/src/hooks/";

type AccessoryItemProps = {
  accessory: Accessory;
  accessoryDiscount: number;
};

export function AccessoryItem({
  accessory,
  accessoryDiscount,
}: AccessoryItemProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(accessory.purchaseCurrency);
  const formatPercent = usePercentFormat();
  const { getEnumLabel: getAccessoryTypeEnumLabel } =
    useEnumEntries("AccessoryType");
  const discountedPrice =
    accessory.purchasePrice - accessory.purchasePrice * accessoryDiscount;
  return (
    <Flex data-testid="accessory-item" direction="column" gap="xs">
      <Typography weight="bold" textTransform="capitalize">
        {getAccessoryTypeEnumLabel(accessory.accessoryType)}
      </Typography>
      <RowItem
        label={`${accessory.manufacturer} ${accessory.model}`}
        value={formatCurrency(accessory.purchasePrice)}
        valueIsBold
        labelIsBold={false}
      />
      <RowItem
        label={t("shopV2:orderViewPage.accessories.discountedPrice", {
          value: formatPercent(accessoryDiscount),
        })}
        value={formatCurrency(discountedPrice)}
        labelIsBold={false}
        typoVariant="bodySm"
      />
    </Flex>
  );
}
