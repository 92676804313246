import { SupportedOrganisationCountries } from "@vapaus/api-codegen";

import { useGetAccessoryTypes } from "../../../../api/accessoryTypes";

export function useFilterAccessoryTypesByCountry(
  country: SupportedOrganisationCountries,
) {
  const { data = [] } = useGetAccessoryTypes();

  const allowedAccessoryTypes = data.filter((accessoryType) =>
    accessoryType.availabilities.includes(country),
  );
  const notAllowedAccessoryTypes = data.filter(
    (accessoryType) =>
      !accessoryType.availabilities.includes(country) &&
      accessoryType.accessoryType !== "other",
  );

  return { allowedAccessoryTypes, notAllowedAccessoryTypes };
}
