import { useMemo, useState } from "react";

import { PaginationState, SortingState } from "@tanstack/react-table";

import { useSearchMaintenanceEvents } from "../../../../api/maintenanceEvents";
import { useShopContext } from "../../../../contexts/ShopContext";

export function useMaintenanceEventsTable() {
  const { selectedShop } = useShopContext();
  const [searchPhrase, setSearchPhrase] = useState<string>("");

  const [sorting, setSorting] = useState<SortingState>([
    { id: "date", desc: true },
  ]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const sortingOptions = {
    sorting,
    setSorting,
  };

  const mapSortField = () => {
    if (!sorting[0]) {
      return "work_date";
    }
    switch (sorting[0].id) {
      case "bike":
        return "bike.bike_brand.name";
      case "price":
        return "total_price";
      case "date":
        return "work_date";
      default:
        return "work_date";
    }
  };

  const { data: fetchedData, isLoading } = useSearchMaintenanceEvents(
    {
      shopId: selectedShop?.id || "",
      limit: pageSize,
      skip: pageIndex * pageSize,
      q: searchPhrase,
      ...(mapSortField()
        ? {
            sortField: mapSortField(),
            sortDirection: !sorting[0]
              ? "desc"
              : sorting[0].desc
              ? "desc"
              : "asc",
          }
        : null),
    },
    {
      enabled: selectedShop != null,
      keepPreviousData: true,
    },
  );

  const totalItems = useMemo(() => fetchedData?.total, [fetchedData?.total]);

  const paginationOptions = {
    pagination: { pageIndex, pageSize },
    setPagination: setPagination,
    totalItems: totalItems || 0,
  };

  return {
    searchPhrase,
    setSearchPhrase,
    paginationOptions,
    sortingOptions,
    fetchedData,
    isLoading,
  };
}
