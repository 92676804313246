/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BenefitDefinitionSummaryRead } from "./BenefitDefinitionSummaryRead";
import {
  BenefitDefinitionSummaryReadFromJSON,
  BenefitDefinitionSummaryReadFromJSONTyped,
  BenefitDefinitionSummaryReadToJSON,
} from "./BenefitDefinitionSummaryRead";
import type { BikeBenefitOrderState } from "./BikeBenefitOrderState";
import {
  BikeBenefitOrderStateFromJSON,
  BikeBenefitOrderStateFromJSONTyped,
  BikeBenefitOrderStateToJSON,
} from "./BikeBenefitOrderState";
import type { BikeSummaryRead } from "./BikeSummaryRead";
import {
  BikeSummaryReadFromJSON,
  BikeSummaryReadFromJSONTyped,
  BikeSummaryReadToJSON,
} from "./BikeSummaryRead";
import type { UserSummaryRead } from "./UserSummaryRead";
import {
  UserSummaryReadFromJSON,
  UserSummaryReadFromJSONTyped,
  UserSummaryReadToJSON,
} from "./UserSummaryRead";

/**
 *
 * @export
 * @interface BikeBenefitOrderSummaryRead
 */
export interface BikeBenefitOrderSummaryRead {
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderSummaryRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderSummaryRead
   */
  vapausCode: string;
  /**
   *
   * @type {UserSummaryRead}
   * @memberof BikeBenefitOrderSummaryRead
   */
  user?: UserSummaryRead;
  /**
   *
   * @type {BenefitDefinitionSummaryRead}
   * @memberof BikeBenefitOrderSummaryRead
   */
  benefitDefinition: BenefitDefinitionSummaryRead;
  /**
   *
   * @type {BikeSummaryRead}
   * @memberof BikeBenefitOrderSummaryRead
   */
  bike: BikeSummaryRead;
  /**
   *
   * @type {BikeBenefitOrderState}
   * @memberof BikeBenefitOrderSummaryRead
   */
  state: BikeBenefitOrderState;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderSummaryRead
   */
  totalPackagePrice: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderSummaryRead
   */
  totalPackagePriceWithoutMaintenance: number;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitOrderSummaryRead
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the BikeBenefitOrderSummaryRead interface.
 */
export function instanceOfBikeBenefitOrderSummaryRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "vapausCode" in value;
  isInstance = isInstance && "benefitDefinition" in value;
  isInstance = isInstance && "bike" in value;
  isInstance = isInstance && "state" in value;
  isInstance = isInstance && "totalPackagePrice" in value;
  isInstance = isInstance && "totalPackagePriceWithoutMaintenance" in value;

  return isInstance;
}

export function BikeBenefitOrderSummaryReadFromJSON(
  json: any,
): BikeBenefitOrderSummaryRead {
  return BikeBenefitOrderSummaryReadFromJSONTyped(json, false);
}

export function BikeBenefitOrderSummaryReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitOrderSummaryRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    vapausCode: json["vapaus_code"],
    user: !exists(json, "user")
      ? undefined
      : UserSummaryReadFromJSON(json["user"]),
    benefitDefinition: BenefitDefinitionSummaryReadFromJSON(
      json["benefit_definition"],
    ),
    bike: BikeSummaryReadFromJSON(json["bike"]),
    state: BikeBenefitOrderStateFromJSON(json["state"]),
    totalPackagePrice: json["total_package_price"],
    totalPackagePriceWithoutMaintenance:
      json["total_package_price_without_maintenance"],
    updatedAt: !exists(json, "updated_at")
      ? undefined
      : new Date(json["updated_at"]),
  };
}

export function BikeBenefitOrderSummaryReadToJSON(
  value?: BikeBenefitOrderSummaryRead | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    vapaus_code: value.vapausCode,
    user: UserSummaryReadToJSON(value.user),
    benefit_definition: BenefitDefinitionSummaryReadToJSON(
      value.benefitDefinition,
    ),
    bike: BikeSummaryReadToJSON(value.bike),
    state: BikeBenefitOrderStateToJSON(value.state),
    total_package_price: value.totalPackagePrice,
    total_package_price_without_maintenance:
      value.totalPackagePriceWithoutMaintenance,
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
  };
}
