import { useTranslation } from "react-i18next";

import { PopupWrapper } from "@vapaus/common-v2";
import { DropdownMenu, LoginIcon, StorefrontIcon } from "@vapaus/ui-v2";

import { useShopContext } from "../../../contexts/ShopContext";

export type AccountSwitcherMenuProps = {
  accountSwitcherMenuTrigger: HTMLElement | null;
  closeAccountSwitcherMenu: () => void;
};

export function AccountSwitcherMenu({
  accountSwitcherMenuTrigger,
  closeAccountSwitcherMenu,
}: AccountSwitcherMenuProps) {
  const { t } = useTranslation();
  const { selectedShop, managedShops, changeSelectedShop } = useShopContext();
  const menuProps = {
    header: {
      icon: StorefrontIcon,
      title: t("shopV2:navigation:accountSwitcher:menu:title"),
      description: selectedShop?.name ?? "",
    },
    items: managedShops.map((shop) => ({ label: shop.name, value: shop.id })),
    onItemClick: (value: string) => {
      closeAccountSwitcherMenu();
      changeSelectedShop(value);
    },
    selectable: {
      selected: selectedShop?.id ?? "",
      onHoverAdornment: LoginIcon,
    },
  };

  return (
    <PopupWrapper
      triggerElement={accountSwitcherMenuTrigger}
      onOutsideClick={closeAccountSwitcherMenu}
    >
      <DropdownMenu {...menuProps} />
    </PopupWrapper>
  );
}
