import { useEffect, useRef, useState } from "react";

import { get } from "lodash-es";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  BenefitDefinitionReadWithOrganisationSummary,
  UserSummaryRead,
} from "@vapaus/api-codegen";
import {
  Flex,
  PaletteVariants,
  RowItem,
  Typography,
  useBreakpoints,
} from "@vapaus/ui-v2";

import { useCurrencyFormat } from "../../../../../../../../packages/i18n/src/hooks";
import { useCalculationBar } from "./useCalculationBar";

type CalculationBarProps = {
  user: UserSummaryRead;
  orderTotal: number | null;
  hasFixedTaxableValue: boolean;
  benefitDefinition: BenefitDefinitionReadWithOrganisationSummary;
};

export function CalculationBar({
  user,
  benefitDefinition,
  hasFixedTaxableValue,
  orderTotal,
}: CalculationBarProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(benefitDefinition.currency);
  const { isMobile } = useBreakpoints();
  const {
    barThemeAndMessage: { bgColor, messageBgColor, barMessage },
    leasingPeriodText,
    monthlyPaymentText,
    downPaymentText,
    packageMaximumText,
  } = useCalculationBar(benefitDefinition, hasFixedTaxableValue);
  const [height, setHeight] = useState(0);
  const rootDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHeight(rootDivRef.current?.clientHeight ?? 0);
  }, [barMessage]);

  return (
    <>
      <StyledRoot
        ref={rootDivRef}
        bgColor={bgColor}
        direction="column"
        gap="none"
      >
        <StyledCalculationRoot padding="sm" fullWidth>
          <StyledContent align="center" fullWidth>
            {!isMobile && (
              <StyledBorderedRightFlex
                direction="column"
                align="flex-end"
                gap="xxs"
              >
                <Typography variant="h5" noWrap>
                  {user.fullName}
                </Typography>
                <Typography variant="bodySm" noWrap>
                  {user.email}
                </Typography>
              </StyledBorderedRightFlex>
            )}
            <Flex justify="space-between" fullWidth>
              <StyledRowsWrapper direction="column" gap="none">
                <RowItem
                  label={t("shopV2:orderForm.calculationBar.leasingPeriod")}
                  value={leasingPeriodText}
                  typoVariant="bodyXs"
                  justify="flex-start"
                  gap="xxs"
                />
                <RowItem
                  label={t("shopV2:orderForm.calculationBar.monthlyPayment")}
                  value={monthlyPaymentText}
                  typoVariant="bodyXs"
                  justify="flex-start"
                  gap="xxs"
                />
                <RowItem
                  label={t("shopV2:orderForm.calculationBar.downPayment")}
                  value={downPaymentText}
                  typoVariant="bodyXs"
                  justify="flex-start"
                  gap="xxs"
                />
              </StyledRowsWrapper>
              <Flex direction="column" align="flex-end" gap="xxs">
                <Typography variant="h5">
                  {formatCurrency(orderTotal)}
                </Typography>
                <Typography variant="bodySm" align="right">
                  {packageMaximumText}
                </Typography>
              </Flex>
            </Flex>
          </StyledContent>
        </StyledCalculationRoot>
        {barMessage && (
          <StyledBackgroundFlex padding="sm" bgColor={messageBgColor}>
            <Typography
              weight="bold"
              colorOn={messageBgColor}
              align="center"
              fullWidth
            >
              {barMessage}
            </Typography>
          </StyledBackgroundFlex>
        )}
      </StyledRoot>
      {/* 
      This div is used to reserve space in the form layout for the CalculationBar component. 
      Since StyledRoot is fixed and does not occupy space in the normal document flow, 
      this div ensures that the space equivalent to StyledRoot's height is accounted for 
      in the layout to prevent overlapping of content.
    */}
      <div style={{ height }} />
    </>
  );
}

const StyledBackgroundFlex = styled(Flex)<{
  bgColor: PaletteVariants;
}>`
  background: ${({ theme, bgColor }) => get(theme.palette, bgColor)};
`;

const StyledRoot = styled(StyledBackgroundFlex)`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
`;

const StyledCalculationRoot = styled(Flex)`
  border-bottom: ${({ theme }) => theme.border.secondary.main};
`;

const StyledContent = styled(Flex)`
  max-width: 800px;
  margin: auto;
`;

const StyledBorderedRightFlex = styled(Flex)`
  padding-right: ${({ theme }) => theme.spacing.sm};
  border-right: ${({ theme }) => theme.border.primary.light};
`;

const StyledRowsWrapper = styled(Flex)`
  * > p {
    text-align: left;
  }
`;
