import { DateTime } from "luxon";

import { BikeBenefitOrderRead, Currency } from "@vapaus/api-codegen";

import { DeliveryMethod } from "../deliveryMethod";
import { BenefitOrderFormType } from "../orderFormSchema/orderFormSchema";

export function getOrderFormDefaultValues(
  currency: Currency,
  userId: string,
  shopId: string,
  benefitDefinitionId: string,
  initialOrder?: BikeBenefitOrderRead,
): BenefitOrderFormType {
  const formDefaultValues = (initialOrder || {
    userId,
    shopId,
    benefitDefinitionId,
    monthlyMaintenanceBudget: 0,
    fixedMonthlyTaxableValue: null,
    fixedDownPaymentAmount: null,
    leasingPeriodMonths: null,
    notes: "",
    orderNumber: "",
    isDownPaymentInsured: false,
    accessories: [],
    additionalServices: [],
    bike: {
      purchaseCurrency: currency,
      manufacturer: "",
      model: "",
      serialNumber: "",
      driveType: null,
      frameType: null,
      size: "",
      colour: "",
      purchasePrice: null,
    },
    deliveryTransport: undefined,
    discountBike: 0,
    deliveryMethod: DeliveryMethod.pickUp,
    addOrderNote: false,
  }) as unknown as BenefitOrderFormType;

  if (
    formDefaultValues.deliveryTransport &&
    Object.values(formDefaultValues.deliveryTransport).some(
      (value) => !!String(value),
    )
  ) {
    formDefaultValues.deliveryMethod = DeliveryMethod.delivery;
    if (formDefaultValues.deliveryTransport.expectedDeliveryDate) {
      formDefaultValues.deliveryTransport.expectedDeliveryDate =
        DateTime.fromJSDate(
          new Date(formDefaultValues.deliveryTransport.expectedDeliveryDate),
        ).toFormat("yyyy-LL-dd");
    }
  } else {
    formDefaultValues.deliveryMethod = DeliveryMethod.pickUp;
  }

  if (formDefaultValues.notes || formDefaultValues.orderNumber) {
    formDefaultValues.addOrderNote = true;
  }
  return formDefaultValues;
}
