import { useTranslation } from "react-i18next";

import { Currency } from "@vapaus/api-codegen";
import { useEnumEntries } from "@vapaus/shared-api";
import { Flex, Modal, RowItem, Separator, Typography } from "@vapaus/ui-v2";

import {
  useCurrencyFormat,
  useDateFormat,
  usePercentFormat,
} from "../../../../../../../packages/i18n/src/hooks";
import { MaintenanceTableDataType } from "../MaintenanceEventsTable/MaintenanceEventsTable";
import { ButtonsSection } from "./components/ButtonsSection";
import { EditMaintenanceEventModal } from "./components/EditMaintenanceEventModal";
import { useMaintenanceEventModal } from "./useMaintenanceEventModal";

type MaintenanceEventModalProps = {
  isOpen: boolean;
  onClose: () => void;
  maintenanceEvent?: MaintenanceTableDataType;
};

export function MaintenanceEventModal({
  isOpen,
  onClose,
  maintenanceEvent,
}: MaintenanceEventModalProps) {
  const { t } = useTranslation();

  const {
    isDeleteConfirmationModalOpen,
    openDeletionConfirmationModal,
    closeDeletionConfirmationModal,
    isEditEventModalOpen,
    openEditEventModal,
    closeEditEventModal,
    vatRate,
    deleteMaintenanceEvent,
  } = useMaintenanceEventModal(onClose, maintenanceEvent);

  const { getEnumLabel } = useEnumEntries("WorkTypes");

  const formatDate = useDateFormat();
  const formatCurrency = useCurrencyFormat(
    (maintenanceEvent?.price.currency as Currency) || Currency.Eur,
  );
  const formatPercent = usePercentFormat();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Flex direction="column" gap="md">
        <Flex direction="column" align="center" gap="xxs">
          <Typography variant="h6">
            {maintenanceEvent?.bike.bikeBrand.name}{" "}
            {maintenanceEvent?.bike.model}
          </Typography>
          <Typography variant="h2">
            {getEnumLabel(maintenanceEvent?.workType)}
          </Typography>
          <Typography>
            {maintenanceEvent?.bike.frameNumber}
            {maintenanceEvent?.customer ? " · " : null}
            {maintenanceEvent?.customer?.vapausCode}
          </Typography>
        </Flex>
        <Flex justify="center">
          <ButtonsSection
            maintenanceEventDate={maintenanceEvent?.date}
            openEditEventModal={openEditEventModal}
            openDeletionConfirmationModal={openDeletionConfirmationModal}
          />
        </Flex>
        <Flex direction="column" align="center">
          <Flex direction="column" align="center" gap="xxs">
            <Typography weight="bold">
              {t("shopV2:maintenance.eventModal.serviceDescription")}
            </Typography>
            <Typography align="center">
              {maintenanceEvent?.description}
            </Typography>
          </Flex>
        </Flex>
        <Separator />
        <Flex direction="column">
          <RowItem
            label={t("shopV2:maintenance.eventModal.date")}
            value={formatDate(maintenanceEvent?.date)}
          />
          <RowItem
            label={t("shopV2:maintenance.eventModal.parts", {
              value: formatPercent(vatRate),
            })}
            value={formatCurrency(maintenanceEvent?.price.parts)}
          />
          <RowItem
            label={t("shopV2:maintenance.eventModal.labour", {
              value: formatPercent(vatRate),
            })}
            value={formatCurrency(maintenanceEvent?.price.labour)}
          />
        </Flex>
        <Separator />
        <RowItem
          label={t("shopV2:maintenance.eventModal.total")}
          value={formatCurrency(maintenanceEvent?.price.total)}
          valueIsBold
        />
      </Flex>
      <EditMaintenanceEventModal
        maintenanceEvent={maintenanceEvent}
        vatRate={vatRate}
        isOpen={isEditEventModalOpen}
        onClose={closeEditEventModal}
        onParentClose={onClose}
      />
      <Modal
        isOpen={isDeleteConfirmationModalOpen}
        onClose={closeDeletionConfirmationModal}
        title={t("shopV2:maintenance.eventModal.deleteModal.title")}
        description={t("shopV2:maintenance.eventModal.deleteModal.description")}
        onConfirm={() => deleteMaintenanceEvent()}
        confirmText={t(
          "shopV2:maintenance.eventModal.deleteModal.confirmButton",
        )}
        confirmButtonVariant="primaryDanger"
        onCancel={closeDeletionConfirmationModal}
        cancelText={t("commonV2:cancel")}
      />
    </Modal>
  );
}
