import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { MaintenanceEventRead, WorkTypes } from "@vapaus/api-codegen";
import { useFormMutation } from "@vapaus/form";
import { useToaster } from "@vapaus/ui-v2";

import { maintenanceEventsApi } from "../../../../api/maintenanceEvents";
import { useShopContext } from "../../../../contexts/ShopContext";
import {
  MaintenanceEventFormSchema,
  useMaintenanceEventFormSchema,
} from "../MaintenanceEventForm/useMaintenanceEventFormSchema";

export function useAddMaintenanceEventForm(
  contractId: string,
  onClose: () => void,
  handleBudgetChange: (budgetDifference: number) => void,
) {
  const { t } = useTranslation();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const { selectedShop } = useShopContext();

  const schema = useMaintenanceEventFormSchema();

  const { formMethods, mutation } = useFormMutation<
    MaintenanceEventFormSchema,
    MaintenanceEventRead
  >(
    {
      mutationFn: (data) =>
        maintenanceEventsApi.maintenanceEventsCreateMaintenanceEvent({
          shopMaintenanceEventCreate: {
            bikeBenefitContractId: contractId,
            partsPrice: data.partsPrice,
            shopId: selectedShop?.id ?? "",
            workDate: data.date,
            workDescription: data.serviceDescription,
            workPrice: data.labourPrice,
            workType: data.type as WorkTypes,
          },
        }),
      onSuccess: (data) => {
        queryClient.invalidateQueries(["maintenanceEvents"]);
        toaster.add(
          "success",
          t("shopV2:maintenance.wizard.bikeView.newEventModal.successToast"),
        );
        handleBudgetChange(data.totalPrice);
        onClose();
        formMethods.reset();
      },
    },
    {
      resolver: zodResolver(schema),
    },
  );

  return { formMethods, mutation };
}
