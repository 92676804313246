import { useQuery } from "@tanstack/react-query";

import {
  BikeBenefitContractForMaintenanceRead,
  ResponseError,
  ShopBenefitApi,
  ShopBenefitSearchContractForMaintenanceRequest,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const bikeContractsApi = new ShopBenefitApi(apiConfiguration);

export const useSearchBikeContractsForMaintenance = (
  params: ShopBenefitSearchContractForMaintenanceRequest,
  options?: UseQueryOptions<Array<BikeBenefitContractForMaintenanceRead>>,
) => {
  return useQuery<Array<BikeBenefitContractForMaintenanceRead>, ResponseError>(
    ["contractsForMaintenance", params],
    () => bikeContractsApi.shopBenefitSearchContractForMaintenance(params),
    options,
  );
};
