import { ReactNode } from "react";

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { Flex, NavigationButton } from "@vapaus/ui-v2";

import { Path } from "../../../constants/paths";

export function NavigationButtons(): ReactNode {
  const { t } = useTranslation();

  return (
    <Flex gap="xs">
      <NavLink to={Path.orders}>
        {({ isActive }) => (
          <NavigationButton isActivated={isActive}>
            {t("shopV2:navigation.buttons.orders")}
          </NavigationButton>
        )}
      </NavLink>
      <NavLink to={Path.maintenance}>
        {({ isActive }) => (
          <NavigationButton isActivated={isActive}>
            {t("shopV2:navigation.buttons.maintenance")}
          </NavigationButton>
        )}
      </NavLink>
      <NavLink to={Path.support}>
        {({ isActive }) => (
          <NavigationButton isActivated={isActive}>
            {t("shopV2:navigation.buttons.support")}
          </NavigationButton>
        )}
      </NavLink>
    </Flex>
  );
}
