/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BikeSummaryRead } from "./BikeSummaryRead";
import {
  BikeSummaryReadFromJSON,
  BikeSummaryReadFromJSONTyped,
  BikeSummaryReadToJSON,
} from "./BikeSummaryRead";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
} from "./Currency";
import type { UserSummaryRead } from "./UserSummaryRead";
import {
  UserSummaryReadFromJSON,
  UserSummaryReadFromJSONTyped,
  UserSummaryReadToJSON,
} from "./UserSummaryRead";

/**
 *
 * @export
 * @interface BikeBenefitContractForMaintenanceRead
 */
export interface BikeBenefitContractForMaintenanceRead {
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  totalMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  spentMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  remainingMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  monthlyMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  leasingPeriodMonths: number;
  /**
   *
   * @type {BikeSummaryRead}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  bike: BikeSummaryRead;
  /**
   *
   * @type {UserSummaryRead}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  user: UserSummaryRead;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  vapausCode: string;
  /**
   *
   * @type {Currency}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  currency: Currency;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  flatVatRate: number;
}

/**
 * Check if a given object implements the BikeBenefitContractForMaintenanceRead interface.
 */
export function instanceOfBikeBenefitContractForMaintenanceRead(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "totalMaintenanceBudget" in value;
  isInstance = isInstance && "spentMaintenanceBudget" in value;
  isInstance = isInstance && "remainingMaintenanceBudget" in value;
  isInstance = isInstance && "monthlyMaintenanceBudget" in value;
  isInstance = isInstance && "leasingPeriodMonths" in value;
  isInstance = isInstance && "bike" in value;
  isInstance = isInstance && "user" in value;
  isInstance = isInstance && "vapausCode" in value;
  isInstance = isInstance && "currency" in value;
  isInstance = isInstance && "flatVatRate" in value;

  return isInstance;
}

export function BikeBenefitContractForMaintenanceReadFromJSON(
  json: any,
): BikeBenefitContractForMaintenanceRead {
  return BikeBenefitContractForMaintenanceReadFromJSONTyped(json, false);
}

export function BikeBenefitContractForMaintenanceReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitContractForMaintenanceRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    totalMaintenanceBudget: json["total_maintenance_budget"],
    spentMaintenanceBudget: json["spent_maintenance_budget"],
    remainingMaintenanceBudget: json["remaining_maintenance_budget"],
    monthlyMaintenanceBudget: json["monthly_maintenance_budget"],
    leasingPeriodMonths: json["leasing_period_months"],
    bike: BikeSummaryReadFromJSON(json["bike"]),
    user: UserSummaryReadFromJSON(json["user"]),
    vapausCode: json["vapaus_code"],
    currency: CurrencyFromJSON(json["currency"]),
    flatVatRate: json["flat_vat_rate"],
  };
}

export function BikeBenefitContractForMaintenanceReadToJSON(
  value?: BikeBenefitContractForMaintenanceRead | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    total_maintenance_budget: value.totalMaintenanceBudget,
    spent_maintenance_budget: value.spentMaintenanceBudget,
    remaining_maintenance_budget: value.remainingMaintenanceBudget,
    monthly_maintenance_budget: value.monthlyMaintenanceBudget,
    leasing_period_months: value.leasingPeriodMonths,
    bike: BikeSummaryReadToJSON(value.bike),
    user: UserSummaryReadToJSON(value.user),
    vapaus_code: value.vapausCode,
    currency: CurrencyToJSON(value.currency),
    flat_vat_rate: value.flatVatRate,
  };
}
