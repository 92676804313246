/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  ProductTypes,
  ProductVatRateBase,
  SupportedOrganisationCountries,
} from "../models/index";
import {
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  ProductTypesFromJSON,
  ProductTypesToJSON,
  ProductVatRateBaseFromJSON,
  ProductVatRateBaseToJSON,
  SupportedOrganisationCountriesFromJSON,
  SupportedOrganisationCountriesToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface ProductVatRatesGetProductVatRateRequest {
  product: ProductTypes;
  country: SupportedOrganisationCountries;
  atTime?: Date;
}

export interface ProductVatRatesGetProductsVatRatesRequest {
  country: SupportedOrganisationCountries;
}

/**
 *
 */
export class ProductVatRatesApi extends runtime.BaseAPI {
  /**
   * Get Product Vat Rate
   */
  async productVatRatesGetProductVatRateRaw(
    requestParameters: ProductVatRatesGetProductVatRateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProductVatRateBase>> {
    if (
      requestParameters.product === null ||
      requestParameters.product === undefined
    ) {
      throw new runtime.RequiredError(
        "product",
        "Required parameter requestParameters.product was null or undefined when calling productVatRatesGetProductVatRate.",
      );
    }

    if (
      requestParameters.country === null ||
      requestParameters.country === undefined
    ) {
      throw new runtime.RequiredError(
        "country",
        "Required parameter requestParameters.country was null or undefined when calling productVatRatesGetProductVatRate.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.country !== undefined) {
      queryParameters["country"] = requestParameters.country;
    }

    if (requestParameters.atTime !== undefined) {
      queryParameters["at_time"] = (requestParameters.atTime as any)
        .toISOString()
        .substring(0, 10);
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/product-vat-rates/{product}`.replace(
          `{${"product"}}`,
          encodeURIComponent(String(requestParameters.product)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductVatRateBaseFromJSON(jsonValue),
    );
  }

  /**
   * Get Product Vat Rate
   */
  async productVatRatesGetProductVatRate(
    requestParameters: ProductVatRatesGetProductVatRateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ProductVatRateBase> {
    const response = await this.productVatRatesGetProductVatRateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Products Vat Rates
   */
  async productVatRatesGetProductsVatRatesRaw(
    requestParameters: ProductVatRatesGetProductsVatRatesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<{ [key: string]: number }>> {
    if (
      requestParameters.country === null ||
      requestParameters.country === undefined
    ) {
      throw new runtime.RequiredError(
        "country",
        "Required parameter requestParameters.country was null or undefined when calling productVatRatesGetProductsVatRates.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.country !== undefined) {
      queryParameters["country"] = requestParameters.country;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/product-vat-rates`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Get Products Vat Rates
   */
  async productVatRatesGetProductsVatRates(
    requestParameters: ProductVatRatesGetProductsVatRatesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: number }> {
    const response = await this.productVatRatesGetProductsVatRatesRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
