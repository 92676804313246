import { QueryClient } from "@tanstack/react-query";
import { Navigate, Outlet } from "react-router-dom";

import { createAuthRoutes } from "@vapaus/auth-v2";
import { AccountSettingsCard, UserProfileCard } from "@vapaus/settings";

import { Layout } from "./components/Layout";
import { Path } from "./constants/paths";
import { MaintenancePage } from "./pages/MaintenancePage";
import { createOrderViewRoute } from "./pages/OrderViewPage";
import { OrdersPage } from "./pages/OrdersPage";
import { SettingsPage } from "./pages/SettingsPage";
import { ShopAdminSection } from "./pages/SettingsPage/ShopAdminSection";
import { SupportPage } from "./pages/SupportPage";

export const createRoutes = (queryClient: QueryClient) =>
  createAuthRoutes(
    {
      allowSignUp: false,
      scope: "shop",
      app: "shop",
    },
    [
      {
        path: ":shopId?",
        element: <Layout />,
        children: [
          {
            index: true,
            element: <Navigate to={Path.orders} replace />,
          },
          {
            path: Path.orders,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <OrdersPage />,
              },
              createOrderViewRoute(queryClient),
            ],
          },
          {
            path: Path.maintenance,
            element: <MaintenancePage />,
          },
          {
            path: Path.support,
            element: <SupportPage />,
          },
          {
            path: Path.settings,
            element: <SettingsPage />,
            children: [
              {
                index: true,
                element: <Navigate to={Path.userProfile} />,
              },
              {
                path: Path.userProfile,
                element: <UserProfileCard />,
              },
              {
                path: Path.account,
                element: <AccountSettingsCard />,
              },
              {
                path: Path.shopAdmin,
                element: <ShopAdminSection />,
              },
            ],
          },
        ],
      },
    ],
  );
