import { now } from "lodash-es";
import { useTranslation } from "react-i18next";

import { getDateDifferenceInDays } from "@vapaus/common-v2";
import { Button, Tooltip } from "@vapaus/ui-v2";

const MAINTENANCE_EVENT_MODIFICATION_LIMIT_IN_DAYS = 30;

type ButtonsSectionProps = {
  maintenanceEventDate?: Date;
  openEditEventModal: () => void;
  openDeletionConfirmationModal: () => void;
};

export function ButtonsSection({
  maintenanceEventDate,
  openEditEventModal,
  openDeletionConfirmationModal,
}: ButtonsSectionProps) {
  const { t } = useTranslation();

  const isModificationEnabled =
    maintenanceEventDate &&
    getDateDifferenceInDays(new Date(maintenanceEventDate), new Date(now())) <=
      MAINTENANCE_EVENT_MODIFICATION_LIMIT_IN_DAYS;

  return (
    <>
      <Tooltip
        trigger={
          <Button
            size="small"
            onClick={openEditEventModal}
            disabled={!isModificationEnabled}
          >
            {t("commonV2:edit")}
          </Button>
        }
        disabled={isModificationEnabled}
      >
        {t("shopV2:maintenance.eventModal.disabledButtonTooltip", {
          value: MAINTENANCE_EVENT_MODIFICATION_LIMIT_IN_DAYS,
        })}
      </Tooltip>
      <Tooltip
        trigger={
          <Button
            size="small"
            variant="outlined"
            onClick={openDeletionConfirmationModal}
            disabled={!isModificationEnabled}
          >
            {t("commonV2:delete")}
          </Button>
        }
        disabled={isModificationEnabled}
      >
        {t("shopV2:maintenance.eventModal.disabledButtonTooltip", {
          value: MAINTENANCE_EVENT_MODIFICATION_LIMIT_IN_DAYS,
        })}
      </Tooltip>
    </>
  );
}
