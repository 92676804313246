import { useMutation, useQuery } from "@tanstack/react-query";

import {
  MaintenanceEventsApi,
  MaintenanceEventsDeleteMaintenanceEventRequest,
  OrderDirection,
  ResponseError,
  ShopPaginatedMaintenanceEventOut,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const maintenanceEventsApi = new MaintenanceEventsApi(apiConfiguration);

export const useSearchMaintenanceEvents = (
  params: {
    shopId?: string;
    skip: number;
    limit: number;
    q?: string;
    vapausCode?: string;
    sortField?: string;
    sortDirection?: OrderDirection;
  },
  options?: UseQueryOptions<ShopPaginatedMaintenanceEventOut>,
) => {
  return useQuery<ShopPaginatedMaintenanceEventOut, ResponseError>(
    ["maintenanceEvents", params],
    () =>
      maintenanceEventsApi.maintenanceEventsSearchMaintenanceEvents({
        shopId: params.shopId,
        vapausCode: params.vapausCode,
        q: params.q,
        skip: params.skip,
        limit: params.limit,
        sortField: params.sortField,
        sortDirection: params.sortDirection,
      }),
    options,
  );
};

export const useDeleteMaintenanceEvent = (
  params: MaintenanceEventsDeleteMaintenanceEventRequest,
) => {
  return useMutation<void, ResponseError>(["maintenanceEvents", params], () =>
    maintenanceEventsApi.maintenanceEventsDeleteMaintenanceEvent(params),
  );
};
