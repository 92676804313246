import { useTranslation } from "react-i18next";

import { Currency, PaginatedBikeBrandOut } from "@vapaus/api-codegen";
import {
  AutocompleteSelectAsync,
  Input,
  NumberInput,
  Select,
} from "@vapaus/form";
import { useEnumEntries } from "@vapaus/shared-api";
import { Card, Flex, Notification } from "@vapaus/ui-v2";

import {
  useCurrencyFormat,
  usePercentFormat,
} from "../../../../../../../../packages/i18n/src/hooks";
import { shopBikeBrandsApi } from "../../../../../api/bikeBrands";
import { BaseOrderFormSectionProps } from "../types";

export function BikeInformationForm({
  currency = Currency.Eur,
  vatRate,
  bikeDataCheckResult,
}: BaseOrderFormSectionProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(currency);
  const formatPercent = usePercentFormat();
  const { entries: driveTypes } = useEnumEntries("BikeDriveType");
  const { entries: frameTypes } = useEnumEntries("BikeFrameType");

  const brandsQueryFunction = (params: { q: string }) =>
    shopBikeBrandsApi.shopBikeBrandsSearchBikeBrands({
      active: true,
      q: params?.q,
    });

  const selectBrandSuggestions = (data: PaginatedBikeBrandOut) =>
    (data?.items || []).map((brand) => ({
      value: brand.id as string,
      label: brand.name,
    }));

  return (
    <Card
      title={t("shopV2:orderForm.bikeInformation.title")}
      subtitle={t("shopV2:orderForm.bikeInformation.subtitle")}
    >
      <Flex direction="column" gap="md">
        <Flex xsDirection="column" gap="md" align="flex-start">
          <AutocompleteSelectAsync
            name="bike.bikeBrandId"
            queryFn={brandsQueryFunction}
            querySelect={selectBrandSuggestions}
            queryKey="brands"
            noResultMessage={t("commonV2:autocomplete.noResultMessage")}
            inputPlaceholder={t("commonV2:search")}
            placeholder={t(
              "shopV2:orderForm.bikeInformation.enterManufacturer",
            )}
            label={t("shopV2:orderForm.bikeInformation.manufacturer")}
            fullWidth
          />
          <Input
            name="bike.model"
            placeholder={t("shopV2:orderForm.bikeInformation.enterModel")}
            label={t("shopV2:orderForm.bikeInformation.model")}
            fullWidth
          />
        </Flex>
        <Flex xsDirection="column" gap="md" align="flex-start">
          <Select
            name="bike.driveType"
            options={driveTypes}
            placeholder={t("shopV2:orderForm.bikeInformation.chooseDriveType")}
            label={t("shopV2:orderForm.bikeInformation.driveType")}
            fullWidth
          />
          <Select
            name="bike.frameType"
            options={frameTypes}
            placeholder={t("shopV2:orderForm.bikeInformation.chooseFrameType")}
            label={t("shopV2:orderForm.bikeInformation.frameType")}
            fullWidth
          />
        </Flex>
        <Flex xsDirection="column" gap="md" align="flex-start">
          <Input
            name="bike.size"
            placeholder={t("shopV2:orderForm.bikeInformation.enterSize")}
            label={t("shopV2:orderForm.bikeInformation.size")}
            fullWidth
          />
          <Input
            name="bike.colour"
            placeholder={t("shopV2:orderForm.bikeInformation.enterColor")}
            label={t("shopV2:orderForm.bikeInformation.color")}
            fullWidth
          />
        </Flex>
        <Input
          name="bike.serialNumber"
          placeholder={t("shopV2:orderForm.bikeInformation.enterSerialNumber")}
          label={t("shopV2:orderForm.bikeInformation.serialNumber")}
        />
        {bikeDataCheckResult?.serialNumber.warnings.map((warning) => (
          <Notification type="warning" key={warning._short}>
            {warning._short}
          </Notification>
        ))}
        <NumberInput
          name="bike.purchasePrice"
          label={t("shopV2:orderForm.priceIncludeVAT", {
            vat: formatPercent(vatRate),
          })}
          placeholder={formatCurrency(0)}
        />
      </Flex>
    </Card>
  );
}
