import { sum, sumBy } from "lodash-es";

interface Order {
  bikePrice: number;
  accessories: Array<Accessory>;
  additionalServices: Array<AdditionalService>;
  deliveryTransport?: DeliveryTransport;
}

interface Accessory {
  purchasePrice: number;
}

interface AdditionalService {
  priceGross: number;
}

interface DeliveryTransport {
  priceGross: number;
}

export const getOrderTotalPrice = (
  order: Order,
  bikeDiscount = 0,
  accessoryDiscount = 0,
): number => {
  const bikePrice = order.bikePrice;

  const bikeTotal = bikePrice - bikePrice * bikeDiscount;

  const accessoriesTotal = sumBy(order.accessories, (accessory: Accessory) => {
    const purchasePrice = accessory.purchasePrice || 0;
    return purchasePrice - purchasePrice * accessoryDiscount;
  });

  const additionalServiceTotal = sumBy(
    order.additionalServices?.map((service) => service.priceGross || 0),
  );
  const transportTotal = order.deliveryTransport?.priceGross || 0;

  const total = sum([
    bikeTotal,
    accessoriesTotal,
    additionalServiceTotal,
    transportTotal,
  ]);
  return parseFloat(total.toFixed(2));
};
