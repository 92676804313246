export enum Path {
  account = "account",
  orders = "orders",
  maintenance = "maintenance",
  support = "support",
  settings = "settings",
  userProfile = "user-profile",
  shopAdmin = "shop-admin",
  settingsUserProfile = `${settings}/${userProfile}`,
  settingsShopAdmin = `${settings}/${shopAdmin}`,
  pageNotFound = "/page-not-found",
  edit = "/edit",
  logout = "logout",
}
