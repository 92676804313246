import { useQuery } from "@tanstack/react-query";

import {
  BikeBenefitContractRead,
  ResponseError,
  ShopBenefitApi,
  ShopBenefitGetBikeBenefitContractRequest,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const bikeContractsApi = new ShopBenefitApi(apiConfiguration);

export const useSearchBikeContracts = (
  params: ShopBenefitGetBikeBenefitContractRequest,
  options?: UseQueryOptions<BikeBenefitContractRead>,
) => {
  return useQuery<BikeBenefitContractRead, ResponseError>(
    ["contracts", params],
    () => bikeContractsApi.shopBenefitGetBikeBenefitContract(params),
    options,
  );
};
