/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { AccessoryType } from "./AccessoryType";
import {
  AccessoryTypeFromJSON,
  AccessoryTypeFromJSONTyped,
  AccessoryTypeToJSON,
} from "./AccessoryType";
import type { SupportedOrganisationCountries } from "./SupportedOrganisationCountries";
import {
  SupportedOrganisationCountriesFromJSON,
  SupportedOrganisationCountriesFromJSONTyped,
  SupportedOrganisationCountriesToJSON,
} from "./SupportedOrganisationCountries";

/**
 *
 * @export
 * @interface AccessoryTypeRead
 */
export interface AccessoryTypeRead {
  /**
   *
   * @type {AccessoryType}
   * @memberof AccessoryTypeRead
   */
  accessoryType: AccessoryType;
  /**
   *
   * @type {Array<SupportedOrganisationCountries>}
   * @memberof AccessoryTypeRead
   */
  availabilities: Array<SupportedOrganisationCountries>;
}

/**
 * Check if a given object implements the AccessoryTypeRead interface.
 */
export function instanceOfAccessoryTypeRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "accessoryType" in value;
  isInstance = isInstance && "availabilities" in value;

  return isInstance;
}

export function AccessoryTypeReadFromJSON(json: any): AccessoryTypeRead {
  return AccessoryTypeReadFromJSONTyped(json, false);
}

export function AccessoryTypeReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AccessoryTypeRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    accessoryType: AccessoryTypeFromJSON(json["accessory_type"]),
    availabilities: (json["availabilities"] as Array<any>).map(
      SupportedOrganisationCountriesFromJSON,
    ),
  };
}

export function AccessoryTypeReadToJSON(value?: AccessoryTypeRead | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    accessory_type: AccessoryTypeToJSON(value.accessoryType),
    availabilities: (value.availabilities as Array<any>).map(
      SupportedOrganisationCountriesToJSON,
    ),
  };
}
