import { get } from "lodash-es";
import { useTranslation } from "react-i18next";
import { Outlet, useLoaderData, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import { BikeBenefitOrderState } from "@vapaus/api-codegen";
import { PageRoot } from "@vapaus/common-v2";
import { useRefetchAndRevalidateOnFocus } from "@vapaus/shared-api";
import {
  ArrowBackIcon,
  Flex,
  PaletteVariants,
  Tag,
  TextButton,
  Typography,
} from "@vapaus/ui-v2";

import { EditOrderButton } from "../../components/OrderCreation";
import { CancelOrderButton } from "./HeroSection/CancelOrderButton";
import { MarkAsDeliveredButton } from "./HeroSection/MarkAsDeliveredButton";
import { OrderTabs } from "./HeroSection/OrderTabs";
import { useGetOrderHeroData } from "./HeroSection/useGetOrderHeroData";
import { OrderViewRouteLoaderResult } from "./loaders";

export function OrderViewPage() {
  const navigate = useNavigate();
  const { order, benefitDefinition, contract } =
    useLoaderData() as OrderViewRouteLoaderResult;

  useRefetchAndRevalidateOnFocus(["orders", "contracts", "benefitDefinitions"]);

  const { t } = useTranslation();
  const {
    bgColor,
    statusType,
    statusLabel,
    statusDescription,
    canEdit,
    canCancelOrder,
    canMarkAsDelivered,
    disabledTitle,
    isEditDisabled,
    editDisabledMessage,
  } = useGetOrderHeroData(
    order,
    benefitDefinition,
    contract?.state,
    contract?.deliveryDate,
  );
  const heroTitle = `${order.bike.bikeBrand.name} ${order.bike.model}`;
  const heroSubtitle = [
    order.user?.fullName,
    order.vapausCode,
    order.orderNumber,
  ]
    .filter(Boolean)
    .join(" · ");
  const showTabs = (
    [BikeBenefitOrderState.Finalised] as BikeBenefitOrderState[]
  ).includes(order.state);
  const isStateApproved = (
    [
      BikeBenefitOrderState.WaitingForPayment,
      BikeBenefitOrderState.ReadyForDelivery,
    ] as BikeBenefitOrderState[]
  ).includes(order.state);

  return (
    <>
      <StyledRoot
        direction="column"
        align="center"
        justify="center"
        bgColor={bgColor}
        showTabs={showTabs}
      >
        <StyledContainer direction="column" gap="none">
          <TextButton size="md" onClick={() => navigate(-1)}>
            <Flex gap="xs">
              <ArrowBackIcon size="sm" />
              <span>{t("shopV2:orderViewPage.heroSection.backToOrders")}</span>
            </Flex>
          </TextButton>
          <StyledContent direction="column" gap="lg">
            <Flex direction="column">
              <Typography variant="h6" align="center" disabled={disabledTitle}>
                {heroSubtitle}
              </Typography>
              <Flex
                justify="center"
                align="center"
                direction="row"
                smDirection="column"
              >
                <Typography
                  variant="h1"
                  align="center"
                  disabled={disabledTitle}
                >
                  {heroTitle}
                </Typography>
                <Tag variant={statusType} text={statusLabel} size="large" />
              </Flex>
              <StyledStatusDescription variant="bodyLg" align="center">
                {statusDescription}
              </StyledStatusDescription>
            </Flex>
            {(canCancelOrder || canMarkAsDelivered || canEdit) && (
              <Flex direction="column" align="center" gap="lg">
                <Flex
                  justify="center"
                  direction="row"
                  smDirection="column"
                  smAlign="stretch"
                >
                  {canMarkAsDelivered && (
                    <MarkAsDeliveredButton
                      orderId={order.id}
                      bikeId={order.bikeId}
                      bikeSerialNumber={order.bike?.serialNumber}
                    />
                  )}
                  {canEdit && (
                    <EditOrderButton
                      label={t("shopV2:orderViewPage.heroSection.editOrder")}
                      order={order}
                      benefitDefinition={benefitDefinition}
                      variant={isStateApproved ? "outlined" : "primary1"}
                      disabled={isEditDisabled}
                      disabledTooltipMessage={editDisabledMessage}
                    />
                  )}
                  {canCancelOrder && !isStateApproved && (
                    <CancelOrderButton orderId={order.id} />
                  )}
                </Flex>
                {canCancelOrder && isStateApproved && (
                  <CancelOrderButton orderId={order.id} variant="text" />
                )}
              </Flex>
            )}
          </StyledContent>
        </StyledContainer>
        {showTabs && <OrderTabs />}
      </StyledRoot>
      <PageRoot>
        <Outlet />
      </PageRoot>
    </>
  );
}

const StyledRoot = styled(Flex)<{
  bgColor?: PaletteVariants;
}>`
  background-color: ${({ theme, bgColor = "secondary2.lighter" }) =>
    get(theme.palette, bgColor)};
  border-bottom: ${({ theme }) => theme.border.secondary.main};
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing.xl}  ${theme.spacing.xl} 0`};

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      padding: ${theme.spacing.xl} ${theme.spacing.md} 0;
    `)}
`;

const StyledContainer = styled(Flex)`
  width: 100%;
  max-width: 960px;
`;

const StyledContent = styled(Flex)`
  padding: ${({ theme }) => theme.height.navBar} 0;
`;

const StyledStatusDescription = styled(Typography)`
  max-width: 600px;
  width: 100%;
  margin: auto;
`;
