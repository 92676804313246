import { useTranslation } from "react-i18next";

import { AccessoryTypeRead, Currency } from "@vapaus/api-codegen";
import { Input, NumberInput, Select } from "@vapaus/form";
import { useEnumEntries } from "@vapaus/shared-api";
import { Flex, InputListBox } from "@vapaus/ui-v2";

import {
  useCurrencyFormat,
  usePercentFormat,
} from "../../../../../../../../packages/i18n/src/hooks";
import { BaseOrderFormSectionProps } from "../types";

type SingleAccessoryFormProps = {
  availableAccessoryTypes: Array<AccessoryTypeRead>;
  index: number;
  onRemove: (index: number) => void;
  id: string;
  fields: Array<Record<string, unknown>>;
} & BaseOrderFormSectionProps;

export function SingleAccessoryForm({
  index,
  onRemove,
  availableAccessoryTypes,
  currency = Currency.Eur,
  vatRate,
  id,
  fields,
}: SingleAccessoryFormProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(currency);
  const formatPercent = usePercentFormat();
  const { getEnumLabel: getAccessoryTypeEnumLabel } =
    useEnumEntries("AccessoryType");

  return (
    <InputListBox
      title={t("shopV2:orderForm.accessories.itemTitle", { index: index + 1 })}
      onRemove={() => onRemove(index)}
    >
      <Flex direction="column" gap="md">
        <Flex xsDirection="column" gap="md" align="flex-start">
          <Select
            name={`accessories.${index}.accessoryType`}
            options={availableAccessoryTypes.map((accessoryType) => ({
              value: accessoryType.accessoryType,
              label: getAccessoryTypeEnumLabel(accessoryType.accessoryType)!,
            }))}
            placeholder={t("shopV2:orderForm.accessories.chooseDriveType")}
            label={t("shopV2:orderForm.accessories.driveType")}
            fullWidth
          />
          <Input
            name={`accessories.${index}.manufacturer`}
            placeholder={t("shopV2:orderForm.accessories.enterManufacturer")}
            label={t("shopV2:orderForm.accessories.manufacturer")}
            fullWidth
          />
        </Flex>
        <Flex xsDirection="column" gap="md" align="flex-start">
          <Input
            name={`accessories.${index}.model`}
            placeholder={t("shopV2:orderForm.accessories.enterModel")}
            label={t("shopV2:orderForm.accessories.model")}
            fullWidth
          />
          <NumberInput
            name={`accessories.${index}.purchasePrice`}
            label={t("shopV2:orderForm.priceIncludeVAT", {
              vat: formatPercent(vatRate),
            })}
            placeholder={formatCurrency(0)}
            fullWidth
            defaultValue={
              (fields.find((field) => field.id === id)
                ?.purchasePrice as number) || undefined
            }
          />
        </Flex>
      </Flex>
    </InputListBox>
  );
}
