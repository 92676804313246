import { useMemo, useState } from "react";

import { useCheckBikeData } from "apps/shop-v2/src/api/bike";
import { useTranslation } from "react-i18next";

import { parseApiError, useRouterDataRefetch } from "@vapaus/shared-api";
import {
  Button,
  Flex,
  Input,
  Modal,
  Notification,
  useToaster,
} from "@vapaus/ui-v2";
import { useDebounce } from "@vapaus/utils";

import { useFinaliseBikeBenefitOrder } from "../../../api/order";

type MarkAsDeliveredButtonProps = {
  orderId: string;
  bikeId: string;
  bikeSerialNumber?: string;
};

export function MarkAsDeliveredButton({
  orderId,
  bikeId,
  bikeSerialNumber,
}: MarkAsDeliveredButtonProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleCloseModal = () => setIsOpen(false);
  const toaster = useToaster();
  const finalizedOrder = useFinaliseBikeBenefitOrder();
  const handleRefetch = useRouterDataRefetch();
  const [newBikeSerialNumber, setNewBikeSerialNumber] = useState<string>();
  const bikeDataCheckParams = useDebounce(
    useMemo(
      () => ({ id: bikeId, serialNumber: newBikeSerialNumber }),
      [bikeId, newBikeSerialNumber],
    ),
  );
  const bikeDataCheckEnabled =
    !!bikeDataCheckParams.serialNumber &&
    bikeDataCheckParams.serialNumber.length > 0;
  const {
    data: bikeDataCheckResult,
    isSuccess: isBikeDataCheckSuccess,
    isLoading: isBikeDataCheckLoading,
  } = useCheckBikeData(bikeDataCheckParams, {
    enabled: bikeDataCheckEnabled,
  });

  const handleMarkOrderAsDelivered = () => {
    finalizedOrder.mutate(
      {
        bikeBenefitOrderId: orderId,
        bikeSerialNumber: (bikeSerialNumber || newBikeSerialNumber) as string,
      },
      {
        onSuccess: async () => {
          toaster.add(
            "success",
            t(
              "shopV2:orderViewPage.heroSection.markAsDelivered.successMessage",
            ),
          );
          await handleRefetch();
          handleCloseModal();
        },
        onError: async (error) => {
          const message = await parseApiError(error);
          toaster.add("error", message);
        },
      },
    );
  };

  return (
    <>
      <Button size="small" xsFullWidth onClick={() => setIsOpen(true)}>
        {t("shopV2:orderViewPage.heroSection.markAsDelivered.title")}
      </Button>
      {bikeSerialNumber ? (
        <Modal
          isOpen={isOpen}
          title={t("shopV2:orderViewPage.heroSection.markAsDelivered.title")}
          description={t(
            "shopV2:orderViewPage.heroSection.markAsDelivered.description",
          )}
          confirmText={t(
            "shopV2:orderViewPage.heroSection.markAsDelivered.title",
          )}
          cancelText={t("commonV2:cancel")}
          onCancel={handleCloseModal}
          onClose={handleCloseModal}
          onConfirm={handleMarkOrderAsDelivered}
          isSubmitting={finalizedOrder.isLoading}
        />
      ) : (
        <Modal
          isOpen={isOpen}
          title={t("shopV2:orderViewPage.heroSection.enterSerialNumber.title")}
          description={t(
            "shopV2:orderViewPage.heroSection.enterSerialNumber.description",
          )}
          confirmText={t(
            "shopV2:orderViewPage.heroSection.markAsDelivered.title",
          )}
          cancelText={t("commonV2:cancel")}
          disableConfirm={
            !newBikeSerialNumber ||
            (bikeDataCheckEnabled &&
              (!isBikeDataCheckSuccess || isBikeDataCheckLoading))
          }
          onCancel={handleCloseModal}
          onClose={handleCloseModal}
          onConfirm={handleMarkOrderAsDelivered}
          isSubmitting={finalizedOrder.isLoading}
        >
          <Flex direction="column" gap="md">
            <Input
              label={t(
                "shopV2:orderViewPage.heroSection.enterSerialNumber.bikeSerialNumber",
              )}
              placeholder={t(
                "shopV2:orderViewPage.heroSection.enterSerialNumber.bikeSerialNumber",
              )}
              onChange={(e) => setNewBikeSerialNumber(e.target.value)}
            />
            {bikeDataCheckResult?.serialNumber.warnings.map((warning) => (
              <Notification type="warning" key={warning._short}>
                {warning._short}
              </Notification>
            ))}
          </Flex>
        </Modal>
      )}
    </>
  );
}
