import {
  BenefitDefinitionReadWithOrganisationSummary,
  BikeBenefitOrderExtraRead,
} from "@vapaus/api-codegen";
import {
  OrderCalculationParams,
  useOrderCalculation,
} from "@vapaus/shared-api";

import { getOrderTotalPrice } from "../../../../utils";

type OrderSummaryProps = {
  totalDiscountedPrice: number | null;
  totalBikePackage: number | null;
  totalMaintenanceBudget: number;
  monthlyTaxableValue: number | null;
  contractTotal: number | null;
  partnerInvoice: number | null;
  partnerDiscount: number | null;
  downPaymentAmount: number | null;
};

export function useOrderSummary(
  order: BikeBenefitOrderExtraRead,
  benefitDefinition: BenefitDefinitionReadWithOrganisationSummary,
): OrderSummaryProps {
  const totalPackagePrice = getOrderTotalPrice(order);
  const totalDiscountedPrice = getOrderTotalPrice(
    order,
    order.discountBike,
    order.discountAccessory,
  );

  const params: OrderCalculationParams = {
    plan: benefitDefinition.plan,
    benefitDefinitionId: order.benefitDefinitionId,
    monthlyMaintenanceBudget: order.monthlyMaintenanceBudget,
    leasingPeriodMonths: order.leasingPeriodMonths,
    totalPackagePrice: totalPackagePrice,
    fixedDownPaymentAmount: order.fixedDownPaymentAmount,
    hasFixedTaxableValue: !!order.fixedMonthlyTaxableValue,
    fixedMonthlyTaxableValue: order.fixedMonthlyTaxableValue,
  };

  const { data: calculation } = useOrderCalculation(params, {
    enabled: params.totalPackagePrice > 0 && !!benefitDefinition,
    retry: 0,
    refetchOnWindowFocus: false,
  });

  const totalMaintenanceBudget =
    order.monthlyMaintenanceBudget * order.leasingPeriodMonths;
  const partnerDiscount = totalPackagePrice - totalDiscountedPrice;
  let monthlyTaxableValue = null;
  let contractTotal = null;
  let totalBikePackage = null;
  let partnerInvoice = null;

  if (calculation) {
    monthlyTaxableValue =
      order.fixedMonthlyTaxableValue ?? calculation.monthlyTaxableValue;
    contractTotal = calculation.taxablePackagePrice + totalMaintenanceBudget;
    totalBikePackage =
      calculation.taxablePackagePrice + calculation.downPaymentAmount;
    partnerInvoice =
      calculation.taxablePackagePrice -
      partnerDiscount +
      calculation.downPaymentAmount;
  }

  return {
    totalDiscountedPrice: order.isBillable ? totalDiscountedPrice : null,
    totalBikePackage,
    monthlyTaxableValue,
    totalMaintenanceBudget,
    contractTotal,
    partnerInvoice,
    partnerDiscount,
    downPaymentAmount: calculation?.downPaymentAmount ?? null,
  };
}
