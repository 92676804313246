/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { ProductTypes } from "./ProductTypes";
import {
  ProductTypesFromJSON,
  ProductTypesFromJSONTyped,
  ProductTypesToJSON,
} from "./ProductTypes";
import type { SupportedOrganisationCountries } from "./SupportedOrganisationCountries";
import {
  SupportedOrganisationCountriesFromJSON,
  SupportedOrganisationCountriesFromJSONTyped,
  SupportedOrganisationCountriesToJSON,
} from "./SupportedOrganisationCountries";

/**
 *
 * @export
 * @interface ProductVatRateBase
 */
export interface ProductVatRateBase {
  /**
   *
   * @type {SupportedOrganisationCountries}
   * @memberof ProductVatRateBase
   */
  country: SupportedOrganisationCountries;
  /**
   *
   * @type {ProductTypes}
   * @memberof ProductVatRateBase
   */
  product: ProductTypes;
  /**
   *
   * @type {number}
   * @memberof ProductVatRateBase
   */
  vatRate: number;
  /**
   *
   * @type {Date}
   * @memberof ProductVatRateBase
   */
  validFrom: Date;
}

/**
 * Check if a given object implements the ProductVatRateBase interface.
 */
export function instanceOfProductVatRateBase(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "country" in value;
  isInstance = isInstance && "product" in value;
  isInstance = isInstance && "vatRate" in value;
  isInstance = isInstance && "validFrom" in value;

  return isInstance;
}

export function ProductVatRateBaseFromJSON(json: any): ProductVatRateBase {
  return ProductVatRateBaseFromJSONTyped(json, false);
}

export function ProductVatRateBaseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProductVatRateBase {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    country: SupportedOrganisationCountriesFromJSON(json["country"]),
    product: ProductTypesFromJSON(json["product"]),
    vatRate: json["vat_rate"],
    validFrom: new Date(json["valid_from"]),
  };
}

export function ProductVatRateBaseToJSON(
  value?: ProductVatRateBase | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    country: SupportedOrganisationCountriesToJSON(value.country),
    product: ProductTypesToJSON(value.product),
    vat_rate: value.vatRate,
    valid_from: value.validFrom.toISOString().substring(0, 10),
  };
}
