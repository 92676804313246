import { TFunction } from "i18next";

import { BikeBenefitOrderState } from "@vapaus/api-codegen";
import { Flex, Tag, TagVariant } from "@vapaus/ui-v2";

type OrderStatusCellProps = {
  status: BikeBenefitOrderState;
  t: TFunction<"translation">;
};

export function OrderStatusCell({ status, t }: OrderStatusCellProps) {
  const variant = mapStatusToVariant(status);
  const text = mapStatusToText(status, t);

  return (
    <Flex justify="flex-end">
      <Tag variant={variant} text={text} />
    </Flex>
  );
}

const mapStatusToVariant = (state: BikeBenefitOrderState): TagVariant => {
  switch (state) {
    case BikeBenefitOrderState.Draft:
      return "pending";
    case BikeBenefitOrderState.ReadyForDelivery:
      return "waiting";
    case BikeBenefitOrderState.WaitingForPayment:
      return "error";
    case BikeBenefitOrderState.Cancelled:
      return "cancelled";
    case BikeBenefitOrderState.Finalised:
      return "accepted";
  }
};

const mapStatusToText = (
  state: BikeBenefitOrderState,
  t: TFunction<"translation">,
): string => {
  switch (state) {
    case BikeBenefitOrderState.Draft:
      return t("shopV2:orders.status.inReview");
    case BikeBenefitOrderState.ReadyForDelivery:
      return t("shopV2:orders.status.readyForDelivery");
    case BikeBenefitOrderState.WaitingForPayment:
      return t("shopV2:orders.status.waitingForPayment");
    case BikeBenefitOrderState.Cancelled:
      return t("shopV2:orders.status.cancelled");
    case BikeBenefitOrderState.Finalised:
      return t("shopV2:orders.status.delivered");
  }
};
