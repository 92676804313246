import { sumBy } from "lodash-es";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Currency } from "@vapaus/api-codegen";
import { useEnumEntries } from "@vapaus/shared-api";
import {
  Button,
  Card,
  Flex,
  HorizontalLine,
  RowItem,
  Typography,
} from "@vapaus/ui-v2";

import { useCurrencyFormat } from "../../../../../../../../packages/i18n/src/hooks";
import { useGetBikeBrand } from "../../../../../api/bikeBrands";
import { OrderFormCalculatedParams } from "../../hooks";
import { BenefitOrderFormType, getDeliveryLabel } from "../../utils";
import { useMaintenanceOptions } from "../MaintenanceBudgetForm";
import { BaseOrderFormSectionProps } from "../types";

export type OrderFormSummaryProps = {
  formSummary: OrderFormCalculatedParams;
  isSubmitting: boolean;
  isSubmitEnabled: boolean;
  onCancelOrder: () => void;
  editingOrder: boolean;
} & BaseOrderFormSectionProps;

export function OrderFormSummary({
  onCancelOrder,
  formSummary,
  isSubmitting,
  isSubmitEnabled,
  currency = Currency.Eur,
  editingOrder,
}: OrderFormSummaryProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(currency);
  const {
    watch,
    formState: { errors },
  } = useFormContext<BenefitOrderFormType>();

  const [
    accessories = [],
    additionalServices = [],
    bikePurchasePrice,
    bikeBrandId,
    bikeModel,
    fixedDownPaymentAmount,
    deliveryPrice,
    deliveryMethod,
  ] = watch([
    "accessories",
    "additionalServices",
    "bike.purchasePrice",
    "bike.bikeBrandId",
    "bike.model",
    "fixedDownPaymentAmount",
    "deliveryTransport.priceGross",
    "deliveryMethod",
  ]);

  const accessoriesTotals = sumBy(
    accessories.map((accessory) => accessory.purchasePrice || 0),
  );
  const additionalServicesTotals = sumBy(
    additionalServices.map((service) => service.priceGross || 0),
  );

  const { getEnumLabel: getAccessoryTypeEnumLabel } =
    useEnumEntries("AccessoryType");
  const { getEnumLabel } = useEnumEntries("AdditionalServiceType");
  const { selectedOption: maintenanceOption } = useMaintenanceOptions(currency);

  const { data: bikeBrand } = useGetBikeBrand(bikeBrandId);

  return (
    <Card
      title={t("shopV2:orderForm.summary.title")}
      subtitle={t("shopV2:orderForm.summary.subtitle")}
    >
      <Flex direction="column" gap="md">
        <Flex direction="column" gap="xxs">
          <Typography weight="bold">
            {t("shopV2:orderForm.summary.bike")}
          </Typography>
          <RowItem
            label={`${bikeBrand?.name ?? ""} ${bikeModel}`}
            value={formatCurrency(bikePurchasePrice || 0)}
            labelIsBold={false}
            valueIsBold
          />
        </Flex>

        {accessories.length > 0 ? (
          <Flex direction="column" gap="xxs">
            <RowItem
              label={t("shopV2:orderForm.summary.accessories")}
              value={formatCurrency(accessoriesTotals)}
              valueIsBold
            />
            {accessories.map((accessory) => {
              return (
                <RowItem
                  key={accessory._key || accessory.id}
                  label={getAccessoryTypeEnumLabel(accessory.accessoryType)}
                  value={formatCurrency(accessory.purchasePrice || 0)}
                  labelIsBold={false}
                />
              );
            })}
          </Flex>
        ) : null}
        {additionalServices.length > 0 ? (
          <Flex direction="column" gap="xxs">
            <RowItem
              label={t("shopV2:orderForm.summary.additionalServices")}
              value={formatCurrency(additionalServicesTotals)}
              valueIsBold
            />
            {additionalServices.map((service) => (
              <RowItem
                key={service._key || service.id}
                label={
                  service.serviceType ? getEnumLabel(service.serviceType) : "-"
                }
                value={formatCurrency(service.priceGross || 0)}
                labelIsBold={false}
              />
            ))}
          </Flex>
        ) : null}

        <Flex direction="column" gap="xxs">
          <RowItem
            label={t("shopV2:orderForm.summary.deliveryMethod")}
            value={deliveryPrice ? formatCurrency(deliveryPrice) : ""}
            valueIsBold
          />
          <Typography>{getDeliveryLabel(t, deliveryMethod)}</Typography>
        </Flex>

        <Flex direction="column" gap="xxs">
          <RowItem
            label={t("shopV2:orderForm.summary.maintenanceBudget")}
            value={
              !errors.root?.message
                ? formatCurrency(formSummary.totalMaintenanceBudget)
                : "-"
            }
            valueIsBold
          />
          <Typography>{maintenanceOption?.description}</Typography>
        </Flex>
        <HorizontalLine marginBottom="none" marginTop="none" />
        <RowItem
          label={t("shopV2:orderForm.summary.total")}
          value={formatCurrency(formSummary.orderTotal)}
          valueIsBold
        />
        {fixedDownPaymentAmount ? (
          <>
            <HorizontalLine marginBottom="none" marginTop="none" />
            <Flex direction="column" gap="xxs">
              <RowItem
                label={t("shopV2:orderForm.summary.downPayment")}
                value={formatCurrency(fixedDownPaymentAmount)}
                valueIsBold
              />
              <Typography>
                {t("shopV2:orderForm.summary.downPaymentMessage")}
              </Typography>
            </Flex>
          </>
        ) : null}

        <Flex xsDirection="column" gap="sm" justify="space-between">
          <Button
            type="button"
            variant="outlined"
            xsFullWidth
            onClick={onCancelOrder}
          >
            {editingOrder
              ? t("shopV2:orderForm.summary.backToOrder")
              : t("shopV2:orderForm.summary.cancelOrder")}
          </Button>
          <Button
            type="submit"
            xsFullWidth
            isLoading={isSubmitting}
            disabled={!isSubmitEnabled}
          >
            {editingOrder
              ? t("shopV2:orderForm.summary.saveChanges")
              : t("shopV2:orderForm.summary.confirmOrder")}
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
}
