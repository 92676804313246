/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  BenefitDefinitionSummaryRead,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  UserSummaryRead,
} from "../models/index";
import {
  BenefitDefinitionSummaryReadFromJSON,
  BenefitDefinitionSummaryReadToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  UserSummaryReadFromJSON,
  UserSummaryReadToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface ShopUsersGetBenefitDefinitionsActivationsRequest {
  email: string;
  shopId: string;
}

export interface ShopUsersShopGetUserRequest {
  userId: string;
}

export interface ShopUsersShopGetUserByEmailRequest {
  email: string;
  validate?: boolean;
}

/**
 *
 */
export class ShopUsersApi extends runtime.BaseAPI {
  /**
   * Get Benefit Definitions Activations
   */
  async shopUsersGetBenefitDefinitionsActivationsRaw(
    requestParameters: ShopUsersGetBenefitDefinitionsActivationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<BenefitDefinitionSummaryRead>>> {
    if (
      requestParameters.email === null ||
      requestParameters.email === undefined
    ) {
      throw new runtime.RequiredError(
        "email",
        "Required parameter requestParameters.email was null or undefined when calling shopUsersGetBenefitDefinitionsActivations.",
      );
    }

    if (
      requestParameters.shopId === null ||
      requestParameters.shopId === undefined
    ) {
      throw new runtime.RequiredError(
        "shopId",
        "Required parameter requestParameters.shopId was null or undefined when calling shopUsersGetBenefitDefinitionsActivations.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.email !== undefined) {
      queryParameters["email"] = requestParameters.email;
    }

    if (requestParameters.shopId !== undefined) {
      queryParameters["shop_id"] = requestParameters.shopId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/users/activations`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(BenefitDefinitionSummaryReadFromJSON),
    );
  }

  /**
   * Get Benefit Definitions Activations
   */
  async shopUsersGetBenefitDefinitionsActivations(
    requestParameters: ShopUsersGetBenefitDefinitionsActivationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<BenefitDefinitionSummaryRead>> {
    const response = await this.shopUsersGetBenefitDefinitionsActivationsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Shop Get User
   */
  async shopUsersShopGetUserRaw(
    requestParameters: ShopUsersShopGetUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserSummaryRead>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling shopUsersShopGetUser.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/users/{user_id}`.replace(
          `{${"user_id"}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserSummaryReadFromJSON(jsonValue),
    );
  }

  /**
   * Shop Get User
   */
  async shopUsersShopGetUser(
    requestParameters: ShopUsersShopGetUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserSummaryRead> {
    const response = await this.shopUsersShopGetUserRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Shop Get User By Email
   */
  async shopUsersShopGetUserByEmailRaw(
    requestParameters: ShopUsersShopGetUserByEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserSummaryRead>> {
    if (
      requestParameters.email === null ||
      requestParameters.email === undefined
    ) {
      throw new runtime.RequiredError(
        "email",
        "Required parameter requestParameters.email was null or undefined when calling shopUsersShopGetUserByEmail.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.email !== undefined) {
      queryParameters["email"] = requestParameters.email;
    }

    if (requestParameters.validate !== undefined) {
      queryParameters["validate"] = requestParameters.validate;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/users`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserSummaryReadFromJSON(jsonValue),
    );
  }

  /**
   * Shop Get User By Email
   */
  async shopUsersShopGetUserByEmail(
    requestParameters: ShopUsersShopGetUserByEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserSummaryRead> {
    const response = await this.shopUsersShopGetUserByEmailRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
