/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SupportedOrganisationCountries {
  FI = "FI",
  SE = "SE",
  AL = "AL",
  AD = "AD",
  AM = "AM",
  AT = "AT",
  AZ = "AZ",
  BE = "BE",
  BA = "BA",
  BG = "BG",
  HR = "HR",
  CY = "CY",
  CZ = "CZ",
  DK = "DK",
  EE = "EE",
  FR = "FR",
  GE = "GE",
  DE = "DE",
  GR = "GR",
  HU = "HU",
  IS = "IS",
  IE = "IE",
  IT = "IT",
  LV = "LV",
  LI = "LI",
  LT = "LT",
  LU = "LU",
  MT = "MT",
  MD = "MD",
  MC = "MC",
  ME = "ME",
  NL = "NL",
  MK = "MK",
  NO = "NO",
  PL = "PL",
  PT = "PT",
  RO = "RO",
  SM = "SM",
  RS = "RS",
  SK = "SK",
  SI = "SI",
  ES = "ES",
  CH = "CH",
  GB = "GB",
}
