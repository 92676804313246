/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BikeDataCheckMessage
 */
export interface BikeDataCheckMessage {
  /**
   *
   * @type {string}
   * @memberof BikeDataCheckMessage
   */
  _short: string;
  /**
   *
   * @type {string}
   * @memberof BikeDataCheckMessage
   */
  _long: string;
}

/**
 * Check if a given object implements the BikeDataCheckMessage interface.
 */
export function instanceOfBikeDataCheckMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "_short" in value;
  isInstance = isInstance && "_long" in value;

  return isInstance;
}

export function BikeDataCheckMessageFromJSON(json: any): BikeDataCheckMessage {
  return BikeDataCheckMessageFromJSONTyped(json, false);
}

export function BikeDataCheckMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeDataCheckMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    _short: json["short"],
    _long: json["long"],
  };
}

export function BikeDataCheckMessageToJSON(
  value?: BikeDataCheckMessage | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    short: value._short,
    long: value._long,
  };
}
