import { RegisterOptions, useFormContext } from "react-hook-form";

import {
  DatePicker as UIDatePicker,
  DatePickerProps as UIDatePickerProps,
} from "@vapaus/ui-v2";

export type DatePickerProps = Omit<UIDatePickerProps, "error"> &
  Pick<RegisterOptions, "validate" | "valueAsDate"> & {
    name: string;
  };

export function DatePicker({
  name,
  required,
  min,
  max,
  validate,
  value,
  onChange,
  onBlur,
  valueAsDate,
  ...rest
}: DatePickerProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[name]?.message as string | undefined;

  return (
    <UIDatePicker
      {...register(name, {
        required,
        min,
        max,
        validate,
        value,
        onChange,
        onBlur,
        /* eslint-disable @typescript-eslint/no-explicit-any */
        valueAsDate: valueAsDate as any,
      })}
      error={errorMessage}
      {...rest}
    />
  );
}
