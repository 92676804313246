import { useMutation, useQuery } from "@tanstack/react-query";

import {
  BikeBenefitOrderExtraRead,
  BikeBenefitOrderRead,
  ResponseError,
  ShopBenefitApi,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

const shopBenefitApi = new ShopBenefitApi(apiConfiguration);

export const useGetBenefitOrder = (
  id?: string,
  options?: UseQueryOptions<BikeBenefitOrderExtraRead>,
) =>
  useQuery<BikeBenefitOrderExtraRead, ResponseError>(
    ["orders", id],
    () =>
      shopBenefitApi.shopBenefitGetBikeBenefitOrder({
        orderId: id as string,
      }),
    { ...options, enabled: !!id },
  );

export const useCancelBikeBenefitOrder = () =>
  useMutation<BikeBenefitOrderRead, ResponseError, string>(
    (bikeBenefitOrderId: string) =>
      shopBenefitApi.shopBenefitCancelBikeBenefitOrder({
        bikeBenefitOrderId,
      }),
  );

export type FinaliseOrderType = {
  bikeBenefitOrderId: string;
  bikeSerialNumber: string;
};

export const useFinaliseBikeBenefitOrder = () =>
  useMutation<BikeBenefitOrderRead, ResponseError, FinaliseOrderType>(
    ({ bikeBenefitOrderId, bikeSerialNumber }: FinaliseOrderType) =>
      shopBenefitApi.shopBenefitFinaliseBikeBenefitOrder({
        bikeBenefitOrderId,
        bodyShopBenefitFinaliseBikeBenefitOrder: {
          date: new Date(),
          bikeSerialNumber,
        },
      }),
  );
