import { useQuery } from "@tanstack/react-query";

import {
  BikeDataCheckIn,
  BikeDataCheckResult,
  ResponseError,
  ShopBikeApi,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const bikeApi = new ShopBikeApi(apiConfiguration);

export const useCheckBikeData = (
  bikeData: BikeDataCheckIn,
  options?: UseQueryOptions<BikeDataCheckResult>,
) => {
  return useQuery<BikeDataCheckResult, ResponseError>(
    ["check-bike-data", bikeData],
    () =>
      bikeApi.shopBikeCheckBikeData({
        bikeDataCheckIn: bikeData,
      }),
    options,
  );
};
