import { TFunction } from "i18next";
import { z } from "zod";

import {
  AccessoryType,
  AdditionalServiceType,
  BenefitDefinitionUserRead,
  BikeDriveType,
  BikeFrameType,
} from "@vapaus/api-codegen";

import { DeliveryMethod } from "../deliveryMethod";

export const benefitOrderFormSchema = (
  t?: TFunction,
  benefitDefinition?: BenefitDefinitionUserRead,
) => {
  const numberValueSchema = z
    .number({
      required_error: t?.("shopV2:orderForm.validations.cannotLeaveThisEmpty"),
      invalid_type_error: t?.("shopV2:orderForm.validations.enterValidSum"),
    })
    .nonnegative(t?.("shopV2:orderForm.validations.nonNegativeNumber"));
  const leasingPeriodMonthsRangeErrorMessage = t?.(
    "shopV2:orderForm.validations.leasingPeriodOutOfRange",
    {
      min: benefitDefinition?.minimumLeasingPeriod,
      max: benefitDefinition?.maximumLeasingPeriod,
    },
  );
  return z
    .object({
      shopId: z.string(),
      userId: z.string().optional(),
      benefitDefinitionId: z.string(),
      benefitDefinitionTermsId: z.string(),

      monthlyMaintenanceBudget: z.number(),
      leasingPeriodMonths: benefitDefinition
        ? numberValueSchema
            .int()
            .min(
              benefitDefinition.minimumLeasingPeriod,
              leasingPeriodMonthsRangeErrorMessage,
            )
            .max(
              benefitDefinition.maximumLeasingPeriod,
              leasingPeriodMonthsRangeErrorMessage,
            )
        : numberValueSchema,
      fixedMonthlyTaxableValue: numberValueSchema,
      fixedDownPaymentAmount: z.number(),
      isDownPaymentInsured: z.boolean(),

      correctsBikeBenefitContractId: z.string().optional(),
      revisesBikeBenefitContractId: z.string().optional(),
      revisionApplyDate: z.date().optional(),
      discountBike: z.number(),
      notes: z.string().optional(),
      orderNumber: z.string().optional(),

      bike: z.object({
        id: z.string().optional(),
        manufacturer: z.string().optional(),
        bikeBrandId: z
          .string()
          .nonempty(
            t?.("shopV2:orderForm.validations.bikeManufacturerIsRequired"),
          ),
        model: z
          .string()
          .nonempty(t?.("shopV2:orderForm.validations.bikeModelIsRequired")),
        driveType: z
          .string()
          .nullable()
          .refine(
            (value) =>
              Object.values(BikeDriveType).includes(value as BikeDriveType),
            t?.("shopV2:orderForm.validations.invalidDriveType"),
          ),
        frameType: z
          .string()
          .nullable()
          .refine(
            (value) =>
              Object.values(BikeFrameType).includes(value as BikeFrameType),
            t?.("shopV2:orderForm.validations.invalidFrameType"),
          ),
        size: z
          .string()
          .nonempty(t?.("shopV2:orderForm.validations.bikeSizeIsRequired")),
        colour: z
          .string()
          .nonempty(t?.("shopV2:orderForm.validations.bikeColorIsRequired")),
        serialNumber: z.string().optional(),
        purchasePrice: numberValueSchema,
      }),

      accessories: z.array(
        z.object({
          _key: z.number().optional(),
          id: z.string().optional(),
          accessoryType: z
            .string()
            .refine(
              (value) =>
                Object.values(AccessoryType).includes(value as AccessoryType),
              t?.("shopV2:orderForm.validations.invalidAccessoryType"),
            ),
          manufacturer: z
            .string()
            .nonempty(
              t?.(
                "shopV2:orderForm.validations.accessoryManufacturerIsRequired",
              ),
            ),
          model: z
            .string()
            .nonempty(
              t?.("shopV2:orderForm.validations.accessoryModelIsRequired"),
            ),
          purchasePrice: numberValueSchema,
        }),
      ),

      additionalServices: z.array(
        z
          .object({
            _key: z.number().optional(),
            id: z.string().optional(),
            serviceType: z
              .string()
              .nullable()
              .refine(
                (value) =>
                  Object.values(AdditionalServiceType).includes(
                    value as AdditionalServiceType,
                  ),
                t?.("shopV2:orderForm.validations.invalidServiceType"),
              ),
            priceGross: numberValueSchema,
            description: z.string().optional(),
          })
          .refine(
            (data) => {
              if (data.serviceType === AdditionalServiceType.Other) {
                return (
                  data.description != null && data.description.trim().length > 0
                );
              }
              return true;
            },
            {
              message: t?.(
                "shopV2:orderForm.validations.descriptionIsRequiredForOthers",
              ),
              path: ["description"],
            },
          ),
      ),

      deliveryTransport: z
        .object({
          expectedDeliveryDate: z.date().or(z.string()).optional().nullable(),
          trackingUrl: z.string().optional(),
          priceGross: numberValueSchema,
        })
        .optional(),

      deliveryMethod: z.nativeEnum(DeliveryMethod),
      addOrderNote: z.boolean(),
    })
    .refine(
      (data) => {
        if (data.deliveryMethod === DeliveryMethod.delivery) {
          return data.deliveryTransport?.priceGross !== null;
        }
        return true;
      },
      {
        message: t?.(
          "shopV2:orderForm.validations.transportDeliveryPriceIsRequired",
        ),
        path: ["deliveryTransport.priceGross"],
      },
    );
};

const benefitOrderFormSchemaObject = benefitOrderFormSchema();

export type BenefitOrderFormType = z.infer<typeof benefitOrderFormSchemaObject>;
