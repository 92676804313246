import { values } from "lodash-es";
import { useTranslation } from "react-i18next";

import { BikeDataCheckResult } from "@vapaus/api-codegen";
import { Modal, Typography } from "@vapaus/ui-v2";

type BikeDataCheckResultModalProps = {
  bikeDataCheckResult?: BikeDataCheckResult;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const BikeDataCheckResultModal = ({
  bikeDataCheckResult,
  isOpen,
  onClose,
  onConfirm,
}: BikeDataCheckResultModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={onConfirm}
      title={t("shopV2:orderCreation.bikeDataCheckResultModal.title")}
      confirmText={t("shopV2:orderCreation.bikeDataCheckResultModal.confirm")}
      cancelText={t("commonV2:cancel")}
    >
      {values(bikeDataCheckResult).map((fieldCheckResult) =>
        fieldCheckResult.warnings.map((message) => (
          <Typography
            variant="bodyLg"
            align="center"
            marginBottom="s"
            key={message._long}
          >
            {message._long}
          </Typography>
        )),
      )}
      <Typography variant="bodyLg" weight="bold" align="center">
        {t("shopV2:orderCreation.bikeDataCheckResultModal.confirmText")}
      </Typography>
    </Modal>
  );
};
