import { useCallback, useEffect, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import invariant from "tiny-invariant";
import { z } from "zod";

import {
  BenefitDefinitionSummaryRead,
  UserSummaryRead,
} from "@vapaus/api-codegen";
import { parseApiError } from "@vapaus/shared-api";
import {
  ActionCard,
  Flex,
  Notification,
  NotificationType,
  PersonIcon,
  SearchBar,
  TextButton,
  Typography,
} from "@vapaus/ui-v2";

import {
  useGetUserAvailableBenefits,
  useGetUserByMail,
} from "../../../api/user";
import { useShopContext } from "../../../contexts/ShopContext";
import { BenefitRequirementModal } from "./BenefitRequirementModal";
import { ModalViewType } from "./types";

const emailSchema = z
  .string()
  .transform((str) => str.trim())
  .pipe(z.string().email());

type CustomerBenefitSearchProps = {
  searchEmail?: string;
  selectedBenefit: BenefitDefinitionSummaryRead | undefined;
  setSearchEmail: (value: string) => void;
  setUser: (user?: UserSummaryRead) => void;
  setSelectedBenefit: (benefit?: BenefitDefinitionSummaryRead) => void;
  setModalView: (view: ModalViewType) => void;
};

export function CustomerBenefitSearch({
  searchEmail,
  selectedBenefit,
  setSearchEmail,
  setUser,
  setSelectedBenefit,
  setModalView,
}: CustomerBenefitSearchProps) {
  const { t } = useTranslation();
  const { selectedShop } = useShopContext();

  invariant(selectedShop?.id, "selectedShop.id must have a value");

  const parsedEmail = emailSchema.safeParse(searchEmail);
  const isValidEmail = parsedEmail?.success;
  const validatedEmail = isValidEmail ? parsedEmail.data : undefined;

  const {
    data: user,
    error: userFetchingError,
    isFetching: isFetchingUser,
  } = useGetUserByMail(validatedEmail);
  const {
    data: benefitDefinitions = [],
    error: benefitsFetchingError,
    isFetching: isFetchingActivations,
  } = useGetUserAvailableBenefits(
    selectedShop.id,
    user ? validatedEmail : undefined,
  );
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    const fetchError = async () => {
      const error = userFetchingError ?? benefitsFetchingError;
      let message = undefined;
      if (userFetchingError?.response?.status === 404) {
        message = t(
          "shopV2:orderCreation.searchView.notification.noCustomersFound",
        );
      } else if (error) {
        message = await parseApiError(error);
      }
      setErrorMessage(message);
    };

    fetchError();
  }, [benefitsFetchingError, userFetchingError, t]);

  const onConfirmBenefitSelection = useCallback(() => {
    setUser(user);
    setModalView("form");
  }, [setModalView, setUser, user]);

  const isFetching = isFetchingUser || isFetchingActivations;

  const getNotification = (): {
    type: NotificationType;
    message: string;
  } | null => {
    if (!searchEmail) return null;

    if (!isValidEmail) {
      return {
        type: "error",
        message: t(
          "shopV2:orderCreation.searchView.notification.enterValidEmail",
        ),
      };
    }
    if (isFetching) {
      return {
        type: "neutral",
        message: t("shopV2:orderCreation.searchView.notification.isLoading"),
      };
    }
    if (errorMessage) {
      return { type: "error", message: errorMessage };
    }
    if (user && benefitDefinitions.length === 0) {
      return {
        type: "error",
        message: t(
          "shopV2:orderCreation.searchView.notification.noActivationsFound",
        ),
      };
    }
    if (benefitDefinitions.length > 1) {
      return {
        type: "neutral",
        message: t(
          "shopV2:orderCreation.searchView.notification.haveMultipleBenefit",
        ),
      };
    }

    return null;
  };

  const notification = getNotification();

  return (
    <StyledRoot
      align="center"
      direction="column"
      padding="xl"
      sPadding="md"
      gap="lg"
    >
      <Flex align="center" direction="column" gap="sm">
        <Typography variant="h1" align="center">
          {t("shopV2:orderCreation.searchView.title")}
        </Typography>
        <Typography variant="bodyLg" align="center">
          {t("shopV2:orderCreation.searchView.description")}
        </Typography>
      </Flex>
      <StyledSearchBarWrapper>
        <SearchBar
          placeholder={t("shopV2:orderCreation.searchView.searchPlaceholder")}
          searchOnClick
          onSearch={(email) => {
            setErrorMessage(undefined);
            setSearchEmail(email);
          }}
        />
      </StyledSearchBarWrapper>
      {notification || benefitDefinitions.length > 0 ? (
        <Flex align="center" direction="column" gap="md" fullWidth>
          {notification && (
            <Notification
              type={notification.type}
              message={notification.message}
            />
          )}
          {benefitDefinitions.map((benefit) => (
            <ActionCard
              key={benefit.id}
              icon={PersonIcon}
              firstLine={user?.fullName as string}
              secondLine={user?.email as string}
              thirdLine={`${benefit.organisation.name} · ${benefit.name}`}
              onClick={() => setSelectedBenefit(benefit)}
            />
          ))}
        </Flex>
      ) : null}
      <Typography align="center">
        <Trans
          t={t}
          i18nKey="shopV2:orderCreation.searchView.searchFooter"
          components={{
            1: (
              <TextButton to="https://user.vapaus.io" target="_blank" size="md">
                user.vapaus.io
              </TextButton>
            ),
          }}
        />
      </Typography>
      <BenefitRequirementModal
        user={user}
        selectedActivation={selectedBenefit!}
        isOpen={!!selectedBenefit}
        onClose={() => setSelectedBenefit(undefined)}
        onConfirmOrderCreation={onConfirmBenefitSelection}
      />
    </StyledRoot>
  );
}

const StyledRoot = styled(Flex)`
  width: 100%;
  max-width: ${({ theme }) => `calc(560px + ${theme.spacing.xl})`};
  margin: auto;
  padding-left: ${({ theme }) => theme.spacing.md} !important;
  padding-right: ${({ theme }) => theme.spacing.md} !important;
`;

const StyledSearchBarWrapper = styled.div`
  width: 100%;
  max-width: 480px;
`;
