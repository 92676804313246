import { useCallback, useEffect, useMemo, useRef } from "react";

import {
  BenefitCalculation,
  BenefitDefinitionReadWithOrganisationSummary,
} from "@vapaus/api-codegen";
import { FormMethods } from "@vapaus/form";
import {
  OrderCalculationParams,
  parseApiError,
  useOrderCalculation,
} from "@vapaus/shared-api";
import { useDebounce } from "@vapaus/utils";

import { getOrderTotalPrice } from "../../../../../utils";
import { BenefitOrderFormType } from "../../utils/orderFormSchema/orderFormSchema";

type CalculationChanges = {
  leasingPeriodMonths?: number;
  fixedDownPaymentAmount?: number;
  isDownPaymentInsured?: boolean;
  fixedMonthlyTaxableValue?: number;
  monthlyMaintenanceBudget?: number;
};

export type OrderFormCalculatedParams = {
  taxablePackagePrice: number | null;
  totalMaintenanceBudget: number | null;
  hasFixedTaxableValue: boolean;
  orderTotal: number | null;
  isLoading: boolean;
};

// TODO: Let's add test for this later
export function useOrderFormCalculatedParams(
  formMethods: FormMethods<BenefitOrderFormType>,
  hasFixedTaxableValue: boolean,
  benefitDefinition?: BenefitDefinitionReadWithOrganisationSummary,
): OrderFormCalculatedParams {
  const { watch, setValue, setError, clearErrors } = formMethods;
  const order = watch();

  const calculationRef = useRef<BenefitCalculation>();
  const paramsRef = useRef<OrderCalculationParams>();
  const monthlyTaxableValueRef = useRef<number>();
  const maintenanceBudgetRef = useRef<number>();

  const bikePrice = isNaN(order.bike.purchasePrice)
    ? 0
    : order.bike.purchasePrice;

  const totalPackagePrice = getOrderTotalPrice({ ...order, bikePrice });

  useEffect(() => {
    maintenanceBudgetRef.current = order.monthlyMaintenanceBudget ?? 0;
  }, [maintenanceBudgetRef, order.monthlyMaintenanceBudget]);

  const onCalculationChange = useCallback(
    ({
      leasingPeriodMonths,
      fixedDownPaymentAmount,
      isDownPaymentInsured,
      fixedMonthlyTaxableValue,
      monthlyMaintenanceBudget,
    }: CalculationChanges) => {
      if (leasingPeriodMonths !== undefined)
        setValue("leasingPeriodMonths", leasingPeriodMonths);
      if (fixedDownPaymentAmount !== undefined)
        setValue("fixedDownPaymentAmount", fixedDownPaymentAmount);
      if (fixedMonthlyTaxableValue !== undefined)
        setValue("fixedMonthlyTaxableValue", fixedMonthlyTaxableValue);
      if (isDownPaymentInsured !== undefined)
        setValue("isDownPaymentInsured", isDownPaymentInsured);
      if (monthlyMaintenanceBudget !== undefined)
        setValue("monthlyMaintenanceBudget", monthlyMaintenanceBudget);
    },
    [setValue],
  );

  const params = useDebounce(
    useMemo(() => {
      const params: OrderCalculationParams = {
        plan: benefitDefinition?.plan,
        benefitDefinitionId: order.benefitDefinitionId,
        monthlyMaintenanceBudget: order.monthlyMaintenanceBudget,
        totalPackagePrice: totalPackagePrice,
        hasFixedTaxableValue,
        fixedMonthlyTaxableValue: hasFixedTaxableValue
          ? order.fixedMonthlyTaxableValue ??
            benefitDefinition?.fixedMonthlyTaxableValues?.[0]
          : undefined,
        fixedDownPaymentAmount: order.fixedDownPaymentAmount,
        leasingPeriodMonths: hasFixedTaxableValue
          ? undefined
          : order.leasingPeriodMonths,
        contractCorrection: order.correctsBikeBenefitContractId
          ? { correctedContractId: order.correctsBikeBenefitContractId }
          : undefined,
        contractRevision: order.revisesBikeBenefitContractId
          ? {
              revisedContractId: order.revisesBikeBenefitContractId as string,
              revisionApplyDate: order.revisionApplyDate as unknown as string,
            }
          : undefined,
      };

      if (hasFixedTaxableValue) {
        delete params.leasingPeriodMonths;
      }

      if (!paramsRef.current) {
        return params;
      }
      if (
        paramsRef.current.benefitDefinitionId !== params.benefitDefinitionId ||
        paramsRef.current.totalPackagePrice !== params.totalPackagePrice ||
        paramsRef.current.monthlyMaintenanceBudget !==
          params.monthlyMaintenanceBudget
      ) {
        delete params.fixedDownPaymentAmount;
        delete params.leasingPeriodMonths;
      }
      if (!calculationRef.current) return params;
      if (
        calculationRef.current.downPaymentAmount !==
        params.fixedDownPaymentAmount
      ) {
        delete params.leasingPeriodMonths;
      }
      if (
        hasFixedTaxableValue &&
        paramsRef.current.fixedMonthlyTaxableValue !==
          params.fixedMonthlyTaxableValue
      ) {
        delete params.fixedDownPaymentAmount;
      }
      if (
        params.leasingPeriodMonths &&
        calculationRef.current.leasingPeriodMonths &&
        params.leasingPeriodMonths !==
          calculationRef.current.leasingPeriodMonths
      ) {
        delete params.fixedDownPaymentAmount;
      }
      return params;
    }, [
      benefitDefinition?.fixedMonthlyTaxableValues,
      benefitDefinition?.plan,
      hasFixedTaxableValue,
      order.benefitDefinitionId,
      order.correctsBikeBenefitContractId,
      order.fixedDownPaymentAmount,
      order.fixedMonthlyTaxableValue,
      order.leasingPeriodMonths,
      order.monthlyMaintenanceBudget,
      order.revisesBikeBenefitContractId,
      order.revisionApplyDate,
      totalPackagePrice,
    ]),
    700,
  );

  useEffect(() => {
    if (params) {
      paramsRef.current = params;
    }
  }, [params]);

  const {
    data: calculation,
    error,
    isLoading,
  } = useOrderCalculation(params, {
    enabled: params.totalPackagePrice > 0,
    retry: 0,
    refetchOnWindowFocus: false,
  });

  // On calculation success
  useEffect(() => {
    if (calculation) {
      clearErrors("root");
      const changes = {
        leasingPeriodMonths: calculation.leasingPeriodMonths,
        fixedDownPaymentAmount: calculation.downPaymentAmount,
        isDownPaymentInsured: Boolean(
          calculation.downPaymentAmount !== 0 && order.isDownPaymentInsured,
        ),
        fixedMonthlyTaxableValue: hasFixedTaxableValue
          ? ((order.fixedMonthlyTaxableValue ??
              benefitDefinition?.fixedMonthlyTaxableValues?.[0]) as number)
          : calculation.monthlyTaxableValue,
      };
      onCalculationChange(changes);
      monthlyTaxableValueRef.current =
        changes.fixedMonthlyTaxableValue || undefined;
      calculationRef.current = calculation;
      maintenanceBudgetRef.current = order.monthlyMaintenanceBudget ?? 0;
    }
  }, [
    benefitDefinition?.fixedMonthlyTaxableValues,
    calculation,
    clearErrors,
    hasFixedTaxableValue,
    onCalculationChange,
    order.fixedMonthlyTaxableValue,
    order.isDownPaymentInsured,
    order.monthlyMaintenanceBudget,
  ]);

  // On calculation error
  useEffect(() => {
    const fetchError = async () => {
      if (error?.response) {
        monthlyTaxableValueRef.current = undefined;
        maintenanceBudgetRef.current = undefined;
        const message = await parseApiError(error);
        setError("root", { message });
      }
    };
    fetchError();
  }, [error, onCalculationChange, setError]);

  const totalMaintenanceBudget = isNaN(order.leasingPeriodMonths)
    ? 0
    : order.monthlyMaintenanceBudget * order.leasingPeriodMonths;
  const taxablePackagePrice = calculation
    ? (calculation.taxablePackagePrice ?? 0) + totalMaintenanceBudget
    : null;

  const orderTotal = totalPackagePrice + totalMaintenanceBudget;

  return {
    taxablePackagePrice,
    totalMaintenanceBudget,
    hasFixedTaxableValue,
    orderTotal,
    isLoading,
  };
}
