import { useMemo, useState } from "react";

import {
  ColumnDef,
  PaginationState,
  Row,
  SortingState,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import { Currency, WorkTypes } from "@vapaus/api-codegen";
import { useBreakpoints } from "@vapaus/ui-v2";

import { useSearchMaintenanceEvents } from "../../../../../api/maintenanceEvents";
import { PriceCell } from "../../../../../components/tableCells/PriceCell";
import { useShopContext } from "../../../../../contexts/ShopContext";
import { EventCell } from "./EventCell";

export type ServiceHistoryTableDataType = {
  event: Event;
  serviceProvider: string;
  labourPrice: number;
  partsPrice: number;
  totalPrice: number;
  currency: Currency;
};

export type Event = {
  eventType: WorkTypes;
  workDate: Date;
};

export function useServiceHistoryTable(frameNumber: string) {
  const { selectedShop } = useShopContext();
  const { isXs, isSm, isMd, isLg, isXl } = useBreakpoints();
  const { t } = useTranslation();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const sortingOptions = {
    sorting,
    setSorting,
  };

  const mapSortField = () => {
    if (!sorting[0]) {
      return "work_date";
    }
    switch (sorting[0].id) {
      case "event":
        return "work_date";
      case "serviceProvider":
        return "shop.name";
      case "labour":
        return "work_price";
      case "spareParts":
        return "parts_price";
      case "total":
        return "total_price";
      default:
        return "work_date";
    }
  };

  const { data: fetchedData } = useSearchMaintenanceEvents(
    {
      limit: pageSize,
      skip: pageIndex * pageSize,
      q: frameNumber,
      ...(mapSortField()
        ? {
            sortField: mapSortField(),
            sortDirection: !sorting[0]
              ? "desc"
              : sorting[0].desc
              ? "desc"
              : "asc",
          }
        : null),
    },
    {
      enabled: selectedShop != null,
      keepPreviousData: true,
    },
  );

  const totalItems = useMemo(() => fetchedData?.total, [fetchedData?.total]);

  const paginationOptions = {
    pagination: { pageIndex, pageSize },
    setPagination: setPagination,
    totalItems: totalItems || 0,
  };

  const getTableRows = (): ServiceHistoryTableDataType[] => {
    if (!fetchedData) {
      return [];
    }

    return fetchedData.items.map((item) => ({
      event: {
        eventType: item.workType,
        workDate: item.workDate,
      },
      serviceProvider: item.shopId === selectedShop?.id ? item.shop.name : "-",
      labourPrice: item.workPrice,
      partsPrice: item.partsPrice,
      totalPrice: item.totalPrice,
      currency: item.currency,
    }));
  };

  const columns = useMemo<ColumnDef<ServiceHistoryTableDataType>[]>(
    () => [
      {
        id: "event",
        header: t("shopV2:maintenance.wizard.bikeView.table.event"),
        cell: ({ row }) => <EventCell event={row.original.event} t={t} />,
        accessorFn: (row) => row.event,
        enableSorting: true,
        size: isMd || isLg || isXl ? 30 : 45,
      },
      ...(isMd || isLg || isXl
        ? [
            {
              id: "serviceProvider",
              header: t(
                "shopV2:maintenance.wizard.bikeView.table.serviceProvider",
              ),
              cell: ({ row }: { row: Row<ServiceHistoryTableDataType> }) =>
                row.original.serviceProvider,
              accessorFn: (row: Row<ServiceHistoryTableDataType>) =>
                row.original.serviceProvider,
              enableSorting: true,
              size: 20,
            },
          ]
        : []),
      ...(!isXs
        ? [
            {
              id: "labour",
              header: t("shopV2:maintenance.wizard.bikeView.table.labour"),
              cell: ({ row }: { row: Row<ServiceHistoryTableDataType> }) => (
                <PriceCell
                  price={{
                    total: row.original.labourPrice,
                    currency: row.original.currency,
                  }}
                />
              ),
              accessorFn: (row: Row<ServiceHistoryTableDataType>) =>
                row.original.labourPrice,
              enableSorting: true,
            },
          ]
        : []),
      ...(!isXs
        ? [
            {
              id: "spareParts",
              header: t("shopV2:maintenance.wizard.bikeView.table.spareParts"),
              cell: ({ row }: { row: Row<ServiceHistoryTableDataType> }) => (
                <PriceCell
                  price={{
                    total: row.original.partsPrice,
                    currency: row.original.currency,
                  }}
                />
              ),
              accessorFn: (row: Row<ServiceHistoryTableDataType>) =>
                row.original.partsPrice,
              enableSorting: true,
            },
          ]
        : []),
      {
        id: "total",
        header: t("shopV2:maintenance.wizard.bikeView.table.total"),
        cell: ({ row }) => (
          <PriceCell
            price={{
              total: row.original.totalPrice,
              currency: row.original.currency,
            }}
          />
        ),
        accessorFn: (row) => row.totalPrice,
        enableSorting: true,
        size: isXs || isSm ? 50 : 20,
      },
    ],
    [isLg, isMd, isSm, isXl, isXs, t],
  );

  return {
    rows: getTableRows(),
    columns,
    sortingOptions,
    paginationOptions,
  };
}
