import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { SettingsPageLayout } from "@vapaus/settings";
import { useCurrentUser } from "@vapaus/shared-api";
import { PersonIcon, SettingsIcon, StorefrontIcon } from "@vapaus/ui-v2";

import { Path } from "../../constants/paths";
import { useShopContext } from "../../contexts/ShopContext";

export function SettingsPage() {
  const { data: user } = useCurrentUser();
  const { selectedShop } = useShopContext();
  const { t } = useTranslation();

  const tabItems = [
    {
      icon: PersonIcon,
      label: t("shopV2:settings.heroSection.userProfile"),
      to: Path.userProfile,
    },
    {
      icon: SettingsIcon,
      label: t("shopV2:settings.heroSection.accountSettings"),
      to: Path.account,
    },
    {
      icon: StorefrontIcon,
      label: t("shopV2:settings.heroSection.shopAdmin"),
      to: Path.shopAdmin,
    },
  ];

  return (
    <SettingsPageLayout
      name={user?.fullName}
      title={selectedShop?.name}
      email={user?.email}
      tabItems={tabItems}
    >
      <Outlet />
    </SettingsPageLayout>
  );
}
