import { QueryClient } from "@tanstack/react-query";
import { LoaderFunction } from "react-router-dom";
import invariant from "tiny-invariant";

import {
  BenefitDefinitionReadWithOrganisationSummary,
  BikeBenefitContractRead,
  BikeBenefitOrderExtraRead,
} from "@vapaus/api-codegen";

import { getContractQuery } from "../../api/benefitContract";
import { getBenefitDefinitionQuery } from "../../api/benefitDefinition";
import { getOrderQuery } from "../../api/bikeOrders";

export interface OrderViewRouteLoaderResult {
  benefitDefinition: BenefitDefinitionReadWithOrganisationSummary;
  contract: BikeBenefitContractRead | null | undefined;
  order: BikeBenefitOrderExtraRead;
}

export const orderViewRouteLoader =
  (queryClient: QueryClient): LoaderFunction =>
  async ({ params }): Promise<OrderViewRouteLoaderResult> => {
    invariant(params.orderId);
    const order = await queryClient.fetchQuery(getOrderQuery(params.orderId));
    const [contract, benefitDefinition] = await Promise.all([
      order.currentBikeBenefitContractId
        ? queryClient.fetchQuery(
            getContractQuery(order.currentBikeBenefitContractId),
          )
        : null,
      queryClient.fetchQuery(
        getBenefitDefinitionQuery(order.benefitDefinitionId),
      ),
    ]);
    return {
      order,
      contract,
      benefitDefinition,
    };
  };
