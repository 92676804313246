import { Flex, Typography } from "@vapaus/ui-v2";

import { MaintenanceBike } from "../../MaintenanceEventsTable";

type BikeCellProps = {
  bike: MaintenanceBike;
};

export function BikeCell({ bike }: BikeCellProps) {
  return (
    <Flex direction="column" align="flex-end" gap="0">
      <Typography>
        {bike.bikeBrand.name} {bike.model}
      </Typography>
      <Typography>{bike.frameNumber}</Typography>
    </Flex>
  );
}
