import { sumBy } from "lodash-es";
import { useTranslation } from "react-i18next";

import { useGetAdditionalServiceLabel } from "@vapaus/common-v2";
import { HorizontalLine, RowItem } from "@vapaus/ui-v2";

import {
  useCurrencyFormat,
  usePercentFormat,
} from "../../../../../../../packages/i18n/src/hooks/";
import { BaseOrderViewProps } from "../../type";
import { OrderSectionWithEdit } from "./OrderSectionWithEdit";

export function AdditionalServices({
  order,
  benefitDefinition,
}: BaseOrderViewProps) {
  const { t } = useTranslation();
  const { additionalServices = [], currency, flatVatRate } = order;
  const formatCurrency = useCurrencyFormat(currency);
  const formatPercent = usePercentFormat();
  const accessoryTotal = sumBy(additionalServices, "priceGross");
  const { getAdditionalServiceLabel } = useGetAdditionalServiceLabel();

  if (additionalServices.length === 0) return null;
  return (
    <OrderSectionWithEdit
      title={t("shopV2:orderViewPage.additionServices.title")}
      order={order}
      benefitDefinition={benefitDefinition}
      editingSection="AdditionalServices"
    >
      {additionalServices.map((service) => (
        <RowItem
          key={service.id}
          label={getAdditionalServiceLabel(service)}
          value={formatCurrency(service.priceGross)}
          valueIsBold
        />
      ))}
      <HorizontalLine />
      <RowItem
        label={t("shopV2:orderViewPage.totalWithVat", {
          value: formatPercent(flatVatRate),
        })}
        value={formatCurrency(accessoryTotal)}
        valueIsBold
      />
    </OrderSectionWithEdit>
  );
}
