/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const ProductTypes = {
  DefaultProduct: "Default product",
  Bike: "Bike",
  Accessory: "Accessory",
  AdditionalServices: "Additional services",
  Delivery: "Delivery",
  PartnerMaintenanceWork: "Partner maintenance work",
  PartnerMaintenanceParts: "Partner maintenance parts",
  MonthlyOfficeFee: "Monthly office fee",
  MonthlyTaxableValue: "Monthly taxable value",
  CancellationCoverage: "Cancellation coverage",
  DownPayment: "Down payment",
  DownPaymentInsurance: "Down payment insurance",
  ExtendedWarranty: "Extended warranty",
  InsuranceDeductible: "Insurance deductible",
  LateFees: "Late fees",
  Maintenance: "Maintenance",
  OverspentMaintenanceBudget: "Overspent maintenance budget",
  RedemptionFee: "Redemption fee",
  RedemptionFeesAccessoriesOnly: "Redemption fees (accessories only)",
  Refund: "Refund",
  ReturnFee: "Return fee",
} as const;
export type ProductTypes = (typeof ProductTypes)[keyof typeof ProductTypes];

export function ProductTypesFromJSON(json: any): ProductTypes {
  return ProductTypesFromJSONTyped(json, false);
}

export function ProductTypesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProductTypes {
  return json as ProductTypes;
}

export function ProductTypesToJSON(value?: ProductTypes | null): any {
  return value as any;
}
