import { useCallback, useState } from "react";

import { downloadContent } from "../utils";

export type DownloadStatus = "idle" | "error" | "loading" | "success";

export declare type DownloadFunction<TVariables = unknown> = (
  variables: TVariables,
) => Promise<any>; // we could have added a return type like: Promise<TData> but I don't think it's relevant since it will be directly passed to the downloadContent function

export interface DownloadResult<TVariables = unknown, TError = unknown> {
  status: DownloadStatus;
  download: (
    variables: TVariables,
    filename: string,
    type: string,
  ) => Promise<void>; // type's type can probably be improved
  error: TError | null;
  reset: () => void;
}

export const useDownload = <TVariables = unknown, TError = unknown>(
  downloadFn: DownloadFunction<TVariables>,
): DownloadResult<TVariables, TError> => {
  const [status, setStatus] = useState<DownloadStatus>("idle");
  const [error, setError] = useState<TError | null>(null);

  const reset = useCallback(() => {
    setStatus("idle");
    setError(null);
  }, [setStatus, setError]);

  const download = async (
    variables: TVariables,
    filename: string,
    type: string,
  ) => {
    try {
      setStatus("loading");
      setError(null);
      const data = await downloadFn(variables);
      downloadContent(data, filename || data.name, type);
      setStatus("success");
    } catch (error: any) {
      setError(error);
      setStatus("error");
    }
  };

  return { status, error, download, reset };
};
