import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AdditionalServiceType, Currency } from "@vapaus/api-codegen";
import { NumberInput, Select, TextArea } from "@vapaus/form";
import { Flex, InputListBox } from "@vapaus/ui-v2";

import {
  useCurrencyFormat,
  usePercentFormat,
} from "../../../../../../../../packages/i18n/src/hooks";
import { BenefitOrderFormType } from "../../utils";
import { BaseOrderFormSectionProps } from "../types";

type SingleAdditionalServiceFormProps = {
  additionalServiceTypes: Array<{
    value: string;
    label: string;
  }>;
  index: number;
  onRemove: (index: number) => void;
  fields: Array<Record<string, unknown>>;
  id: string;
} & BaseOrderFormSectionProps;

export function SingleAdditionalServiceForm({
  additionalServiceTypes,
  index,
  onRemove,
  currency = Currency.Eur,
  vatRate,
  fields,
  id,
}: SingleAdditionalServiceFormProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(currency);
  const formatPercent = usePercentFormat();

  const { watch } = useFormContext<BenefitOrderFormType>();

  const serviceType = watch(`additionalServices.${index}.serviceType`);

  return (
    <InputListBox
      title={t("shopV2:orderForm.additionalServices.itemTitle", {
        index: index + 1,
      })}
      onRemove={() => onRemove(index)}
    >
      <Flex direction="column" gap="md">
        <Select
          name={`additionalServices.${index}.serviceType`}
          options={additionalServiceTypes}
          placeholder={t("shopV2:orderForm.additionalServices.chooseType")}
          label={t("shopV2:orderForm.additionalServices.type")}
        />
        <NumberInput
          name={`additionalServices.${index}.priceGross`}
          label={t("shopV2:orderForm.priceIncludeVAT", {
            vat: formatPercent(vatRate),
          })}
          placeholder={formatCurrency(0)}
          defaultValue={
            (fields.find((field) => field.id === id)?.priceGross as number) ||
            undefined
          }
        />
        {serviceType === AdditionalServiceType.Other && (
          <TextArea
            label={t("shopV2:orderForm.additionalServices.description")}
            placeholder={t(
              "shopV2:orderForm.additionalServices.enterServiceDescription",
            )}
            name={`additionalServices.${index}.description`}
          />
        )}
      </Flex>
    </InputListBox>
  );
}
