import { ScrollRestoration } from "react-router-dom";

import { ShopContextProvider } from "../../contexts/ShopContext";
import { Navigation } from "../Navigation";

export function Layout() {
  return (
    <ShopContextProvider>
      <ScrollRestoration />
      <Navigation />
    </ShopContextProvider>
  );
}
