import styled, { css } from "styled-components";

import { Flex, Typography } from "@vapaus/ui-v2";

type BudgetBoxProps = {
  selected: boolean;
  disabled: boolean;
  label: string;
  description: string;
  onClick: () => void;
};

export function BudgetBox({
  selected,
  disabled,
  label,
  description,
  onClick,
}: BudgetBoxProps) {
  return (
    <StyledRoot
      onClick={onClick}
      direction="column"
      align="center"
      gap="xxs"
      padding="md"
      selected={selected}
      disabled={disabled}
    >
      <Typography align="center" variant="h4">
        {label}
      </Typography>
      <Typography align="center" variant="bodySm">
        {description}
      </Typography>
    </StyledRoot>
  );
}

const StyledRoot = styled(Flex)<{
  selected: boolean;
  disabled: boolean;
}>`
  width: 100%;
  border: ${({ theme }) => theme.border.bold.light};
  padding-left: ${({ theme }) => theme.spacing.sm} !important;
  padding-right: ${({ theme }) => theme.spacing.sm} !important;
  border-radius: ${({ theme }) => theme.spacing.sm};
  cursor: pointer;
  ${({ theme, selected }) =>
    selected
      ? css`
          border-color: ${theme.palette.secondary1.main};
          background-color: ${theme.palette.secondary1.lighter};
        `
      : css`
          border-color: ${theme.palette.neutral.main};
          background-color: ${theme.palette.common.white};
        `};

  ${({ theme, disabled }) =>
    disabled &&
    css`
      opacity: ${theme.opacity.disabled};
      pointer-events: none;
    `};
`;
