import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button, Card, Flex } from "@vapaus/ui-v2";

import { useFilterAccessoryTypesByCountry } from "../../hooks";
import { BikeAccessoriesFormProps } from "../types";
import { AccessoryGuideButton } from "./AccessoryGuideButton";
import { SingleAccessoryForm } from "./SingleAccessoryForm";

export function BikeAccessoriesForm({
  currency,
  vatRate,
  country,
}: BikeAccessoriesFormProps) {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({ name: "accessories" });

  const handleAddAccessory = () => {
    append({
      _key: Date.now(),
      accessoryType: null,
      manufacturer: "",
      model: "",
      purchasePrice: null,
    });
  };

  const { allowedAccessoryTypes } = useFilterAccessoryTypesByCountry(country);

  return (
    <Card
      title={t("shopV2:orderForm.accessories.title")}
      subtitle={t("shopV2:orderForm.accessories.subtitle")}
    >
      <Flex direction="column" gap="lg">
        {fields.map((accessory, index) => (
          <SingleAccessoryForm
            key={accessory.id}
            availableAccessoryTypes={allowedAccessoryTypes}
            index={index}
            onRemove={remove}
            currency={currency}
            vatRate={vatRate}
            fields={fields}
            id={accessory.id}
          />
        ))}

        <Flex>
          <Button type="button" variant="outlined" onClick={handleAddAccessory}>
            {t("shopV2:orderForm.accessories.add")}
          </Button>
        </Flex>
        <AccessoryGuideButton country={country} />
      </Flex>
    </Card>
  );
}
