import { useState } from "react";

import { useTranslation } from "react-i18next";

import { AddIcon, Button, CircularButton, useBreakpoints } from "@vapaus/ui-v2";

import { OrderCreationModal } from "../OrderCreationModal";

export function AddOrderButton() {
  const { t } = useTranslation();
  const { isXs } = useBreakpoints();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);

  return (
    <>
      {isXs ? (
        <CircularButton
          onClick={handleOpenModal}
          icon={<AddIcon />}
          variant="secondary"
        />
      ) : (
        <Button onClick={handleOpenModal}>{t("shopV2:orders.addOrder")}</Button>
      )}
      <OrderCreationModal isOpen={isOpen} onClose={handleCloseModal} />
    </>
  );
}
